/* eslint-disable */

import axios from "axios";
import {
  GET_PROFILE_INFO,
  GETTING_PROFILE_INFO,
  FETCHING_USER_RECOMMENDATIONS,
  USER_RECOMMENDATIONS,
  FETCHING_OTHERS_RECOMMENDATIONS,
  OTHERS_RECOMMENDATIONS,
  FETCHING_USER_POSTS,
  USER_POSTS,
  FETCHING_USER_VIDEOS,
  USER_VIDEOS,
  FETCHING_OTHERS_RATINGS,
  OTHERS_RATINGS,
  SENT_RATINGS,
  FETCHING_PROFILE_STATUS,
  COLLABORATION_SUCCESS,
  EVENT_BOOKING_SUCCESS,
  RECORD_LABEL_SUCCESS,
  COLLABORATION_FAILED,
  RECORD_LABEL_FAILED,
  EVENT_BOOKING_FAILED,
  NPS_CREATE_FAILED,
  NPS_CREATE_SUCCESS,
} from "./types";
import { returnErrors, createMessage } from "./messages";
import mixpanel from "../../components/Mixpanel";
import { config } from "../Endpoints";

const url = config.url.API_URL;
// Get User Profile Info
export const getProfileInfo = (requestId) => (dispatch, getState) => {
  //Resetting Profile Info
  dispatch({ type: GETTING_PROFILE_INFO });
  const key = getState().auth.key;

  // Headers
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  // If token, add to headers config
  if (key) {
    config.headers["Authorization"] = `Token ${key}`;
  }

  axios
    .get(`${url}users/${requestId}/`, config)
    .then((res) => {
      dispatch({
        type: GET_PROFILE_INFO,
        payload: res.data,
      });
      console.log(res.data);
      localStorage.setItem("profile", JSON.stringify(res.data));
    })
    .catch((err) => console.log(err?.response));
};

// LOGGED IN USER RECOMMENDATIONS
export const userRecommendations = () => (dispatch, getState) => {
  // Resetting User Recommendations
  dispatch({ type: FETCHING_USER_RECOMMENDATIONS });
  const key = getState().auth.key;

  // Headers
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  // If token, add to headers config
  if (key) {
    config.headers["Authorization"] = `Token ${key}`;
  }

  axios
    .get(`${url}recommendations/`, config)
    .then((res) => {
      dispatch({
        type: USER_RECOMMENDATIONS,
        payload: res.data.results,
      });
      console.log(res.data.results);
    })
    .catch((err) => console.log(err?.response));
};

// OTHER USER RECOMMENDATIONS
export const othersRecommendations = (personId) => (dispatch, getState) => {
  // Resetting Others Recommendations
  dispatch({ type: FETCHING_OTHERS_RECOMMENDATIONS });
  const key = getState().auth.key;

  // Headers
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  // If token, add to headers config
  if (key) {
    config.headers["Authorization"] = `Token ${key}`;
  }

  axios
    .get(`${url}recommendations/${personId}/`, config)
    .then((res) => {
      dispatch({
        type: OTHERS_RECOMMENDATIONS,
        payload: res.data.results,
      });
      console.log(res.data);
    })
    .catch((err) => console.log(err?.response));
};

// OTHER USER RATINGS
export const othersRatings = (personId) => (dispatch, getState) => {
  // Resetting Others Ratings
  dispatch({ type: FETCHING_OTHERS_RATINGS });
  const key = getState().auth.key;

  // Headers
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  // If token, add to headers config
  if (key) {
    config.headers["Authorization"] = `Token ${key}`;
  }

  axios
    .get(`${url}rating/${personId}/`, config)
    .then((res) => {
      dispatch({
        type: OTHERS_RATINGS,
        payload: res.data.results,
      });
      console.log(res.data);
    })
    .catch((err) => console.log(err?.response));
};

// Give Ratings
export const giveRatings =
  (personId, talent_rating, communication_rating, professionalism_rating) =>
  (dispatch, getState) => {
    const key = getState().auth.key;

    // Headers
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    // If token, add to headers config
    if (key) {
      config.headers["Authorization"] = `Token ${key}`;
    }
    const body = JSON.stringify({
      talent_rating,
      communication_rating,
      professionalism_rating,
    });

    axios
      .post(`${url}rate/${personId}/`, body, config)
      .then((res) => {
        dispatch({
          type: SENT_RATINGS,
          payload: res.data.results,
        });
        mixpanel.track("Rate User", {
          "User ID": personId,
          "Talent Rating": talent_rating,
          "Communication Rating": communication_rating,
          "Professionalism Rating": professionalism_rating,
        });
        dispatch(createMessage(res.data));
      })
      .catch((err) => console.log(err?.response));
  };

// Fetch User Posts
export const getUserPosts = (personId) => (dispatch, getState) => {
  // Resetting User Posts
  dispatch({ type: FETCHING_USER_POSTS });
  const key = getState().auth.key;

  // Headers
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  // If token, add to headers config
  if (key) {
    config.headers["Authorization"] = `Token ${key}`;
  }

  axios
    .get(`${url}posts/x/text/${personId}/`, config)
    .then((res) => {
      dispatch({ type: USER_POSTS, payload: res.data });
      console.log(res.data);
    })
    .catch((err) => {
      console.log(err?.response);
    });
};

// Fetch User Videos
export const getUserVideos = (personId) => (dispatch, getState) => {
  // Resetting User Posts
  dispatch({ type: FETCHING_USER_VIDEOS });
  const key = getState().auth.key;

  // Headers
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  // If token, add to headers config
  if (key) {
    config.headers["Authorization"] = `Token ${key}`;
  }

  axios
    .get(`${url}posts/x/video/${personId}/`, config)
    .then((res) => {
      dispatch({ type: USER_VIDEOS, payload: res.data });
      console.log(res.data);
    })
    .catch((err) => {
      console.log(err?.response);
    });
};
// Write Recommendations
export const giveRecommendations =
  (personId, recommendation) => (dispatch, getState) => {
    const key = getState().auth.key;

    // Headers
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    // If token, add to headers config
    if (key) {
      config.headers["Authorization"] = `Token ${key}`;
    }
    const body = JSON.stringify({ recommendation });

    axios
      .post(`${url}recommend/${personId}/`, body, config)
      .then((res) => {
        dispatch({
          type: SENT_RATINGS,
          payload: res.data.results,
        });
        mixpanel.track("Recommend User");
        dispatch(createMessage(res.data));

        console.log(res.data);
      })
      .catch((err) => console.log(err?.response));
  };
// PATCH COLLABORATION STATUS
export const collaborationUpdate =
  (collaboration_interest) => (dispatch, getState) => {
    // COLLABORATION_SUCCESS
    // COLLABORATION_FAILED
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const key = getState().auth.key;
    if (key) {
      config.headers["Authorization"] = `Token ${key}`;
    }
    const user = getState().auth.users;
    const body = { collaboration_interest };
    axios
      .patch(user, body, config)
      .then((res) => {
        dispatch({
          type: COLLABORATION_SUCCESS,
          payload: res.data,
        });
        mixpanel.track("Update Collaboration Status");
      })
      .catch((err) => {
        dispatch(returnErrors(err?.response?.data, err?.response?.status));
        dispatch({
          type: COLLABORATION_FAILED,
        });
      });
  };
// PATCH EVENT BOOKING STATUS
export const eventBookingUpdate = (event_interest) => (dispatch, getState) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const key = getState().auth.key;
  if (key) {
    config.headers["Authorization"] = `Token ${key}`;
  }
  const user = getState().auth.users;
  const body = { event_interest };
  axios
    .patch(user, body, config)
    .then((res) => {
      dispatch({
        type: EVENT_BOOKING_SUCCESS,
        payload: res.data,
      });
      mixpanel.track("Update Event Booking Status");
    })
    .catch((err) => {
      dispatch(returnErrors(err?.response?.data, err?.response?.status));
      dispatch({
        type: EVENT_BOOKING_FAILED,
      });
    });
};
// PATCH RECORD LABEL STATUS
export const recordLabelUpdate =
  (record_label_interest) => (dispatch, getState) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const key = getState().auth.key;
    if (key) {
      config.headers["Authorization"] = `Token ${key}`;
    }
    const user = getState().auth.users;
    const body = { record_label_interest };
    axios
      .patch(user, body, config)
      .then((res) => {
        dispatch({
          type: RECORD_LABEL_SUCCESS,
          payload: res.data,
        });
        mixpanel.track("Update Record Label Status");
      })
      .catch((err) => {
        dispatch(returnErrors(err?.response?.data, err?.response?.status));
        dispatch({
          type: RECORD_LABEL_FAILED,
        });
      });
  };
// PATCH NPS
export const NpsUpdate = (score) => (dispatch, getState) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const key = getState().auth.key;
  if (key) {
    config.headers["Authorization"] = `Token ${key}`;
  }
  const body = { score };
  axios
    .post(`${url}nps/create`, body, config)
    .then((res) => {
      dispatch({
        type: NPS_CREATE_SUCCESS,
        payload: res.data,
      });
      mixpanel.track("Add NPS Score", {
        score: score,
      });
      dispatch(createMessage(res.data));

      console.log(res);
    })
    .catch((err) => {
      dispatch(returnErrors(err?.response?.data, err?.response?.status));
      dispatch({
        type: NPS_CREATE_FAILED,
      });
      mixpanel.track("Add NPS Score Failed");
    });
};
// PATCH Avatar
export const avatarUpdate = (avatar) => (dispatch, getState) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  // const key = getState().auth.key;
  const key = localStorage.getItem("key");

  if (key) {
    config.headers["Authorization"] = `Token ${key}`;
  }
  const user = getState().auth.users;
  const body = { avatar };
  axios
    .patch(user, body, config)
    .then((res) => {
      console.log(res);
      mixpanel.track("Update Avatar");
    })
    .catch((err) => {
      dispatch(returnErrors(err?.response?.data, err?.response?.status));
      dispatch({
        type: COLLABORATION_FAILED,
      });
    });
};
// give Feedback
export const feedbackUpdate = (feedback) => (dispatch, getState) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const key = getState().auth.key;
  if (key) {
    config.headers["Authorization"] = `Token ${key}`;
  }
  const body = { feedback };
  axios.post(`${url}feedback/`, body, config).then((res) => {
    console.log(res);
    mixpanel.track("Give Feedback");
  });
};
