/* eslint-disable import/no-anonymous-default-export */
import {
  GET_POSTS,
  NUMBER_OF_LIKES,
  GET_PERSONAL_POSTS,
  GET_PERSONAL_VIDEOS,
  IMG_UPLOADED,
  IMG_UPLOADING,
  GET_VIDEO,
  POST_LOADING,
  LIKE_POST,
  UNLIKE_POST,
  SUBMIT_COMMENT,
} from "../wm_actions/types";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  key: localStorage.getItem("key"),
  posts: {},
  likes: null,
  isFetching: true,
  personalPosts: [],
  personalVideos: [],
  uploadedIMG: true,
  vid: [],
  videoPost: [],
  productLoading: false,
  likeArr: [],
  post: {},
};

export const likeSlice = createSlice({
  name: "like",
  initialState,
  reducers: {
    updateData: (state, action) => {
      action.payload.map((ele) => {
        state[ele.key] = ele.value;
      });
    },
  },
});

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_POSTS:
      // console.log(action.payload, 'GET_POSTS')
      return {
        ...state,
        posts: action.payload,
        isFetching: false,
        vid: action.vids,
        likeArr: action.likes,
      };
    case GET_VIDEO:
      // console.log(action.payload, 'GET_VIDEO')
      return {
        ...state,
        videoPost: action.payload,
        isFetching: false,
        vid: action.vids,
      };
    case NUMBER_OF_LIKES:
      return {
        ...state,
        likes: action.payload,
      };
    case GET_PERSONAL_POSTS:
      return {
        ...state,
        personalPosts: action.payload,
      };
    case GET_PERSONAL_VIDEOS:
      return {
        ...state,
        personalVideos: action.payload,
      };
    case IMG_UPLOADING:
      return {
        ...state,
        uploadedIMG: false,
      };
    case IMG_UPLOADED:
      return {
        ...state,
        uploadedIMG: true,
      };
    case POST_LOADING:
      console.log(action.payload);
      return {
        ...state,
        productLoading: action.payload,
      };
    case LIKE_POST:
      console.log(action.payload, 'This is from Reducer: LIKE_POST');
      let idx = state.posts.results.findIndex(
        (post) => post.id === action.payload.id
      );

      state.posts.results[idx] = action.payload;
      if (state.posts.results.id === action.payload.id) {
        state.post = action.payload;
      }

      return {
        ...state,

      };


    case UNLIKE_POST:
      let index = state.posts.results.findIndex(
        (post) => post.id === action.payload.id
      );
      console.log(state.posts.results[index]);
      state.posts.results[index] = action.payload;
      if (state.posts.results.id === action.payload.id) {
        state.post = action.payload;
      }
      return {
        ...state,
      };
    case SUBMIT_COMMENT:
      let commentIndex = state.posts.results.findIndex(
        (post) => post.id === action.payload.post_id
      );
      console.log(state.posts.results[commentIndex]);
      state.posts.results[commentIndex].Comments = [
        ...state.posts.results[commentIndex].Comments,
        action.payload,
      ];
      return {
        ...state,
      };
    default:
      return state;
  }
}
