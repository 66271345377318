import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withAlert } from "react-alert";
import { getProfileInfo, avatarUpdate } from "../wm_actions/profile";
import { loadUser } from "../wm_actions/auth";
// import { getDownloadURL } from "firebase/storage";
import { postVideo, startPost } from "../wm_actions/posts";
import { artistHandle } from "../wm_actions/gig_application";
// import { collection, addDoc } from "firebase/firestore";
import firebase, { storage, db } from "../../components/Firebase/firebase";
import LinearProgress from "@material-ui/core/LinearProgress";
import subscriptions from "../../assets/images/share/subscriptions.png";
import { Link } from "react-router-dom";
import VideoPlayer from "react-simple-video-player";
import MyLoader from "../../loader/CircleLoader";
import ContentLoader from "react-content-loader";

import {
  Avatar,
  Button,
  FormControl,
  FormHelperText,
  Input,
  InputLabel,
} from "@material-ui/core";
import {
  videoUploadedStorage,
  videoUploadedStorageFalse,
} from "../wm_actions/share";
import store from "../wm_store/store";
import record from "../../assets/images/gigs/camera.png";
import Navbar from "../wm_navigation/Navbar";
import BottomNav from "../wm_navigation/BottomNav";
import { Redirect } from "react-router-dom";
import axios from "axios";
import { config } from "../Endpoints";
import { createMessage } from "../wm_actions/messages";
import { padding } from "@material-ui/system";
import { useAlert } from "react-alert";
import CircleLoader from "../../loader/CircleLoader";

const url = config.url.API_URL;
class LatestArtistApplication extends React.Component {
  static propTypes = {
    person: PropTypes.array,
    postVideo: PropTypes.func.isRequired,
    uploadedIMG: PropTypes.bool.isRequired,
    videoUploadedStorageFalse: PropTypes.func.isRequired,
  };
  state = {
    caption: "",
    video_url: "",
    videoName: "",
    percentUploaded: 0,
    isUploading: false,
    videoToGet: "",
    userId: "",
    isUploadedToStorage: false,
    youtubeStat: {},
    youtubeUrl: "",
    soundCloudStat: {},
    socialPlatformList: [{ socialPlatform: "", handle: "" }],
    facebookHandle: "",
    twitterHandle: "",
    tiktokHandle: "",
    instagramHandle: "",
    img: null,
    imgUrl: null,
    phoneNumber: "",
    videoPlaying: true,
    downloadingVideo: false,
    uploadProfilePicture: false,
    // steamingLinks: "",
  };

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  // gs:/whip-mvp.appspot.com/gig/video/f3f0bb8c-06b1-4775-9ede-aa06c39c3ed8/20
  //  https://firebasestorage.googleapis.com/v0/b/whip-mvp.appspot.com/o/gig%2Fvideo%2Ff3f0bb8c-06b1-4775-9ede-aa06c39c3ed8%2F2?alt=media
  //  https://firebasestorage.googleapis.com/v0/b/whip-mvp.appspot.com/o/gig%2Fvideo%2Ff3f0bb8c-06b1-4775-9ede-aa06c39c3ed8%2F19?alt=media
  // https://firebasestorage.googleapis.com/v0/b/whip-mvp.appspot.com/o/gig%2Fvideo%2Ff3f0bb8c-06b1-4775-9ede-aa06c39c3ed8%2F1658216049955?alt=media
  // https://firebasestorage.googleapis.com/v0/b/whip-mvp.appspot.com/o/gig%2Fvideo%2Ff3f0bb8c-06b1-4775-9ede-aa06c39c3ed8%2F1658216049962?alt=media
  // https://firebasestorage.googleapis.com/v0/b/whip-mvp.appspot.com/o/gig%2Fvideo%2Ff3f0bb8c-06b1-4775-9ede-aa06c39c3ed8%2F1658216049955?alt=media
  data = [
    "Youtube",
    "Instagram",
    "Twitter",
    "Spotify",
    "Tiktok",
    "iTunes",
    "SoundCloud",
    "Shazam",
  ];
  handlesocialPlatformChange = (e, index) => {
    console.log(this.state.socialPlatformList);
    const { name, value } = e.target;
    console.log(value);
    if (value.includes("youtube.com")) {
      console.log("youtube url");
      this.setState({ youtubeUrl: value });
      this.fetchYoutubeStat(value);
    }
    if (value.includes("soundcloud.com")) {
      console.log("sound cloud url");
      // this.setState({youtubeUrl:value})
      this.fetchSoundCloudStat(value);
    }
    if (value.includes("instagram.com")) {
      console.log("instagram url");
      // this.setState({youtubeUrl:value})
      this.fetchInstagramStat(value);
    }

    const list = [...this.state.socialPlatformList];
    list[index][name] = value;
    console.log();
    this.setState({ socialPlatformList: list });
  };
  fetchYoutubeStat = (youtubeUrl) => {
    let formatValue = youtubeUrl.split("/");
    let channel_id = formatValue[formatValue.length - 1];
    console.log(channel_id);
    // TODO extract private data into env keys 
    const options = {
      method: "GET",
      url: `https://youtube-data-scraper.p.rapidapi.com/channel/statistics/${channel_id}`,
      headers: {
        "X-RapidAPI-Host": "youtube-data-scraper.p.rapidapi.com",
        "X-RapidAPI-Key": "7a605ab4e5msh5ea047e05061264p183150jsn5d1daf8c0b66",
      },
    };
    axios
      .request(options)
      .then((response) => {
        console.log(response.data);
        let { viewCount, videoCount } = response.data;
        this.setState({
          youtubeStat: {
            youtubeUrl: youtubeUrl,
            viewCount: viewCount,
            videoCount: videoCount,
          },
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };
  fetchSoundCloudStat = (soundCloudUrl) => {
    const options = {
      method: "GET",
      url: "https://soundcloud4.p.rapidapi.com/user/info",
      params: { profile_url: soundCloudUrl },
      headers: {
        "X-RapidAPI-Host": "soundcloud4.p.rapidapi.com",
        "X-RapidAPI-Key": "7a605ab4e5msh5ea047e05061264p183150jsn5d1daf8c0b66",
      },
    };
    axios
      .request(options)
      .then((response) => {
        console.log(response);
        let { profile, tracksCount, followers } = response.data;
        console.log(profile);
        this.setState({
          soundCloudStat: {
            profile: profile,
            tracksCount: tracksCount,
            followers: followers,
            soundCloudUrl: soundCloudUrl,
          },
        });
        console.log(this.state);
      })
      .catch((error) => {
        console.error(error);
      });
  };
  fetchInstagramStat = (instagramUrl) => {
    let formatValue = instagramUrl.split("/");
    const result = formatValue.filter((x) => x !== "/").filter((x) => x !== "");
    console.log(result);
    let username = result[result.length - 1];
    console.log(username);
    const options = {
      method: "GET",
      url: "https://instagram130.p.rapidapi.com/account-info",
      params: { username: username },
      headers: {
        "X-RapidAPI-Host": "instagram130.p.rapidapi.com",
        "X-RapidAPI-Key": "7a605ab4e5msh5ea047e05061264p183150jsn5d1daf8c0b66",
      },
    };

    axios
      .request(options)
      .then((response) => {
        console.log(response.data);
        let { edge_followed_by } = response.data;
        // console.log(profile)
        this.setState({
          instagramStat: {
            edge_followed_by: edge_followed_by,
            profile_url: instagramUrl,
          },
        });
        console.log(this.state);
      })
      .catch((error) => {
        console.error(error);
      });
  };
  handlesocialPlatformRemove = (index) => {
    if (
      this.state.socialPlatformList[index].socialPlatform.includes(
        "youtube.com"
      )
    ) {
      this.setState({ youtubeStat: {} });
    }
    if (
      this.state.socialPlatformList[index].socialPlatform.includes(
        "soundcloud.com"
      )
    ) {
      this.setState({ soundCloudStat: {} });
    }
    if (
      this.state.socialPlatformList[index].socialPlatform.includes(
        "instagram.com"
      )
    ) {
      this.setState({ instagramStat: {} });
    }

    const list = [...this.state.socialPlatformList];
    list.splice(index, 1);
    this.setState({ socialPlatformList: list });
  };

  handlesocialPlatformAdd = () => {
    console.log("working");
    this.setState({
      socialPlatformList: [
        ...this.state.socialPlatformList,
        { socialPlatform: "" },
      ],
    });
  };
  handleDownloadUrl = () => {
    let spaceRef = this.state.videoName;
    console.log(spaceRef, "spaceRef");
    let GetVideo = `https://firebasestorage.googleapis.com/v0/b/whip-mvp.appspot.com/o/gig%2Fvideo%2F${localStorage.getItem(
      "id"
    )}%2F${new Date().getTime()}?alt=media`;
    console.log(GetVideo);
    this.setState({
      video_url: GetVideo,
    });
    this.props.artistHandle("ARTIST_VIDEO", GetVideo);
  };

  handleFacebookHandle = (v) => {
    console.log(v.target.value);
    this.setState({
      facebookHandle: v.target.value,
    });
    this.props.artistHandle("FACEBOOK", v.target.value);
  };
  handleTwitterHandle = (v) => {
    this.setState({
      twitterHandle: v.target.value,
    });
    this.props.artistHandle("TWITTER", v.target.value);
  };
  handleStreamLinks = (v) => {
    // let streamingArr = v.target.value.split(/\n/);
    // console.log(streamingArr);
    this.props.artistHandle("STREAMINGLINKS", v.target.value);
    // this.setState({
    //   streamingLinks: v.target,
    // });
  };
  handlePhoneNumber = (v) => {
    this.setState({
      phoneNumber: v.target.value,
    });
    this.props.artistHandle("PHONE_NUMBER", v.target.value);
  };
  handleTiktokHandle = (v) => {
    this.setState({
      tiktokHandle: v.target.value,
    });
    this.props.artistHandle("TIKTOK", v.target.value);
  };
  handleInstagramHandle = (v) => {
    this.setState({
      instagramHandle: v.target.value,
    });
    this.props.artistHandle("INSTAGRAM", v.target.value);
    // this.fetchInstagramStat(v.target.value);
  };
  handleArtistCaption = (v) => {
    this.setState({
      caption: v.target.value,
    });
    this.props.artistHandle("ARTIST_CAPTION", v.target.value);
  };

  handlePost = async (e) => {
		e.preventDefault();
		console.log("videurl", this.state.video_url);
		let authKey = localStorage.getItem("key");
		const config = {
			headers: {
				Authorization: `Token ${authKey}`,
				"Content-Type": "application/json",
			},
		};
		// TODO refactor to use props
		const gig_id = localStorage.getItem("gig_id");
		const user_id = localStorage.getItem("id");
		const gig_name = localStorage.getItem("gig_name");
		console.log(gig_id);
		console.log("videourl", this.state.video_url);
		console.log(this.state.socialPlatformList);
		console.log(this.state.caption);
		let platformLinks = JSON.stringify(this.state.socialPlatformList);
		const data = new FormData();
		if (
			this.props.artistApplicationHandle.facebookHandle === "" ||
			this.props.artistApplicationHandle.twitterHandle === "" ||
			this.props.artistApplicationHandle.tiktokHandle === "" ||
			this.props.artistApplicationHandle.instagramHandle === ""
		) {
			return this.props.alert.error("Please fill the form properly");
		}
		// if (this.props.artistApplicationHandle.artistVideo === "") {
		//   return this.props.alert.error("Please record a video");
		// }
		if (this.props.artistApplicationHandle.caption === "") {
			return this.props.alert.error("Please fill the caption");
		}
		if (this.props.artistApplicationHandle.phoneNumber === "") {
			return this.props.alert.error("Please fill your phone number");
		}
		if (
			!this.props.artistApplicationHandle.facebookHandle.includes(
				"facebook.com",
			)
		) {
			return this.props.alert.error(
				"incorrect facebook handle, handle must include facebook.com ",
			);
		}
		if (
			!this.props.artistApplicationHandle.twitterHandle.includes("twitter.com")
		) {
			return this.props.alert.error(
				"incorrect twitter handle, handle must include twitter.com ",
			);
		}
		if (
			!this.props.artistApplicationHandle.tiktokHandle.includes("tiktok.com")
		) {
			return this.props.alert.error(
				"incorrect tiktok handle, handle must include tiktok.com ",
			);
		}
		if (
			!this.props.artistApplicationHandle.instagramHandle.includes(
				"instagram.com",
			)
		) {
			return this.props.alert.error(
				"incorrect Instagram handle, handle must include instagram.com ",
			);
		}
		if (this.props.me.avatar === "" || this.props.me.avatar === null) {
			return this.props.alert.error("Upload a profile picture ");
		}
		const streamingLinks =
			this.props.artistApplicationHandle.streamingLinksHandle.split(",");
		data.append("caption", this.state.caption);
		// data.append("social_platform", platformLinks);
		data.append("video_url", this.state.video_url);
		data.append("gig_id", gig_id);
		data.append("applicant_id", user_id);
		data.append("instagramHandle", this.state.instagramHandle);
		data.append("facebookHandle", this.state.facebookHandle);
		data.append("tiktokHandle", this.state.tiktokHandle);
		data.append("twitterHandle", this.state.twitterHandle);

		// data.append("youtubeu_rl", this.state.youtubeStat.youtubeUrl);
		// data.append("youtube_view_count", this.state.youtubeStat.viewCount);
		// data.append("youtube_video_count", this.state.youtubeStat.videoCount);
		// data.append('soundcloud_profile',this.state.soundCloudStat.profile )
		// data.append('soundcloud_trackscount',this.state.soundCloudStat.tracksCount )
		// data.append('soundcloud_followers',this.state.soundCloudStat.followers )
		// data.append('soundcloud_url',this.state.soundCloudStat.soundCloudUrl )
		// data.append('instagram_follower',this.state.instagramStat.edge_followed_by )
		// data.append('instagram_profile',this.state.instagramStat.profile_url )

		// console.log(data);
		const userObject = {
			id: user_id,
			name: this.props.userDetails.name,
			email: this.props.userDetails.email,
		};
		db.collection(`gigs/${gig_name}/applications`)

			.add({
				gigname: `${gig_name}`,
				caption: this.props.artistApplicationHandle.caption,
				artistVideo: this.props.artistApplicationHandle.artistVideo,
				facebookHandle: this.props.artistApplicationHandle.facebookHandle,
				twitterHandle: this.props.artistApplicationHandle.twitterHandle,
				tiktokHandle: this.props.artistApplicationHandle.tiktokHandle,
				instagramHandle: this.props.artistApplicationHandle.instagramHandle,
				phoneNumber: this.props.artistApplicationHandle.phoneNumber,
				streamingLinksHandle: streamingLinks,
				user: userObject,
				timestamp: Date.now(),
				userProfilePicture: this.props.me.avatar,
        // add stats
        stats: {
          youtube: this.state.youtubeStat,
          instagram: '',
          facebook: '',
          tiktok: '',
          soundCloud: this.state.soundCloudStat
        }
			})
			.then((docRef) => {
				console.log("Document written with ID: ", docRef.id);
				axios.post(`${url}gigs_application/`, data, config).then((res) => {
					console.log(res);
					if (res.statusText === "Created") {
						this.props.history.push("/successfulApplication");
					}
				});
			})
			.catch((error) => {
				console.error("Error adding document: ", error);
			});
	};
  handleProfileImg = (e) => {
    this.setState({
      img: e.target.files[0],
      uploadProfilePicture: true,
    });
    setTimeout(() => {
      const { img } = this.state;
      e.preventDefault();
      const ref = storage.ref(`/profile/images/${img.name}`);
      const uploadTask = ref.put(img);
      uploadTask.on("state_changed", console.log, console.error, () => {
        ref.getDownloadURL().then((url) => {
          this.setState({ img: null });
          this.props.avatarUpdate(url);
          this.props.loadUser();
          this.setState({ imgUrl: url, uploadProfilePicture: false });
        });
      });
    }, 1000);
    console.log(e.target.files[0]);
  };
  fetchData = () => {
    db.collection("gigs")
      .doc("ibadan")
      .collection("ibadan-festival")
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((element) => {
          let data = element.data();
          console.log(data);
        });
      });
  };
  async componentDidMount() {
    await this.props.loadUser();
    // window.addEventListener("load", () => {
    // this.fetchData();
    // });
    this.props.startPost();

    const video = store.getState();
    // this.setState({
    //   facebookHandle: video.artistApplicationReducer.artist.facebookHandle,
    //   instagramHandle: video.artistApplicationReducer.artist.instagramHandle,
    //   twitterHandle: video.artistApplicationReducer.artist.twitterHandle,
    //   tiktokHandle: video.artistApplicationReducer.artist.tiktokHandle,
    // });
    console.log(video);
    console.log(video.share.isUploadedToStorage);
    this.setState({
      isUploadedToStorage: video.share.isUploadedToStorage,
    });
    if (video.share.isUploadedToStorage) {
      let blob = await fetch(sessionStorage.getItem("video")).then((r) =>
        r.blob()
      );
      console.log(blob);
      let bucketName = "gig";
      let file = blob;
      this.setState({
        ...this.state,
        videoToGet: localStorage.getItem("id") + "/" + new Date().getTime(),
        downloadingVideo: true,
      });

      let storageRef = firebase
        .storage()
        .ref(
          `${bucketName}/video/${
            localStorage.getItem("id") + "/" + new Date().getTime()
          }`
        );
      let uploadTask = storageRef.put(file);
      console.log(uploadTask);
      uploadTask.on(
        firebase.storage.TaskEvent.STATE_CHANGED,
        (snap) => {
          console.log(snap);
          const percentUploaded = Math.round(
            (snap.bytesTransferred / snap.totalBytes) * 100
          );
          const isUploading =
            Math.round((snap.bytesTransferred / snap.totalBytes) * 100) === 100;
          this.setState({
            percentUploaded,
            isUploading,
          });
          console.log(firebase.storage().ref().child(`${storageRef}`));
          this.setState({
            videoName: firebase.storage().ref().child(`${storageRef}`),
          });
        },
        (err) => console.log(err),
        () => {
          storageRef.getDownloadURL(uploadTask.snapshot.ref).then((url) => {
            this.setState({
              video_url: url,
              downloadingVideo: false,
            });
            this.props.artistHandle("ARTIST_VIDEO", url);
          });
        }
      );

      this.props.videoUploadedStorageFalse();
      // this.handleDownloadUrl();
      console.log(this.state.video_url);
    }
    this.setState({
      isUploading: false,
    });
  }
  render() {
    const video = sessionStorage.getItem("video");
    const {
      caption,
      video_url,
      isUploading,
      downloadingVideo,
      uploadProfilePicture,
    } = this.state;

    return (
			<div>
				<div>
					<Navbar />
				</div>
				<form className="p-4">
					{/* upload profile picture*/}
					<div className="topPage">
						{uploadProfilePicture ? (
							<CircleLoader />
						) : (
							<Avatar
								src={this.props.me.avatar}
								className="userPic"
								sizes="large"
							/>
						)}

						<label>Upload a profile picture</label>
						<input
							placeholder="Upload a profile picture"
							onChange={this.handleProfileImg}
							type="file"
						/>
					</div>

					{/* upload a video */}
					<div className="border border-blue-900 rounded h-28 p-24">
						{this.props.artistApplicationHandle.artistVideo === "" &&
						isUploading === false ? (
							<Link className="py-14" to="/shareVideoGig" id="">
								<img className="w-28 mx-auto" src={record} alt="" />
								<p className="pt-5 font-bold text-blue-800">Record a Video</p>
								{isUploading && (
									<p className="pt-5 font-bold text-green-900">
										Video Uploaded successfully
									</p>
								)}
							</Link>
						) : (
							<div>
								{isUploading && downloadingVideo ? (
									<div className="center-loader">
										<MyLoader />
									</div>
								) : (
									<VideoPlayer
										id="VideoPostContent"
										url={this.props.artistApplicationHandle.artistVideo}
										autosize
									/>
								)}

								<div style={{ marginTop: "20px" }}>
									<button className="uploadButton bg-blue-700 text-white w-full">
										<Link to="/shareVideoGig" id="">
											Upload another video
										</Link>
									</button>
								</div>
							</div>
						)}

						{video !== "" ? <p></p> : null}
					</div>

					{/*  Add a caption */}
					<div className="flex flex-col pt-5 pb-5">
						<label
							className="my-1 text-sm items-start mr-auto"
							htmlFor="caption"
						>
							Caption
						</label>
						<textarea
							className="py-2 text-sm pl-5 "
							rows="5"
							placeholder="A brief description about your self"
							name="caption"
							// id="caption"
							value={this.props.artistApplicationHandle.caption}
							onChange={this.handleArtistCaption}
						/>
					</div>

					{/*Add a phone number*/}
					<>
						<input
							required
							placeholder="Phone number"
							type="number"
							value={this.props.artistApplicationHandle.phoneNumber}
							onChange={this.handlePhoneNumber}
						/>
					</>

					{/*Heading for social media links section */}
					<>
						<p className="my-8 text-sm font-bold">
							Please paste your social links below
						</p>
					</>

					{/*Social Media Links Section*/}
					<div style={{ width: "100%" }}>
						<input
							type="text"
							required
							style={{ marginTop: "5px" }}
							id="facebook"
							// onFocus={}
							placeholder="Facebook"
							value={this.props.artistApplicationHandle.facebookHandle}
							onChange={this.handleFacebookHandle}
						/>
						<input
							type="text"
							required
							style={{ marginTop: "5px" }}
							id="twitter"
							placeholder="Twitter"
							value={this.props.artistApplicationHandle.twitterHandle}
							onChange={this.handleTwitterHandle}
						/>

						<input
							required
							type="text"
							style={{ marginTop: "5px" }}
							id="tiktok"
							placeholder="Tiktok"
							value={this.props.artistApplicationHandle.tiktokHandle}
							onChange={this.handleTiktokHandle}
						/>
						<input
							required
							type={"text"}
							style={{ marginTop: "5px" }}
							id="Instagram"
							placeholder="Instagram"
							value={this.props.artistApplicationHandle.instagramHandle}
							onChange={this.handleInstagramHandle}
						/>
					</div>

					{/*  Label for streaming links */}
					<div>
						<p className="my-8 text-sm font-bold">
							Please paste your streaming links below
						</p>
					</div>
					<div className="flex flex-col pt-5 pb-5">
						<textarea
							className="py-2 text-sm pl-5 "
							rows="5"
							placeholder="spotify, youtube, soundcloud...."
							name="streamingLinks"
							id="streamingLinks"
							value={this.props.artistApplicationHandle.streamingLinksHandle}
							onChange={this.handleStreamLinks}
						/>
					</div>

					<div className="postgig-btn-wrapper content-center mb-12">
						<button
							className="my-5 bg-blue-700 text-white w-full py-2 px-4 rounded"
							type="submit"
							variant="contained"
							size="large"
							style={{ textTransform: "none" }}
							onClick={this.handlePost}
						>
							Apply
						</button>
					</div>
				</form>
				{/* On Video upload section */}
				{!this.state.isUploading && this.state.isUploadedToStorage && (
					<div id="uploadingProgress">
						<div>
							<p>Upload In Progress...</p>
							<LinearProgress value={this.state.percentUploaded} />
						</div>
					</div>
				)}
				<video id="videoUploaded" />
				{/* <img id='imgUploaded'/> */}
				<div id="sharePhotoBottomLine" />
				<div className="mt-24">
					<BottomNav />
				</div>
			</div>
		);
  }
}
const mapStateToProps = (state) => ({
  me: state.auth.profile,
  userDetails: state.auth.me[0],
  person: state.profile.person,
  artistApplicationHandle: state.artistApplicationReducer.artistHandle,
  uploadedIMG: state.posts.uploadedIMG,
  isvideoUploaded: state.videoUploaded,
});
export default connect(mapStateToProps, {
  getProfileInfo,
  postVideo,
  startPost,
  videoUploadedStorage,
  videoUploadedStorageFalse,
  createMessage,
  artistHandle,
  avatarUpdate,
  loadUser,
})(withAlert()(LatestArtistApplication));
