import {
  FACEBOOK,
  TWITTER,
  INSTAGRAM,
  TIKTOK,
  ARTIST_CAPTION,
  PHONE_NUMBER,
  ARTIST_VIDEO,
  STREAMINGLINKS,
} from "../wm_actions/types";

const initialState = {
  artistHandle: {
    facebookHandle: "",
    twitterHandle: "",
    tiktokHandle: "",
    instagramHandle: "",
    phoneNumber: "",
    caption: "",
    artistVideo: "",
    streamingLinksHandle: "",
  },
};

export const artistApplicationReducer = (state = initialState, action) => {
  switch (action.type) {
    case FACEBOOK:
      return {
        ...state,
        artistHandle: {
          ...state.artistHandle,
          facebookHandle: action.payload,
        },
      };
    case TWITTER:
      return {
        ...state,
        artistHandle: {
          ...state.artistHandle,
          twitterHandle: action.payload,
        },
      };
    case INSTAGRAM:
      return {
        ...state,
        artistHandle: {
          ...state.artistHandle,
          instagramHandle: action.payload,
        },
      };
    case TIKTOK:
      return {
        ...state,
        artistHandle: {
          ...state.artistHandle,
          tiktokHandle: action.payload,
        },
      };
    case ARTIST_CAPTION:
      return {
        ...state,
        artistHandle: {
          ...state.artistHandle,
          caption: action.payload,
        },
      };
    case PHONE_NUMBER:
      return {
        ...state,
        artistHandle: {
          ...state.artistHandle,
          phoneNumber: action.payload,
        },
      };
    case ARTIST_VIDEO:
      return {
        ...state,
        artistHandle: {
          ...state.artistHandle,
          artistVideo: action.payload,
        },
      };
    case STREAMINGLINKS:
      return {
        ...state,
        artistHandle: {
          ...state.artistHandle,
          streamingLinksHandle: action.payload,
        },
      };
    default:
      return state;
  }
};
