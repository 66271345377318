import React, { useEffect, useState } from "react";
import Navbar from "../wm_navigation/Navbar";
import "./feedback.css";
import axios from "axios";
import mixpanel from "../../components/Mixpanel";

const Feedback = () => {
	const [feedback, setFeedback] = useState("");
	const [message, setMessage] = useState("");

	useEffect(() => {
		setTimeout(() => {
			setMessage("");
		}, 3000);
	}, [message]);

	useEffect(() => {
		if (message) {
			setFeedback("");
		}
	}, [message]);

	const submitFeedback = async (e) => {
		e.preventDefault();
		const response = await axios
			.post(
				"https://server.whipafrica.com/v1/feedback/",
				{
					body: feedback,
				},
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: `Token ${localStorage.getItem("key")}`,
					},
				},
			)
			.then((res) => {
				console.log(res);
				mixpanel.track("Feedback Submitted");
				if (res.status === 201) {
					setMessage("Thank you for your feedback");
				}
			})
			.catch((err) => {
				setMessage("Something went wrong");
				mixpanel.track("Feedback Submission Failed");
			});
	};
	return (
		<div>
			<div>
				<Navbar />
			</div>
			<div id="feedbackHeadContainer">
				<h1 id="feedbackHeading">Feedback</h1>
				<p id="feedbackPara">
					{message
						? message
						: "Do you have recommendations for us? We will love to hear from you"}
				</p>
			</div>
			<form id="feedbackInputContainer" onSubmit={submitFeedback}>
				<textarea
					value={feedback}
					placeholder="Type Here..."
					onChange={(e) => setFeedback(e.target.value)}
				/>
				<input type="submit" id="feedbackSubmitButton" />
			</form>
		</div>
	);
};

export default Feedback;
