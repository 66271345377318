import {
	FETCHING_CHAT,
	FETCHED_CHAT,
	FETCH_CHAT_FAILED,
	CREATE_GET_ROOM_CHAT,
	CHAT_HISTORY,
	SEND_MESSAGE_CHAT,
	FETCH_CURRENT_CHAT,
	CURRENT_CHAT,
	SEARCH_USERS_CHAT
} from "../wm_actions/types";

const initialState = {
	chatArray: [],
	fetchingChat: false,
	currentChat: false,
	createChat: false,
	msgSend: false,
	searchUsers: ""
};

export default function (state = initialState, action) {
	switch (action.type) {
		case FETCHING_CHAT:
			return {
				...state,
				chatArray: [],
				fetchingChat: true
			};
		case FETCHED_CHAT:
			return {
				...state,
				chatArray: action.payload,
				fetchingChat: false
			};
		case CREATE_GET_ROOM_CHAT:
			return {
				...state,
				chatArray: action.payload,
				createChat: action.payload,
				fetchingChat: false
			};
		case CHAT_HISTORY:
			console.log(action.payload, "CHAT_HISTORY");
			return {
				...state,
				chatArray: action.payload
			};
		case SEND_MESSAGE_CHAT:
			console.log(action.payload, "SEND_MESSAGE_CHAT");
			return {
				...state,
				chatArray: action.payload,
				msgSend: action.payload
			};
		case CURRENT_CHAT:
			return {
				...state,
				currentChat: action.payload?.results
			};
		case SEARCH_USERS_CHAT:
			return {
				...state,
				searchUsers: action.payload.value
			};
		default:
			return state;
	}
}
