import React from "react";
import { getPersonalPosts } from "../../wm_actions/posts";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import {
  professionConstants,
  countryConstants,
} from "../../wm_constants/index";
import like from "../../../assets/images/Posts/like.png";
import comment from "../../../assets/images/Posts/comment.png";
import share from "../../../assets/images/Posts/share.png";
import CircleLoader from "../../../loader/CircleLoader";
import VideoPlayer from "react-simple-video-player";
import Linkify from "react-linkify";
import Like from "../../wm_posts/like";
import axios from "axios";
import _ from "lodash";
import {
  postTextLike,
  postImageLike,
  postVideoLike,
} from "../../wm_actions/posts";

import CommentSystem from "../../wm_posts/component/comments";
import { config } from "../../Endpoints";

const url = config.url.API_URL;
const frontend_url = config.url.FRONTEND_URL;
class MyPosts extends React.Component {
  state = {
    likedState: new Array(this.props.personalPosts.length).fill(false),
    commentSystem: false,
  };
  static propTypes = {
    personalPosts: PropTypes.array,
    me: PropTypes.array,
  };
  componentWillMount() {
    this.props.getPersonalPosts();
  }

  onText = (e) => {
    this.setState({
      postId: e,
    });
    this.t = setTimeout(() => this.props.postTextLike(this.state.postId), 200);
  };
  onVideo = (e) => {
    this.setState({
      postId: e,
    });
    this.t = setTimeout(() => this.props.postVideoLike(this.state.postId), 200);
  };
  onImage = (e) => {
    this.setState({
      postId: e,
    });
    this.t = setTimeout(() => this.props.postImageLike(this.state.postId), 200);
  };
  onLike = (id, i) => {
    const updateLikedState = this.state.likedState.map((item, index) =>
      index === i ? !item : item
    );

    this.setState({
      likedState: updateLikedState,
    });
    const { authKey } = this.props;

    axios
      .post(
        `${url}posts/posts/like/`,
        { id: id },
        {
          headers: {
            Authorization: `Token ${authKey}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          this.props.getPersonalPosts();
        }
      })
      .catch((err) => console.log(err?.response));
  };
  onSubmit = (e, pid) => {
    e.preventDefault();
  };
  commentSystemToggle = () => {
    this.setState({
      commentSystem: !this.state.commentSystem,
    });
  };
  // share post dropdown
  handleDropdownClick = (i) => {
    this.setState({ clicked: i, open: !this.state.open });
    console.log(this.state.open);
  };
  // copy url from dropdown
  copyUrl = (id, type) => {
    const el = document.createElement("input");
    el.value = `${frontend_url}posts/${type}/${id}`;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    this.setState({ open: !this.state.open });
  };

  handleFetchPosts = () => {
    this.props.getPersonalPosts();
  };
  render() {
    console.log(this.context);
    return (
      <div id="myPostsContainer" style={{ marginBottom: "100px" }}>
        {this.props.personalPosts ? (
          this.props.personalPosts.length ? (
            this.props.personalPosts
              .sort((a, b) =>
                b.created_at < a.created_at
                  ? -1
                  : b.created_at > a.created_at
                  ? 1
                  : 0
              )
              .map((post, index) => (
                <Card className="root" key={post.id}>
                  {/* <Link to={`/profile/${post.created_by.id}/`}> */}
                  <CardHeader
                    avatar={
                      <Avatar
                        aria-label="recipe"
                        className="avatar"
                        src={this.props.me[0].profile.avatar}
                      ></Avatar>
                    }
                    title={this.props.me[0].name}
                    subheader={professionConstants.map((proC) => (
                      <div>
                        <p>
                          {proC.id === this.props.me[0].profile.profession[0]
                            ? proC.name
                            : null}
                        </p>
                        <p>
                          {proC.id === this.props.me[0].profile.profession[1]
                            ? proC.name
                            : null}
                        </p>
                      </div>
                    ))}
                  />
                  <CardContent id="cardContent">
                    {post?.post_type === "text" && (
                      <Typography id="textPostContent">
                        {/* format text post */}

                        <div
                          className="text-body"
                          style={{
                            whiteSpace: "pre-wrap",
                          }}
                        >
                          <Linkify
                            componentDecorator={(
                              decoratedHref,
                              decoratedText,
                              key
                            ) => (
                              <a
                                target="blank"
                                href={decoratedHref}
                                rel="nonreferrer"
                                key={key}
                              >
                                {decoratedText}
                              </a>
                            )}
                          >
                            {post?.caption}
                          </Linkify>
                        </div>
                      </Typography>
                    )}
                    {post?.post_type === "image" && (
                      <>
                        <img
                          id="ImagePostContent"
                          src={post?.url}
                          alt={post?.caption}
                        />
                        <Typography id="caption">{post?.caption}</Typography>
                      </>
                    )}

                    {post?.post_type === "video" ? (
                      <>
                        <VideoPlayer
                          id="VideoPostContent"
                          url={post?.url}
                          autosize
                        />
                        <Typography id="caption">{post?.caption}</Typography>
                      </>
                    ) : null}
                  </CardContent>
                  <div id="reaction-wrapper">
                    <form onSubmit={this.onSubmit} id="reactionForm">
                      <div id="reactions">
                        <Like
                          liked={
                            post.likes?.find(
                              (like) => like.id === localStorage.getItem("id")
                            ) === undefined
                              ? false
                              : true
                          }
                          likes={post?.likes.length}
                          postId={post?.id}
                          func={() => this.onLike(post?.id, index)}
                        />
                      </div>
                      <div id="reactions">
                        <div id="commentWrap">
                          {this.state.commentSystem === post?.id ? (
                            <>
                              <button
                                name="postId"
                                value={post?.id}
                                onClick={() =>
                                  this.setState({ commentSystem: false })
                                }
                                id="post-like-btn"
                                type="submit"
                              >
                                <img
                                  alt=""
                                  className="w-full h-full object-fit object-cover"
                                  src={comment}
                                />
                              </button>
                              <p>{post?.Comments.length} Comments</p>
                            </>
                          ) : (
                            <>
                              <button
                                name="postId"
                                value={post?.id}
                                onClick={() =>
                                  this.setState({ commentSystem: post?.id })
                                }
                                id="post-like-btn"
                                type="submit"
                              >
                                <img
                                  alt=""
                                  className="LSCButtons"
                                  src={comment}
                                />
                              </button>
                              <p>{post?.Comments.length} Comments</p>
                            </>
                          )}
                        </div>
                      </div>
                      <div id="reactions">
                        <div id="shareWrap">
                          <button
                            name="postId"
                            value={post?.id}
                            onClick={() => this.handleDropdownClick(post?.id)}
                            id="post-like-btn"
                            type="submit"
                          >
                            <img alt="" className="LSCButtons" src={share} />
                          </button>
                          {this.state.open && post.id === this.state.clicked && (
                            <div className="dropdown">
                              <ul>
                                <li
                                  className="share-text"
                                  key="1"
                                  onClick={() => this.copyUrl(post.id, "post")}
                                >
                                  Copy link to Post
                                </li>
                              </ul>
                            </div>
                          )}
                          <p>{post?.Comments.length} Shares</p>
                        </div>
                      </div>
                    </form>
                  </div>
                  {this.state?.commentSystem === post?.id ? (
                    <>
                      <div>
                        <CommentSystem
                          commentId={post?.id}
                          authKey={this.props.authKey}
                          func={this.handleFetchPosts}
                        />
                      </div>
                      {post?.Comments?.map((comment) => (
                        <div className="px-4 py-2 flex justify-start gap-1 items-start">
                          <div className="w-12 h-10 rounded-full">
                            <img
                              src={comment?.comment_by.profile?.avatar}
                              className="rounded-full w-full h-full object-fit object-cover"
                              alt="avatar"
                            />
                          </div>
                          <div
                            style={{ backgroundColor: "#FFFCF6" }}
                            className="text-left w-full p-3"
                          >
                            <p
                              style={{
                                color: "#9C7B39",
                                fontFamily: "Poppins",
                                fontWeight: 700,
                              }}
                              className="text-xs"
                            >
                              {_.capitalize(comment?.comment_by?.name)}
                            </p>
                            <div className="flex items-center">
                              {professionConstants.map((proC) => (
                                <p
                                  style={{
                                    color: "##666666",
                                    fontFamily: "Poppins",
                                    fontWeight: 400,
                                    fontSize: "8px",
                                  }}
                                  className="mr-0.5"
                                >
                                  {proC.id ===
                                  comment?.comment_by?.profile.profession[0]
                                    ? proC.name
                                    : null}
                                </p>
                              ))}
                              <p
                                style={{
                                  color: "##666666",
                                  fontFamily: "Poppins",
                                  fontWeight: 400,
                                  fontSize: "8px",
                                }}
                              >
                                ,{"  "}
                              </p>
                              {countryConstants?.map((country) => (
                                <p
                                  style={{
                                    color: "##666666",
                                    fontFamily: "Poppins",
                                    fontWeight: 400,
                                    fontSize: "8px",
                                  }}
                                >
                                  {country.id === comment?.comment_by?.country
                                    ? country.name
                                    : null}
                                </p>
                              ))}
                            </div>
                            <p
                              style={{
                                fontFamily: "Poppins",
                                fontWeight: 400,
                              }}
                              className="text-sm mt-2"
                            >
                              {comment?.comment}
                            </p>
                          </div>
                        </div>
                        // <p>{comment.comment}</p>
                      ))}
                    </>
                  ) : null}
                </Card>
              ))
          ) : (
            <div className="ProfileLoaderWrapper">
              <h1>Nothing To Show</h1>
            </div>
          )
        ) : (
          <div className="ProfileLoaderWrapper">
            <div className="loader"></div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  personalPosts: state.posts.personalPosts,
  me: state.auth.me,
  authKey: state.auth.key,
});
export default connect(mapStateToProps, {
  getPersonalPosts,
  postTextLike,
  postImageLike,
  postVideoLike,
})(MyPosts);
