import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { postVideo, startPost } from "../../pages/wm_actions/posts";
import firebase, { storage } from "../../components/Firebase/firebase";
import LinearProgress from "@material-ui/core/LinearProgress";
import subscriptions from "../../assets/images/share/subscriptions.png";
import { Link } from "react-router-dom";
import { Button } from "@material-ui/core";
import {
	videoUploadedStorage,
	videoUploadedStorageFalse,
} from "../wm_actions/share";
import store from "../wm_store/store";
import record from "../../assets/images/gigs/camera.png";
import Navbar from "../wm_navigation/Navbar";
import BottomNav from "../wm_navigation/BottomNav";
import { Redirect } from "react-router-dom";
import axios from "axios";
import { config } from "../Endpoints";
import { createMessage } from "../wm_actions/messages";

const url = config.url.API_URL;
class ArtistApplication extends React.Component {
	static propTypes = {
		postVideo: PropTypes.func.isRequired,
		uploadedIMG: PropTypes.bool.isRequired,
		videoUploadedStorageFalse: PropTypes.func.isRequired,
	};
	state = {
		caption: "",
		video_url: "",
		videoName: "",
		percentUploaded: 0,
		isUploading: false,
		videoToGet: "",
		caption: "",
		userId: "",
		isUploadedToStorage: false,
		youtubeStat: {},
		youtubeUrl: "",
		soundCloudStat: {},
		socialPlatformList: [{ socialPlatform: "", handle: "" }],
	};

	onChange = (e) => {
		this.setState({
			[e.target.name]: e.target.value,
		});
	};
	data = [
		"Youtube",
		"Instagram",
		"Twitter",
		"Spotify",
		"Tiktok",
		"iTunes",
		"SoundCloud",
		"Shazam",
	];
	handlesocialPlatformChange = (e, index) => {
		console.log(this.state.socialPlatformList);
		const { name, value } = e.target;
		console.log(value);
		if (value.includes("youtube.com")) {
			console.log("youtube url");
			this.setState({ youtubeUrl: value });
			this.fetchYoutubeStat(value);
		}
		if (value.includes("soundcloud.com")) {
			console.log("sound cloud url");
			// this.setState({youtubeUrl:value})
			this.fetchSoundCloudStat(value);
		}
		if (value.includes("instagram.com")) {
			console.log("instagram url");
			// this.setState({youtubeUrl:value})
			this.fetchInstagramStat(value);
		}

		const list = [...this.state.socialPlatformList];
		list[index][name] = value;
		console.log();
		this.setState({ socialPlatformList: list });
	};
	fetchYoutubeStat = (youtubeUrl) => {
		let formatValue = youtubeUrl.split("/");
		let channel_id = formatValue[formatValue.length - 1];
		console.log(channel_id);
		const options = {
			method: "GET",
			url: `https://youtube-data-scraper.p.rapidapi.com/channel/statistics/${channel_id}`,
			headers: {
				"X-RapidAPI-Host": "youtube-data-scraper.p.rapidapi.com",
				"X-RapidAPI-Key": "7a605ab4e5msh5ea047e05061264p183150jsn5d1daf8c0b66",
			},
		};
		axios
			.request(options)
			.then((response) => {
				console.log(response.data);
				let { viewCount, videoCount } = response.data;
				this.setState({
					youtubeStat: {
						youtubeUrl: youtubeUrl,
						viewCount: viewCount,
						videoCount: videoCount,
					},
				});
			})
			.catch((error) => {
				console.error(error);
			});
	};
	fetchSoundCloudStat = (soundCloudUrl) => {
		const options = {
			method: "GET",
			url: "https://soundcloud4.p.rapidapi.com/user/info",
			params: { profile_url: soundCloudUrl },
			headers: {
				"X-RapidAPI-Host": "soundcloud4.p.rapidapi.com",
				"X-RapidAPI-Key": "7a605ab4e5msh5ea047e05061264p183150jsn5d1daf8c0b66",
			},
		};
		axios
			.request(options)
			.then((response) => {
				console.log(response);
				let { profile, tracksCount, followers } = response.data;
				console.log(profile);
				this.setState({
					soundCloudStat: {
						profile: profile,
						tracksCount: tracksCount,
						followers: followers,
						soundCloudUrl: soundCloudUrl,
					},
				});
				console.log(this.state);
			})
			.catch((error) => {
				console.error(error);
			});
	};
	fetchInstagramStat = (instagramUrl) => {
		let formatValue = instagramUrl.split("/");
		const result = formatValue.filter((x) => x !== "/").filter((x) => x !== "");
		console.log(result);
		let username = result[result.length - 1];
		console.log(username);
		const options = {
			method: "GET",
			url: "https://instagram130.p.rapidapi.com/account-info",
			params: { username: username },
			headers: {
				"X-RapidAPI-Host": "instagram130.p.rapidapi.com",
				"X-RapidAPI-Key": "7a605ab4e5msh5ea047e05061264p183150jsn5d1daf8c0b66",
			},
		};

		axios
			.request(options)
			.then((response) => {
				console.log(response.data);
				let { edge_followed_by } = response.data;
				// console.log(profile)
				this.setState({
					instagramStat: {
						edge_followed_by: edge_followed_by,
						profile_url: instagramUrl,
					},
				});
				console.log(this.state);
			})
			.catch((error) => {
				console.error(error);
			});
	};
	handlesocialPlatformRemove = (index) => {
		if (
			this.state.socialPlatformList[index].socialPlatform.includes(
				"youtube.com",
			)
		) {
			this.setState({ youtubeStat: {} });
		}
		if (
			this.state.socialPlatformList[index].socialPlatform.includes(
				"soundcloud.com",
			)
		) {
			this.setState({ soundCloudStat: {} });
		}
		if (
			this.state.socialPlatformList[index].socialPlatform.includes(
				"instagram.com",
			)
		) {
			this.setState({ instagramStat: {} });
		}

		const list = [...this.state.socialPlatformList];
		list.splice(index, 1);
		this.setState({ socialPlatformList: list });
	};

	handlesocialPlatformAdd = () => {
		console.log("working");
		this.setState({
			socialPlatformList: [
				...this.state.socialPlatformList,
				{ socialPlatform: "" },
			],
		});
	};
	handleDownloadUrl = () => {
		let spaceRef = this.state.videoName;
		console.log(spaceRef, "spaceRef");
		let GetVideo = `https://firebasestorage.googleapis.com/v0/b/whip-mvp.appspot.com/o/gig%2Fvideo%2F${localStorage.getItem("userId")}%2F${new Date().getTime()}?alt=media`;
		console.log(GetVideo);
		this.setState({
			video_url: GetVideo,
		});
	};

	// onSubmit = (e) => {

	// };

	handlePost = async () => {
		let authKey = localStorage.getItem("key");
		const config = {
			headers: {
				Authorization: `Token ${authKey}`,
				"Content-Type": "application/json",
			},
		};
		const gig_id = localStorage.getItem("gig_id");
		const user_id = localStorage.getItem("userId");
		console.log(gig_id);
		console.log(this.state.video_url);
		console.log(this.state.socialPlatformList);
		console.log(this.state.caption);
		let platformLinks = JSON.stringify(this.state.socialPlatformList);
		const data = new FormData();
		if (this.state.video_url === "" || this.state.caption === "") {
			return this.props.createMessage("Please fill the form");
		}
		data.append("caption", this.state.caption);
		data.append("social_platform", platformLinks);
		data.append("video_url", this.state.video_url);
		data.append("gig_id", gig_id);
		data.append("applicant_id", user_id);
		data.append('youtubeu_rl',this.state.youtubeStat.youtubeUrl )
		data.append('youtube_view_count',this.state.youtubeStat.viewCount )
		data.append('youtube_video_count',this.state.youtubeStat.videoCount )
		// data.append('soundcloud_profile',this.state.soundCloudStat.profile )
		// data.append('soundcloud_trackscount',this.state.soundCloudStat.tracksCount )
		// data.append('soundcloud_followers',this.state.soundCloudStat.followers )
		// data.append('soundcloud_url',this.state.soundCloudStat.soundCloudUrl )
		// data.append('instagram_follower',this.state.instagramStat.edge_followed_by )
		// data.append('instagram_profile',this.state.instagramStat.profile_url )

		console.log(data);
		axios.post(`${url}gigs_application/`, data, config).then((res)=>{
			if (res.status ===200) {
				this.props.history.push("/successfulApplication");

			}
		});
	};

	async componentDidMount() {
		this.props.startPost();
		const video = store.getState();
		console.log(video);
		console.log(video.share.isUploadedToStorage);
		this.setState({
			isUploadedToStorage: video.share.isUploadedToStorage,
		});
		if (video.share.isUploadedToStorage) {
			let blob = await fetch(sessionStorage.getItem("video")).then((r) =>
				r.blob(),
			);
			console.log(blob);
			let bucketName = "gig";
			let file = blob;
			this.setState({
				...this.state,
				videoToGet: localStorage.getItem("userId") + '/' + new Date().getTime(),
			});

			let storageRef = firebase
				.storage()
				.ref(`${bucketName}/video/${localStorage.getItem("userId") + '/' + new Date().getTime()}`);
			let uploadTask = storageRef.put(file);
			console.log(uploadTask);
			uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, (snap) => {
				console.log(snap);
				const percentUploaded = Math.round(
					(snap.bytesTransferred / snap.totalBytes) * 100,
				);
				const isUploading =
					Math.round((snap.bytesTransferred / snap.totalBytes) * 100) === 100;
				this.setState({
					percentUploaded,
					isUploading,
				});
				this.setState({
					videoName: firebase.storage().ref().child(`${storageRef}`),
				});
			});

			this.props.videoUploadedStorageFalse();
			this.handleDownloadUrl();
			console.log(this.state.video_url);
		}
		this.setState({
			isUploading: false,
		});
	}
	render() {
		const video = sessionStorage.getItem("video");
		const { caption, video_url, isUploading } = this.state;

		return (
			<div>
				<div>
					<Navbar />
				</div>
				<form className="p-4">
					<div className="">
						<div className="border border-blue-900 rounded h-28 p-24">
							<Link className="py-14" to="/shareVideoGig" id="">
								<img className="w-28 mx-auto" src={record} alt="" />
								<p className="pt-5 font-bold text-blue-800">Record a Video</p>
								{isUploading && <p className="pt-5 font-bold text-green-900">Video Uploaded successfully</p>
}
							</Link>
							{video !== "" ? <p></p> : null}
						</div>
					</div>
					<div className="flex flex-col pt-5">
						<label className="my-1 text-sm items-start mr-auto" for="caption">
							Caption
						</label>
						<textarea
							className="py-2 text-sm pl-5"
							rows="5"
							placeholder=""
							name="caption"
							id="caption"
							value={caption}
							onChange={(e) => this.setState({ caption: e.target.value })}
						/>
					</div>
					<div>
						<p className="my-8 text-sm font-bold">
							Please paste your social links below
						</p>
					</div>
					<div className="form-field">
						{this.state.socialPlatformList.map(
							(singlesocialPlatform, index) => (
								<div key={index} className="socialPlatforms">
									<div className="first-division text-xs">
										<div className="flex items-center">
											<select
												required
												onChange={(e) =>
													this.handlesocialPlatformChange(e, index)
												}
												name="handle"
												value={singlesocialPlatform.handle}
											>
												<option value="">Select a platform</option>

												{this.data.map((x, y) => (
													<option key={y}>{x}</option>
												))}
											</select>
											<input
												className="text-xs pl-2 border bg-transparent border-gray-600 rounded py-3 rounder mx-2"
												name="socialPlatform"
												type="url"
												id="socialPlatform"
												value={singlesocialPlatform.socialPlatform}
												onChange={(e) =>
													this.handlesocialPlatformChange(e, index)
												}
												required
											/>
											<br />
											{this.state.socialPlatformList.length - 1 === index &&
												this.state.socialPlatformList.length < 6 && (
													<button
														type="button"
														onClick={this.handlesocialPlatformAdd}
														className="add-btn text-blue-700 font-bold border-blue-700 rounded border-2 py-2 px-4"
													>
														Add
													</button>
												)}
										</div>
										<div className="second-division">
											{this.state.socialPlatformList.length !== 1 && (
												<button
													type="button"
													onClick={() => this.handlesocialPlatformRemove(index)}
													className="remove-btn text-red-600 border-red-700 rounded border-2 py-1 px-4"
												>
													<span>X</span>
												</button>
											)}
										</div>
									</div>
								</div>
							),
						)}
					</div>
					<div className="postgig-btn-wrapper content-center mb-12">
						<button
							className="my-5 bg-blue-700 text-white w-full py-2 px-4 rounded"
							type="submit"
							variant="contained"
							size="large"
							style={{ textTransform: "none" }}
							onClick={this.handlePost}
						>
							Apply
						</button>
					</div>
				</form>

				{!this.state.isUploading && this.state.isUploadedToStorage && (
					<div id="uploadingProgress">
						<div>
							<p>Upload In Progress...</p>
							<LinearProgress value={this.state.percentUploaded} />
						</div>
					</div>
				)}
				<video id="videoUploaded" />
				{/* <img id='imgUploaded'/> */}
				<div id="sharePhotoBottomLine" />
				<div className="mt-24">
					<BottomNav />
				</div>
			</div>
		);
	}
}
const mapStateToProps = (state) => ({
	uploadedIMG: state.posts.uploadedIMG,
	isvideoUploaded: state.videoUploaded,
});
export default connect(mapStateToProps, {
	postVideo,
	startPost,
	videoUploadedStorage,
	videoUploadedStorageFalse,
	createMessage,
})(ArtistApplication);
