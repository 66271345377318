import React, { useEffect, useLayoutEffect, useState } from "react";
import Navbar from "../wm_navigation/Navbar";
import BottomNav from "../wm_navigation/BottomNav";
import "./styles/home.css";
// import PropTypes from "prop-types";
import { connect } from "react-redux";
import { loadUser } from "../wm_actions/auth";
import { GetNotification } from "../wm_actions/Notification";
// import { loadUsers, updateSentRequest } from '../wm_actions/connects';
import { Redirect } from "react-router-dom";
import Posts from "../wm_posts/posts";
import mixpanel from "../../components/Mixpanel";
import { useLocalStorage } from "usehooks-ts";



const Home = (props) => {
    const [profession] = useState(props.profession);
    const [trackingStatus, setTrackingStatus] = useLocalStorage(
        "mixpanel_signIn",
        false
    );
    const { loadUser, GetNotification } = props;


    useLayoutEffect(() => {
        let isFetchingProps = true;
        if (isFetchingProps) {
            loadUser();
            GetNotification();
            console.log("fetching props")
        }

        return () => {
            isFetchingProps = false;
        }

        // console.log("User Professions",profession.length);

    }, [loadUser, GetNotification]);

    useEffect(() => {
        if (!trackingStatus) {
            mixpanel.track("Sign In");
            console.log("Current State", trackingStatus);
            setTrackingStatus(true);
        } else {
            console.log("Already Tracked");
            console.log("Current State", trackingStatus);
        }

        return () => {
            setTrackingStatus(true)
        }
    }, [trackingStatus]);

    if (
        JSON.parse(localStorage.getItem("Profession"))?.length >= 0 &&
        JSON.parse(localStorage.getItem("Profession"))?.length < 2
    ) {
        return <Redirect to="/completeProfile" />;
    } else
        return (
            <div id="homeWrapper">
                <Navbar />
                <Posts />
                <BottomNav />
            </div>
        );
};
// class Home extends React.Component {
// 	state = {
// 		profession: this.props.profession,
// 		trackingStatus: false,
// 	};

// 	async componentWillMount() {
// 		await this.props.loadUser();
// 		await this.props.GetNotification();
// 		console.log(this.props.profession.length);
// 		console.log("props", this.props);
// 		console.log("Current State", this.state);
// 		if(!this.state.trackingStatus){
// 				 mixpanel.track('Sign In')
// 		 		this.state.trackingStatus= true

// 		} else{
// 			console.log("Already Tracked")
// 		}

// 	}
// 	static propTypes = {
// 		users: PropTypes.string,
// 		profession: PropTypes.array,
// 		genre: PropTypes.array,
// 	};

// 	render() {
// 		if (
// 			JSON.parse(localStorage.getItem("Profession"))?.length >= 0 &&
// 			JSON.parse(localStorage.getItem("Profession"))?.length < 2
// 		) {
// 			return <Redirect to="/completeProfile" />;
// 		} else
// 			return (
// 				<div id="homeWrapper">
// 					<Navbar />
// 					<Posts />
// 					<BottomNav />
// 				</div>
// 			);
// 	}
// }
const mapStateToProps = (state) => ({
    profession: state.auth.profession,
    genre: state.auth.genre,
});
export default connect(mapStateToProps, { loadUser, GetNotification })(Home);
