import React, { useState } from 'react';
import { useFormik } from "formik";
import {Link} from "react-router-dom";
import {countryConstants} from "../../wm_constants";
import {connect} from "react-redux";
import {register} from "../../wm_actions/auth";


//renders the mobile version of the signup form
const MobileSignup = ({ logo, register, checked }) => {
    const [formErrors, setFormErrors] = useState({});

    const formik = useFormik({
        initialValues: {
            name: '',
            email: '',
            password1: '',
            password2: '',
            email2: '',
            country: 'Nigeria',

        },
        //TODO add validation
        validate: values => {

            const errors = {};
            if (!values.password1 === values.password2) {
                errors.passwords = "passwords do not match"
            }
            if (!values.email === values.email2) {
                errors.email = "Emails do not match"
            }
            if (!values.email) {
                errors.email = 'Required';
            } else if (
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
            ) {
                errors.email = 'Invalid email address';
            }
            console.log(errors);
            setFormErrors(errors);
            return errors;

        },
        onSubmit: values => {


            //use form Data to Hit API
            register(values);

            setTimeout(() => {
                console.log('submitting form:', values);
                // console.log('new user', newUser)
            }, 400);
        }

    });

    return (
        <div id="login-wrapper">
            {/*    Form goes here*/}
            <div id="overlayLogin">
                <div id="logo-wrapper">
                    <img alt="WHIP MUSIC AFRICA" src={logo} id="landing-logo"/>
                </div>
                <div>
                    <span className="text-white font-bold text-sm">Welcome to Whip Music Africa</span>
                </div>
                <div id="registerForm">

                    <form onSubmit={formik.handleSubmit}>


                        <div className={"FieldsOfRegister"}>
                            <p>Full Name</p>
                            <input
                                id="name"
                                name="name"
                                type="text"
                                onChange={formik.handleChange}
                                value={formik.values.name}
                                placeholder={"Name"}
                            />
                        </div>
                        <div className={"FieldsOfRegister"}>
                            <p>Email</p>
                            <input
                                id="email"
                                name="email"
                                type="email"
                                onChange={formik.handleChange}
                                value={formik.values.email}
                                placeholder={"Email"}
                            />
                        </div>
                        <div className={"FieldsOfRegister"}>
                            <p>Confirm Email</p>
                            <input
                                id="email"
                                name="email2"
                                type="email"
                                onChange={formik.handleChange}
                                value={formik.values.email2}
                                placeholder={"Confirm email"}
                            />
                        </div>
                        <div className="FieldsOfRegister">
                            <p>Country of Residency</p>
                            <select
                                className="px-4 pr-8 form-control "
                                name="country"
                                value={formik.values.country}
                                onChange={formik.handleChange}
                                id="countryConstants"
                            >
                                <option value="">Select country</option>
                                {/* {Object.entries(countryConstants).map((k, v) => <option value={k[0]}>{k[1]}</option>)} */}
                                {countryConstants.map((k) => (
                                    <option key={k.id} value={k.id} className="text-md text-white">
                                        {k.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className={"FieldsOfRegister"}>
                            <p>Password</p>
                            <input
                                id="password"
                                name="password1"
                                type="text"
                                onChange={formik.handleChange}
                                value={formik.values.password1}
                                placeholder={"Password"}
                            />
                        </div>
                        <div className={"FieldsOfRegister"}>
                            <p>Confirm Password</p>
                            <input
                                id="password"
                                name="password2"
                                type="text"
                                onChange={formik.handleChange}
                                value={formik.values.password2}
                                placeholder="Confirm password"
                            />
                        </div>
                        {/*    TODO add phone number input */}

                        <button type="submit" className="uppercase">
                            {checked ? (

                                <div className="loaderReset">

                                </div>
                            ) : (
                                "Continue"
                            )}
                        </button>
                    </form>
                    <div id="register-create-wrapper">
                        <p>Already Have An Account?</p>
                        <Link to="/Login">Login</Link>
                    </div>


                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    registerSuccessful: state.auth.registerSuccessful,
});

export default connect(mapStateToProps, {register})(MobileSignup);