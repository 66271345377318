import React from 'react';

class PatchAccount extends React.Component {
  state = {
    email: '',
    phone: '',
  };
  onChange = (e) =>
    this.setState({
      [e.target.name]: e.target.value,
    });
  onSubmit = (e) => {
    e.preventDefault();
    console.log('Submit Pressed');
  };
  render() {
    const { email, phone } = this.state;
    return (
      <form id="editProfileForm">
        <p>Email Address</p>
        <input
          value={email}
          name="email"
          onChange={this.onChange}
          placeholder="Your Email Address"
        />
        <p>Phone Number</p>
        <input
          value={phone}
          name="phone"
          onChange={this.onChange}
          placeholder="Your Phone Number"
        />
        <div id="edit-btn-wrapper" className="editProBtn">
          <button onClick={this.onSubmit}>Submit</button>
        </div>
      </form>
    );
  }
}

export default PatchAccount;
