import React, { useEffect, useState } from "react";
import Navbar from "../wm_navigation/Navbar";
import axios from "axios";
import { loadUser } from "../wm_actions/auth.js";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import moment from "moment";
import "./postedGigs.css";
import LocationOn from "@material-ui/icons/LocationOnOutlined";
import microphone from "../../assets/images/gigs/microphone 1.png";
import profile from "../../assets/images/gigs/profiles 1.png";
import BounceLoader from "react-spinners/BounceLoader";
import BottomNav from "../wm_navigation/BottomNav";
import { config } from "../Endpoints";

function PostedGigs() {
  const [gigs, setGigs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [organizer, setOrganizer] = useState([]);
  const [posted, setPosted] = useState([]);
  const user_id = localStorage.getItem("userId");
  const url = config.url.API_URL;
  useEffect(() => {
    // const api = axios.create({
    //   baseURL: "http://localhost:8000/v1/gigs/",
    // });
    // api.get("/").then((res) => {
    //   console.log(res.data);
    //   setLoading(false);
    //   setGigs(res.data.results);
    //   setOrganizer(res.data.gig_organizer.id);
    //   console.log(res.data.gig_organizer.id, 'orga');
    // });
    fetchGigs();
  }, []);
  const fetchGigs = async () => {
    const res = await axios.get(`${url}gigs/`);
    setGigs(res.data.results);
    setLoading(false);
    // setPosted(res.data.results.gig_organizer);
    console.log(res);
  };
  useEffect(() => {
    mygigs();
    console.log("user_id", user_id);
  }, [gigs]);

  const mygigs = () => {
    const filteredGigs = gigs.filter((item) => {
      return item.gig_organizer.id === user_id;
    });
    console.log(filteredGigs, "filtered gigs");
    setPosted(filteredGigs);
  };
  if (loading) {
    return (
      <div className="loader-wrapper items-center">
        <BounceLoader color={"#34529E"} size={80} />
      </div>
    );
  } else {
    return (
      <div>
        <Navbar />
        <div className="flex justify-around my-10">
          <div>
            <Link to="/gigList">
              <button className="text-gray-600 py-3  px-3 rounded-full font-bold text-xs text-gray-600 bg-slate-300 border-none  active:text-blue-900 hover:bg-white hover:text-blue-900">
                All Gigs
              </button>
            </Link>
          </div>
          <div>
            <Link to="/postedGigs">
              <button className="text-gray-600 py-3  px-3 rounded-full font-bold text-xs text-gray-600 bg-slate-300 border-none  active:text-blue-900 hover:bg-white hover:text-blue-900">
                <div className="flex justify-between">
                  <img className="px-3 object-cover" src={microphone} alt="" />
                  Posted Gigs
                </div>
              </button>
            </Link>
          </div>
          <div>
            <Link to="/gigApplications">
              <button className="text-gray-600 py-3  px-3 rounded-full font-bold text-xs text-gray-600 bg-slate-300 border-none  active:text-blue-900 hover:bg-white hover:text-blue-900">
                <div className="flex justify-between">
                  <img className="px-3 object-cover" src={profile} alt="" /> Gig
                  Applications
                </div>
              </button>
            </Link>
          </div>
        </div>
        {posted.map((gig) => (
          <div className="border bg-white flex h-36 shadow-md mx-2 rounded-sm my-5">
            <div>
              <Link to={`/viewGig/${gig.id}`}>
                <img
                  className="gig-banner object-cover rounded h-full"
                  src={gig.gig_banner}
                  alt=""
                />
              </Link>
            </div>
            <div className="text-left pl-5 mt-3">
              <p className="font-bold text-sm" key={gig.id}>
                {gig.gig_name}
              </p>
              <p className="gig-date my-2 text-sm" key={gig.id}>
                {moment(gig.gig_date).format("Do MMMM YYYY")}
              </p>
              <p className="text-sm text-gray-700 text-sm" key={gig.id}>
                <LocationOn className="mr-1"></LocationOn>
                {gig.gig_location_city}, {gig.gig_location_country}
              </p>
              {/* <p className="text-xs pt-3 mb- 1 font-semibold text-blue-500">
              {gig.gig_artists.length}
              {gig.gig_artists.length === 1 ? "applicant" : "applicants"}
            </p> */}
            </div>
          </div>
        ))}
        <div className="mt-24">
          <BottomNav />
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  me: state.auth.me,
  authKey: state.posts.key,
});

export default connect(mapStateToProps, { loadUser })(PostedGigs);
