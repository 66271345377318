import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import "./style.scss";
import { connect } from "react-redux";
import { loadUser } from "../wm_actions/auth";
import logoDesktop from "../../assets/Logo-2.png";

function DesktopMessage(props) {
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
	console.log(props.Name);
	return (
		<div>
			<Dialog fullScreen={fullScreen} open={true} className="Model">
				<div>
					<img src={logoDesktop} />
				</div>

				<h1 className="Heading">Welcome</h1>
				<p className="Text">
					This application is only supported on mobile devices, Kindly use your phone
				</p>
			</Dialog>
		</div>
	);
}

const mapStateToProps = (state) => ({
	Name: state.auth
});
export default connect(mapStateToProps, { loadUser })(DesktopMessage);
