import React, { Component } from "react";
import completeProfile from "../../../assets/images/Register/completeProfile-top.png";
import "../styles/professionUpdateDesktop.css";
import { professionConstants } from "../../wm_constants/index.js";
import { Redirect } from "react-router-dom";
import { professionUpdateCall } from "../../wm_actions/auth";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { BrowserView } from "react-device-detect";
import logoDesktop from "../../../assets/Logo-2.png";
import TabsVertical from "../TabsVertical";
import CircleLoader from "../../../loader/CircleLoader";

class professionUpdate extends Component {
	state = {
		profession: "",
		checked: false,
		show: false,
		onBoarded: true
	};
	static propTypes = {
		professionUpdate: PropTypes.bool
	};
	async componentDidMount() {
		console.log("This is the Profession Page");
		await this.setState({ profession: this.props.profession });

		console.log(this.props.profession);
		this.timer = setInterval(
			() =>
				this.setState({
					onBoarded: this.props.onBoarded
				}),
			1000
		);
		let time = setInterval(() => console.log(`onBoarded : ${this.props.onBoarded}`), 500);
		// clearInterval(time)
		setTimeout(() => {
			clearInterval(time);
		}, 2000);
	}
	disabledLimit(id) {
		return this.state.profession.length > 1 && this.state.profession.indexOf(id) === -1;
	}
	nextBtn() {
		if (this.state.profession.length === 1) {
			this.setState({ show: true });
		} else {
			return null;
		}
	}
	onSubmit = (e) => {
		e.preventDefault();
		this.props.professionUpdateCall(this.state.profession);
	};
	render() {
		if (this.props.profession.length === 2) {
			window.location.href = "/completeProfile2";
			// return <Redirect to="/completeProfile2" />;
		}
		if (this.state.onBoarded) {
			return <CircleLoader />;
		} else {
			return (
				<BrowserView>
					<div className="ProfessionGenreUpdateDesktop">
						<div className="ProfessionHeader">
							<img alt="WHIP MUSIC AFRICA" src={logoDesktop} />
						</div>
						<div className="ProfessionProfessionRow">
							<div className="ProfessionSectionOneProfession">
								<h1 className="ProfessionHeading">Welcome to Whip Music Africa</h1>
								<TabsVertical
									Data={[
										{ id: 1, name: "Personal Information", isActive: true },
										{ id: 2, name: "Professional Information", isActive: true },
										{ id: 3, name: "Music Interest", isActive: false, NoLine: true }
									]}
								/>
							</div>
							<div className="ProfessionSectionTwoProfession">
								<h4 className="ProfessionHeadingTwoProfession">
									What is your profession in the music industry?
								</h4>
								<p className="ProfessionLabelProfession">Pick at most 2 professions</p>

								<div className="ProfessionFormProffesion">
									<form onSubmit={this.onSubmit}>
										<ul className="Professioncontainer" id="GridDesktop">
											{professionConstants.map((profession) => (
												<li key={profession.id} className="ProfessioncheckboxDesktop">
													<input
														type="checkbox"
														id={profession.id}
														value={profession.id}
														name={profession.id}
														onChange={(e) => {
															let checked = e.target.checked;
															professionConstants.map((prof) => {
																if (profession.id === prof.id) {
																	prof.checked = checked;
																	this.nextBtn();
																}
																return prof.id;
															});
															if (e.target.checked === true) {
																this.setState((prevState) => ({
																	profession: [...prevState.profession, e.target.value]
																}));
															} else if (e.target.checked === false) {
																this.setState({
																	profession: this.state.profession.filter((profession) => {
																		return profession !== e.target.value;
																	})
																});
																return null;
															}
														}}
														checked={profession.checked}
														disabled={this.disabledLimit(profession.id)}
													/>
													<div>
														<label htmlFor={profession.id}>{profession.name}</label>
													</div>
												</li>
											))}
										</ul>
										{this.state.show && (
											<div className="ProfessionButtonDivProfession">
												<input
													className="ProfessionButtonProfession"
													type="submit"
													value="Continue"
												/>
											</div>
										)}
									</form>
								</div>
							</div>
						</div>
					</div>
				</BrowserView>
			);
		}
	}
}
const mapStateToProps = (state) => ({
	profession: state.auth.profession,
	professionUpdate: state.auth.professionUpdate,
	onBoarded: state.auth.onBoarded
});
export default connect(mapStateToProps, { professionUpdateCall })(professionUpdate);
