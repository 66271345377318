// // Import the functions you need from the SDKs you need
import firebase from "firebase";
import "firebase/analytics";

const firebaseConfig = {
	apiKey: "AIzaSyAK1SKpBZLzN4pUYA6wsH73bh1NfESnzvk",
	authDomain: "whip-mvp.firebaseapp.com",
	databaseURL: "https://whip-mvp.firebaseio.com",
	projectId: "whip-mvp",
	storageBucket: "whip-mvp.appspot.com",
	messagingSenderId: "539796108871",
	appId: "1:539796108871:web:902956f5adee65d867a362",
	measurementId: "G-MC9Y3EEGM8",
};
firebase.initializeApp(firebaseConfig);
export const analytics = firebase.analytics();

export const db = firebase.firestore();
export const storage = firebase.storage();

export default firebase;
