import React, { useEffect } from "react";
import { MobileView } from "react-device-detect";
import "./styles/login.css";
import "./styles/loginDesktop.css";
import logo from "../../assets/images/sidebar/logo.png";
import { Link, Redirect, useLocation } from "react-router-dom";
import { connect } from "react-redux";
// import PropTypes from "prop-types";
import { login } from "../wm_actions/auth";
import LoginDesktop from "./LoginDesktop";
import { Button } from "@material-ui/core";
import mixpanel from "../../components/Mixpanel";

function Login(props) {
	const [email, setEmail] = React.useState("");
	const [password, setPassword] = React.useState("");
	const [checked, setChecked] = React.useState(false);
	// const [userId, setUserId] = React.useState("");

	const { state } = useLocation();

	const loginUser = async (e) => {
		e.preventDefault();

		let formattedEmail = email.toLowerCase();

		console.log("email", formattedEmail);
		await props.login(formattedEmail, password);

		setChecked(true);

		// what does this do?
		setTimeout(() => {
			setChecked(false);
		}, 3000);
	};
	useEffect(() => {
		console.log("isAuthenticated", props.isAuthenticated);
		if (props.isAuthenticated) mixpanel.track("user login");
	}, [props.isAuthenticated]);

	if (props.isAuthenticated) {
		return <Redirect to={state?.from || "/Home"} />;
	} else {
		return (
			<>
				<LoginDesktop />
				<MobileView>
					<form onSubmit={loginUser}>
						<div id="login-wrapper">
							<div id="overlayLogin">
								<div id="logo-wrapper">
									<img
										alt="WHIP MUSIC AFRICA"
										src={logo}
										id="landing-logo"
										height="250"
										width="250"
									/>
								</div>
								<div id="login-welcometext">
									<p>
										<span>Hi there,</span>
										<br />
										Nice to see you again
									</p>
								</div>
								<div id="login-input">
									<p>Email address</p>
									<input
										value={email}
										name="email"
										onChange={(e) => setEmail(e.target.value)}
										id="login-email"
										placeholder="input your email address"
									/>
									<p>Password</p>
									<input
										type="password"
										id="login-password"
										onChange={(e) => setPassword(e.target.value)}
										value={password}
										name="password"
										placeholder="input your password"
									/>
								</div>
								<div id="login-btn-wrapper">
									<Button type="submit" variant="contained">
										{checked ? <div className="loaderReset"></div> : "Login"}
									</Button>
									<div className="mt-4">
										<a href="https://server.whipafrica.com/v1/auth/account/password/reset/">
											{" "}
											Forgot Password ?
										</a>
									</div>

									{/* <Link to="/resetPassword">
										<p>Forgot Password?</p>
									</Link> */}
								</div>
								<div id="login-create-wrapper">
									<p>Don't have an account?</p>
									<Link to="/register">
										<button>Create</button>
									</Link>
								</div>
							</div>
						</div>
					</form>
				</MobileView>
			</>
		);
	}
}

// Login.propTypes = {
// 	login: PropTypes.func.isRequired,
// 	isAuthenticated: PropTypes.bool,
// 	users: PropTypes.string,
// 	profession: PropTypes.array,
// 	genre: PropTypes.array,
// };

const mapStateToProps = (state) => ({
	isAuthenticated: state.auth.isAuthenticated,
	// users: state.auth.users,
	profession: state.auth.profession,
	genre: state.auth.genre,
});

export default connect(mapStateToProps, { login })(Login);

// class based component
//--------------------------------------------------------//
// class Login extends React.Component {
// 	state = {
// 		email: "",
// 		password: "",
// 		userId: "",
// 		checked: false,
// 	};
// 	static propTypes = {
// 		login: PropTypes.func.isRequired,
// 		isAuthenticated: PropTypes.bool,
// 		users: PropTypes.string,
// 		profession: PropTypes.array,
// 		genre: PropTypes.array,
// 	};
// 	onSubmit = (e) => {
// 		e.preventDefault();
// 		this.props.login(this.state.email, this.state.password);
// 		this.setState({
// 			checked: true,
// 		});
// 		setTimeout(() => {
// 			this.setState({
// 				checked: false,
// 			});
// 		}, 3000);
// 	};
// 	onChange = (e) =>
// 		this.setState({
// 			[e.target.name]: e.target.value,
// 		});
// 	render() {
// 		if (this.props.isAuthenticated) {
// 			return <Redirect to="/Home" />;
// 		} else {
// 			const { email, password, checked } = this.state;
// 			return (
// 				<>
// 					<LoginDesktop />
// 					<MobileView>
// 						<form onSubmit={this.onSubmit}>
// 							<div id="login-wrapper">
// 								<div id="overlayLogin">
// 									<div id="logo-wrapper">
// 										<img
// 											alt="WHIP MUSIC AFRICA"
// 											src={logo}
// 											id="landing-logo"
// 											height="250"
// 											width="250"
// 										/>
// 									</div>
// 									<div id="login-welcometext">
// 										<p>
// 											<span>Hi there,</span>
// 											<br />
// 											Nice to see you again
// 										</p>
// 									</div>
// 									<div id="login-input">
// 										<p>Email address</p>
// 										<input
// 											value={email}
// 											name="email"
// 											onChange={this.onChange}
// 											id="login-email"
// 											placeholder="input your email address"
// 										/>
// 										<p>Password</p>
// 										<input
// 											type="password"
// 											id="login-email"
// 											onChange={this.onChange}
// 											value={password}
// 											name="password"
// 											placeholder="input your password"
// 										/>
// 									</div>
// 									<div id="login-btn-wrapper">
// 										<Button type="submit" variant="contained">
// 											{checked ? <div className="loaderReset"></div> : "Login"}
// 										</Button>
// 										<Link to="/resetPassword">
// 											<p>Forgot Password?</p>
// 										</Link>
// 									</div>
// 									<div id="login-create-wrapper">
// 										<p>Don't have an account?</p>
// 										<Link to="/register">
// 											<button>Create</button>
// 										</Link>
// 									</div>
// 								</div>
// 							</div>
// 						</form>
// 					</MobileView>
// 				</>
// 			);
// 		}
// 	}
// }
