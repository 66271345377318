import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import profilePicture from "./../Ellipse 26.png";
import { CreateRoomChat, SendMessageChat, ChatHistory, CurrentChats } from "../../wm_actions/chat";
import { getProfileInfo } from "../../wm_actions/profile";
import Pusher from "pusher-js";
import messages from "../../wm_reducers/messages";
import { useHistory } from "react-router";
import { Avatar } from "@material-ui/core";

const pusher = new Pusher("a8f1354f381040cf0b52", {
	cluster: "ap2",
	encrypted: true
});

const MessagePage = (props) => {
	const [chatNav, setChatNav] = useState(true);
	const [messageText, setMessageText] = useState("");
	const history = useHistory();

	useEffect(() => {
		props.CurrentChats();
		props.getProfileInfo(props.match.params.id);
	}, []);
	function clearState() {
		setMessageText("");
	}
	// useEffect(()=>{
	const message = () => {
		let paramId = props.match.params.New;
		if (paramId == "true") {
			props.CreateRoomChat(props.match.params.id, messageText, clearState, true);
		} else {
			props.SendMessageChat({
				message: messageText,
				to: props.match.params.id
			});
			setMessageText("");
		}
	};
	const [listMessages, setListMessages] = useState([]);
	const [inComeMsg, setInComeMsg] = useState({});

	useEffect(() => {
		if (props.createChat) {
			console.log(props.createChat, "props.createChat");
			pusher.unsubscribe(`personal_chat_${props.createChat.thread_id}`);
			let channel = pusher.subscribe(`personal_chat_${props.createChat.thread_id}`);
			channel.bind("message", (data) => {
				console.log(data, "ssssaasasa");
				if (data.sender.id) {
					let senderId = data.sender.id;
					setInComeMsg(data);
				}
			});
		}
	}, [props.createChat]);
	// , [props.createChat])
	const pusherTest = () => {
		if (props.createChat) {
			const id = props.createChat.thread_id;
			let arr = [];
			let paramId = props.match.params.id;
			// const pusher = new Pusher('a8f1354f381040cf0b52', {
			// 	cluster: 'ap2'
			// });
			// console.log(pusher)
			// channel.bind('message', data => {
			// 	console.log(data, 'ssssaasasa')
			// 	if (data.sender.id) {
			// 		let senderId = data.sender.id;
			// 		setInComeMsg(data)
			// 	}
			// });
			console.log([...listMessages, [...arr]]);
		}
	};

	useEffect(() => {
		let paramId = props.match.params.id.split("-").join("");
		if (inComeMsg?.sender?.id) {
			if (inComeMsg.sender.id === paramId) {
				// console.log({ ...inComeMsg, ownMsg: false }, paramId, inComeMsg.sender.id, inComeMsg.sender.id === paramId, "hfdjskhfkjdshfsd")
				// console.log('that')
				setListMessages([...listMessages, { ...inComeMsg, ownMsg: false }]);
			} else {
				// arr.push({ ...data, ownMsg: true })
				// console.log({ ...inComeMsg, ownMsg: true }, paramId, inComeMsg.sender.id, inComeMsg.sender.id === paramId, "hfdjskhfkjdshfsd")
				// console.log('this')
				setListMessages([...listMessages, { ...inComeMsg, ownMsg: true }]);
			}
		}
	}, [inComeMsg]);

	// },[])

	const [messagesList, setMessagesList] = useState(false);
	useEffect(() => {
		let id = props.match.params.id;
		let arr = [];
		let obj = {};
		if (props.currentChat) {
			props.currentChat.map((a, i) =>
				a.users.map((b, i) => {
					if (b.id === id) {
						obj = a;
						a.messages.map((a, i) => {
							if (a.sender.id === id) {
								arr.push({ ...a, ownMsg: false });
							} else {
								arr.push({ ...a, ownMsg: true });
							}
						});
					}
				})
			);
			setMessagesList({ ...obj, messages: [...arr] });
			setListMessages([...arr]);
		}
	}, [props.currentChat, props.match.params.id]);

	useEffect(() => {
		if (!messagesList) {
			props.CreateRoomChat(props.match.params.id, messageText, clearState, false);
		}
	}, [messagesList]);

	useEffect(() => {
		console.log(listMessages);
	}, [listMessages]);

	return (
		<div className="messageList">
			<div className="msgHeader">
				<div onClick={() => history.goBack()} className="backArrow">
					<svg
						width="30"
						height="30"
						viewBox="0 0 30 30"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M19.2773 9.25781L13.5352 15L19.2773 20.7422L17.5195 22.5L10.0195 15L17.5195 7.5L19.2773 9.25781Z"
							fill="#666666"
						/>
					</svg>
				</div>
				<div className="nameMsg">
					{/* <div
						className="profilePhoto"
						style={{ backgroundImage: `url('${profilePicture}')` }}
					></div> */}
					<Avatar src={props.person?.profile?.avatar} className="profilePhoto" />

					<div>
						<div className="name">{props.person?.name}</div>
						<div className="status">
							<svg
								width="5"
								height="5"
								viewBox="0 0 5 5"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<circle cx="2.5" cy="2.5" r="2.5" fill="#34529E" />
							</svg>
							Online
						</div>
					</div>
				</div>
			</div>
			<div className="chatBody">
				<div className="innerChatBot">
					<p className="msgWhen">Today</p>
					{listMessages
						? listMessages.map((a, i) => {
								return a.ownMsg ? (
									<div className="msgBubbleDiv outGoing">
										<svg
											width="10"
											height="10"
											viewBox="0 0 10 10"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												d="M0.175781 5.58594L0.761719 5L3.08594 7.32422L2.5 7.91016L0.175781 5.58594ZM9.25781 2.32422L9.86328 2.91016L4.86328 7.91016L2.51953 5.58594L3.125 5L4.86328 6.73828L9.25781 2.32422ZM7.5 2.91016L4.86328 5.56641L4.27734 4.98047L6.91406 2.32422L7.5 2.91016Z"
												fill="#34529E"
											/>
										</svg>
										<div className="msgBubble outGoing">
											<p>{a.text ? a.text : a.message}</p>
										</div>
									</div>
								) : (
									<div className="msgBubbleDiv">
										<div className="msgBubble inComing">
											<p>{a.text ? a.text : a.message}</p>
										</div>
									</div>
								);
						  })
						: null}
					{/* <div className="msgBubbleDiv">
						<div className="msgBubble inComing">
							<p>This is Johson from Sony,we met at the music event.</p>
						</div>
					</div>
					<div className="msgBubbleDiv outGoing">
						<svg
							width="10"
							height="10"
							viewBox="0 0 10 10"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M0.175781 5.58594L0.761719 5L3.08594 7.32422L2.5 7.91016L0.175781 5.58594ZM9.25781 2.32422L9.86328 2.91016L4.86328 7.91016L2.51953 5.58594L3.125 5L4.86328 6.73828L9.25781 2.32422ZM7.5 2.91016L4.86328 5.56641L4.27734 4.98047L6.91406 2.32422L7.5 2.91016Z"
								fill="#34529E"
							/>
						</svg>
						<div className="msgBubble outGoing">
							<p>Hi Johnson, how are you?</p>
						</div>
					</div>
					<div className="msgBubbleDiv outGoing">
						<svg
							width="10"
							height="10"
							viewBox="0 0 10 10"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M0.175781 5.58594L0.761719 5L3.08594 7.32422L2.5 7.91016L0.175781 5.58594ZM9.25781 2.32422L9.86328 2.91016L4.86328 7.91016L2.51953 5.58594L3.125 5L4.86328 6.73828L9.25781 2.32422ZM7.5 2.91016L4.86328 5.56641L4.27734 4.98047L6.91406 2.32422L7.5 2.91016Z"
								fill="#34529E"
							/>
						</svg>
						<div className="msgBubble outGoing">
							<p>Nice event you put together</p>
						</div>
					</div> */}
				</div>
			</div>
			<div className="msgBox">
				<div className="innerMsgBox">
					<div className="attachment">
						<svg
							width="20"
							height="20"
							viewBox="0 0 20 20"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M13.6719 16.9922C12.7865 17.8776 11.7057 18.3203 10.4297 18.3203C9.15365 18.3203 8.07292 17.8776 7.1875 16.9922C6.27604 16.1068 5.82031 15.026 5.82031 13.75L5.82031 5C5.82031 4.08854 6.15885 3.30729 6.83594 2.65625C7.48698 2.00521 8.26823 1.67969 9.17969 1.67969C10.0911 1.67969 10.8724 2.00521 11.5234 2.65625C12.1745 3.30729 12.5 4.08854 12.5 5V12.0703C12.5 12.6432 12.3047 13.138 11.9141 13.5547C11.4974 13.9714 11.0026 14.1797 10.4297 14.1797C9.85677 14.1797 9.36198 13.9714 8.94531 13.5547C8.52865 13.138 8.32031 12.6432 8.32031 12.0703V5.82031H10V12.1484C10 12.3828 10.1432 12.5 10.4297 12.5C10.6901 12.5 10.8203 12.3828 10.8203 12.1484V5C10.8203 4.55729 10.6641 4.16667 10.3516 3.82812C10.013 3.48958 9.6224 3.32031 9.17969 3.32031C8.73698 3.32031 8.34635 3.48958 8.00781 3.82812C7.66927 4.16667 7.5 4.55729 7.5 5L7.5 13.75C7.5 14.5573 7.78646 15.2474 8.35938 15.8203C8.93229 16.3932 9.6224 16.6797 10.4297 16.6797C11.237 16.6797 11.9271 16.3932 12.5 15.8203C13.0469 15.2474 13.3203 14.5573 13.3203 13.75V5.82031H15V13.75C15 15.026 14.5573 16.1068 13.6719 16.9922Z"
								fill="#999999"
							/>
						</svg>
						<input type="file" />
					</div>
					<input
						className="msgType"
						onKeyDown={(ev) => {
							if (ev.keyCode === 13) {
								if (ev.target.value) {
									message();
								}
							}
						}}
						placeholder="Type a message..."
						value={messageText}
						onChange={(ev) => setMessageText(ev.target.value)}
					/>
					<svg
						onClick={() => message()}
						className="sendSvg"
						width="20"
						height="20"
						viewBox="0 0 20 20"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M1.67969 17.5V11.6797L14.1797 10L1.67969 8.32031V2.5L19.1797 10L1.67969 17.5Z"
							fill="#34529E"
						/>
					</svg>
				</div>
			</div>
		</div>
	);
};
const mapStateToProps = (state) => ({
	currentChat: state.chat.currentChat,
	createChat: state.chat.createChat,
	msgSend: state.chat.msgSend,
	person: state.profile.person
});
export default connect(mapStateToProps, {
	CreateRoomChat,
	SendMessageChat,
	ChatHistory,
	CurrentChats,
	getProfileInfo
})(withRouter(MessagePage));
