import React, { useState } from "react";
import "./App.css";
import RoutingWM from "./pages/wm_routes/routes";
import Alerts from "./pages/wm_services/alertService";
// import { loadUser } from "./pages/wm_actions/auth";
import store from "./pages/wm_store/store";
import { Provider } from "react-redux";
import { Provider as AlertProvider } from "react-alert";
import AlertTemplate from "react-alert-template-basic";
// import "./tailwind.min.css";

// import {fetchToken, onMessageListener} from "./components/Firebase/firebase";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import ErrorBoundary from "./ErrorBoundary";

const MySwal = withReactContent(Swal);

const alertOptions = {
    timeout: 3000,
    position: "top center",
    type: "success",
    containerStyle: { fontSize: 10, zIndex: 3 },
};

const App = () => {
    //	const [isTokenFound, setTokenFound] = useState(false);
    const [notification, setNotification] = useState({ title: "", body: "" });
    // const [show, setShow] = useState(false);

    // fetchToken(setTokenFound);
//	const notify = (payload) => {
//		toast.info(payload.notification.body, {
//			position: "top-right",
//			icon: ({ theme, type }) => (
//				<img src={payload.notification.image} alt="" />
//			),
//		});
//
//		MySwal.fire(
//			payload.notification.title,
//			payload.notification.body,
//			"question",
//		);
//	};

    // onMessageListener()
    //     .then((payload) => {
    //         // setShow(true);
    //         setNotification({
    //             title: payload.notification.title,
    //             body: payload.notification.body,
    //         });
    //         notify(payload);

    //         console.log(payload);
    //     })
    //     .catch((err) => console.log("failed: ", err));

    return (
        <Provider store={store}>
            <AlertProvider template={AlertTemplate} {...alertOptions}>
                <div className="App">
                    <ErrorBoundary>
                        <header className="App-header">
                            <Alerts />
                            <ToastContainer />
                            <RoutingWM />

                        </header>
                    </ErrorBoundary>
                </div>
            </AlertProvider>

        </Provider>
    );
};

export default App;
