// const SERVER_URL = "https://server.whipafrica.com";
// const API_URL = SERVER_URL + "/v1";
// /*
//     auth and profile endpoints
// */
// const LOGIN_URL = API_URL + "/auth/login/";
// const REGISTER_URL = API_URL + "/auth/registration/";
// const LOGOUT_URL = API_URL + "/auth/logout/";
// const PASSWORD_RESET_URL = API_URL + "/auth/password/reset/";
// const PASSWORD_RESET_CONFIRM_URL = API_URL + "/auth/password/reset/confirm/";
// const PASSWORD_CHANGE_URL = API_URL + "/auth/password/change/";
// const AUTHENTICATED_USER_URL = API_URL + "/auth/users/me/";

// /*
//     connection  endpoints
//     -------------------------------
// */
// const CONNECTION_REQUESTS = API_URL + "/connections/users/connectrequests/";
// const PENDING_CONNECTIONS =
//   API_URL + "/connections/users/connectrequests/pending/";

// const CONNECTIONS = API_URL + "/connections/users/connects/";
// const ALL_USERS = API_URL + "/users/";
// const REJECT_CONNECTION_REQUEST =
//   API_URL + "/connections/users/connectrequests/reject/";
// const DISCONNECT_CONNECT_REQUEST =
//   API_URL + "/connections/users/connects/disconnect/";
// const ACCEPT_CONNECTION_REQUEST =
//   API_URL + "/connections/users/connectrequests/accept/";

/*
    posts endpoints
    -------------------------------
*/

/*
    chat endpoints
    -------------------------------
*/

/*
    notifications endpoints
    -------------------------------
*/
// export { LOGIN_URL, REGISTER_URL, LOGOUT_URL, API_URL };

const prod = {
  url: {
    API_URL: "https://server.whipafrica.com/v1/",
    FRONTEND_URL: "https://whipafrica.com/",
  },
};
const dev = {
  url: {
    // API_URL: "http://localhost:8000/v1/",
    API_URL: "https://server.whipafrica.com/v1/",
    FRONTEND_URL: "https://whipafrica.com/",
  },
};
export const config = process.env.NODE_ENV === "development" ? dev : prod;
