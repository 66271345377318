import { Button } from "@material-ui/core";
import React from "react";
import { Link } from "react-router-dom";
class ErrorBoundary extends React.Component {
	constructor(props) {
		super(props);
		this.state = { hasError: false };
//		this.goHome = this.goHome.bind(this);
	}

	static getDerivedStateFromError(error) {
		// Update state so the next render will show the fallback UI.
		return { hasError: true };
	}

	componentDidCatch(error, errorInfo) {
		// You can also log the error to an error reporting service
		console.log(error);
	}

//	goHome() {
//		console.log("going home");
//	}

	render() {
		if (this.state.hasError) {
			// You can render any custom fallback UI
			return (
				<div className="mt-24 mx-8 flex-wrap px-5">
					<h1 className="font-bold text-gray-800 flex-1">
						Something went wrong...

					</h1>
					<h1>
						<span className="text-blue-500">we are working hard to fix it</span>
					</h1>

					{/* TODO Add a redirect back home  */}
					{/* <Button onClick={() => this.goHome()}>Go Home</Button> */}
				</div>
			);
		}

		return this.props.children;
	}
}

export default ErrorBoundary;
