import React from "react";
import { Link } from "react-router-dom";
import opSuccess from "../../assets/opSuccess.png";
import "./premium.css";

export default function PaymentSuccess() {
  return (
    <div id="pSuccessWrapper">
      <img src={opSuccess} />
      <h1>Payment Successful</h1>
      <p>You have successfully paid.</p>
      <Link to="/Home">Done</Link>
    </div>
  );
}
