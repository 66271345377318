import React from "react";
import Navbar from "../wm_navigation/Navbar";
import BottomNav from "../wm_navigation/BottomNav";
import BGProfile from "../../assets/images/Profile/bg-profile.png";
import profile from "./profile.css";
import Avatar from "@material-ui/core/Avatar";
import UserProfile from "../../assets/images/Profile/EllipseP.png";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { loadUser } from "../wm_actions/auth";
import { getPersonalPosts, getPersonalVideos } from "../wm_actions/posts";
import MyPosts from "./userProfileComponents/myPostPromote";
import { Button, Dialog } from "@material-ui/core";
import { Link } from "react-router-dom";
import Back from "../../assets/images/Profile/arrowBack.png";
import { Close } from "@material-ui/icons";
import axios from "axios";
import { config } from "../Endpoints";

const url = config.url.API_URL;

class Profile extends React.Component {
  state = {
    item: 0,
    selected: [],
    value: 0,
    id: "",
    model: false,
  };
  // static contextType = SelectedPostContext;

  static propTypes = {
    me: PropTypes.array,
    selected: PropTypes.array,
    personalPosts: PropTypes.array,
    personalVideos: PropTypes.array,
  };
  componentWillMount() {
    this.props.loadUser();
    this.props.getPersonalPosts();
    this.props.getPersonalVideos();
  }

  handleChange = (index) => {
    this.setState({ value: index });
  };
  handlePromotePost = (event) => {
    event.preventDefault();
    console.log(this.state.selected.length);
    const postId = this.state.selected;
    console.log(postId);
    const totalItem = this.state.item;
    const { authKey } = this.props;
    console.log(totalItem);
    console.log(authKey);
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${authKey}`,
      },
    };
    axios
      .post(
        `${url}payments/promote-post-payment/`,
        {
          name: this?.props?.me?.[0]?.name,
          email: this?.props?.me?.[0]?.email,
          id: this?.props?.me?.[0]?.id,
          totalItem: totalItem,
          postId: postId,
        },
        config
      )
      .then((res) => {
        if (res.status === 200) {
          window.location.href = res.data.data.link;
          console.log(res.data.data.link);
        }
      });
  };

  pull_data = (data) => {
    console.log("pulling");
    this.setState({ selected: data, item: data.length });
  };

  render() {
    return (
      <div
        id="profileContainer"
        style={{ background: "#f6f8fb", height: "100vh", overflow: "hidden" }}
      >
        {this.props.me.map((user) => (
          <div>
            <img
              id="shareBackButton"
              alt=""
              src={Back}
              onClick={() => {
                this.props.history.goBack();
              }}
            />
            <div className="profileTop">
              <img
                onClick={() => console.log(user.profile)}
                alt=""
                src={BGProfile}
                className="bgProfile"
              />
              <Avatar
                src={user.profile.avatar}
                className="userPic"
                sizes="large"
              />
            </div>
          </div>
        ))}
        <div
          id="profileAppBar"
          style={{
            height: "70vh",
            overflow: "hidden",
            paddingBottom: "140px",
          }}
        >
          <p className="PromoteText">Select posts you want to promote</p>
          <MyPosts func={this.pull_data} />
        </div>
        <div>
          <p>{this.state.content}</p>
        </div>
        <Button
          type="submit"
          variant="contained"
          className="PromoteButton"
          onClick={this.handlePromotePost}
          disabled={this.state.selected.length <= 0 ? true : false}
        >
          Promote
        </Button>
        <BottomNav />
        <Dialog
          open={this.state.model}
          onClose={() =>
            this.setState({
              model: false,
            })
          }
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <div className="PromoteDialog">
            <Close
              className="closePromote"
              onClick={() =>
                this.setState({
                  model: false,
                })
              }
            />
            <p>You need to pay to boost your posts</p>

            <Button
              type="submit"
              variant="contained"
              className="PayButton"
              onClick={() => {
                this.props.history.push("/premium");
              }}
            >
              Pay
            </Button>
          </div>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  me: state.auth.me,
  authKey: state.posts.key,

  getPersonalPosts: state.posts.personalPosts,
  getPersonalVideos: state.posts.personalVideos,
});

export default connect(mapStateToProps, {
  loadUser,
  getPersonalPosts,
  getPersonalVideos,
})(Profile);
