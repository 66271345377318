import { FETCH_SINGLE_GIG } from "./types";
import { config } from "../Endpoints";
import axios from 'axios';

const url = config.url.API_URL;
export const getGig = (gig_id) => (dispatch, getState) => {
    const key = getState().auth.key;

    // Headers
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    // If token, add to headers config
    if (key) {
      config.headers["Authorization"] = `Token ${key}`;
    }
    axios
      .get(`${url}gigs/${gig_id}`, config)
      .then((res) => {
        dispatch({
          type: FETCH_SINGLE_GIG,
          payload: res.data,
        });
      })
      .catch((err) => console.log(err?.response));
  };