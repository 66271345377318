import React from "react";
import like from "../../assets/images/Posts/like.png";
// import unlike from '../../assets/images/Posts/unlike.png';
import favourite from "../../assets/images/Posts/liked.png";

class Like extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      like: this.props.liked,
      likeCount: this.props.likes,
    };
  }

  componentWillUnmount() {
    clearInterval(this.t);
  }
  handleUpdate = (id) => {
    this.props.func();

    if (id === this.props.postId) {
      console.log("updating");
      if (this.state.like === false) {
        this.setState({
          likeCount: this.state.likeCount + 1,
          like: !this.state.like,
        });
      } else {
        this.setState({
          likeCount: this.state.likeCount - 1,
          like: !this.state.like,
        });
      }
    }
  };

  render() {
    const { postId } = this.props;
    return (
      <div id="likeWrap">
        <button
          name="postId"
          value={postId?.id}
          onClick={() => this.handleUpdate(postId)}
          id="post-like-btn"
        >
          {this.state.like ? (
            <img src={favourite} alt="liked" className="LSCButtons" />
          ) : (
            <img alt="" className="LSCButtons" src={like} />
          )}
        </button>
        <p>{this.state.likeCount} likes</p>
      </div>
    );
  }
}

export default Like;