import React from "react";
import "./comments.css";
import { connect } from "react-redux";
import { commentOnPost } from "../../wm_actions/posts";
import axios from "axios";
import { withAlert } from "react-alert";
import { config } from "../../Endpoints";

const url = config.url.API_URL;
class CommentSystem extends React.Component {
  state = {
    comment: "",
  };
  onTextComment = (commentId, comment) => {
    this.props.func(commentId, comment);
    this.setState({ comment: "" });
    this.forceUpdate();
  };
  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  render() {
    return (
      <div className={"px-3"}>
        <div id="commentSystemWrap">
          <input
            id="commentInput"
            type="text"
            name="comment"
            required
            placeholder={"Type your comment ..."}
            className="text-xs px-2"
            style={{ fontFamily: "Poppins" }}
            value={this.state.comment}
            onChange={this.handleChange}
          />
          <button
            id="commentSubmitBtn"
            onClick={() =>
              this.onTextComment(this.props.commentId, this.state.comment)
            }
          >
            Post
          </button>
        </div>
      </div>
    );
  }
}
export default withAlert()(CommentSystem);
