import React, { Component } from "react";
import completeProfile from "../../../assets/images/Register/completeProfile-top.png";
import "../styles/professionUpdate.css";
import { genreConstants } from "../../wm_constants/index.js";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { Link, Redirect } from "react-router-dom";
import {
	genreUpdateCall,
	onBoardingCall,
	loadUser,
} from "../../wm_actions/auth";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { MobileView } from "react-device-detect";
import GenreUpdateDesktop from "./genreUpdateDesktop";
import CircleLoader from "../../../loader/CircleLoader";
export class genreUpdate extends Component {
	state = {
		genre: [],
		checked: false,
		show: false,
		is_onboarded: true,
		onBoarded: false,
		reDirect: false,
	};
	static propTypes = {
		genreUpdate: PropTypes.bool,
	};
	async componentDidMount() {
		this.props.loadUser();
		let timer = setInterval(
			() =>
				this.setState({
					reDirect: this.props.onBoardRedirect,
					onBoarded: this.props.onBoarded,
				}),
			1000,
		);
		setTimeout(() => {
			clearInterval(timer);
		}, 50000);
	}
	disabledLimit(id) {
		return this.state.genre.length > 2 && this.state.genre.indexOf(id) === -1;
	}
	nextBtn() {
		if (this.state.genre.length === 2) {
			console.log("genre", this.state.genre);
			this.setState({ show: true });
		}
	}
	onSubmit = (e) => {
		console.log("ss");
		e.preventDefault();
		this.props.genreUpdateCall(this.state.genre);
		this.props.onBoardingCall(this.state.is_onboarded);
		this.props.loadUser();
	};
	render() {
		if (this.state.reDirect && this.state.onBoarded) {
			return <Redirect to="/Home" />;
		} else {
			return (
				<div>
					<GenreUpdateDesktop />
					<MobileView>
						<div className="profession-wrapper">
							<Link id="back-Icon" to="/completeprofile">
								<ArrowBackIcon />
							</Link>
							<img
								src={completeProfile}
								alt="completeProfile"
								id="completeProfile-img"
							/>
							<h2>Two more steps and you’re done</h2>
							<h4>What is your profession in the music industry</h4>
							<p id="limit-label">(Maximum of three genres can be selected)</p>
							<form onSubmit={this.onSubmit}>
								<ul className="container" id="checkboxes-wrapper">
									{genreConstants.map((genre) => (
										<li className="checkbox-wrapper">
											<input
												type="checkbox"
												id={genre.id}
												value={genre.id}
												name={genre.id}
												onChange={(e) => {
													let checked = e.target.checked;
													genreConstants.map((prof) => {
														if (genre.id === prof.id) {
															prof.checked = checked;
															this.nextBtn();
														}
														return prof.id;
													});
													if (e.target.checked === true) {
														this.setState((prevState) => ({
															genre: [
																...prevState.genre,
																e.target.value.toUpperCase(),
															],
														}));
													} else if (e.target.checked === false) {
														this.setState({
															genre: this.state.genre.filter((genre) => {
																return genre !== e.target.value;
															}),
														});
														return null;
													}
												}}
												checked={genre.checked}
												disabled={this.disabledLimit(genre.id)}
											/>
											<div>
												<label for={genre.id}>
													<p>{genre.name}</p>
												</label>
											</div>
										</li>
									))}
								</ul>
								{this.state.show && (
									<input id="cPCompleteBtn" type="submit" value="COMPLETE" />
								)}
							</form>
						</div>
					</MobileView>
				</div>
			);
		}
	}
}
const mapStateToProps = (state) => ({
	genre: state.auth.genre,
	genreUpdate: state.auth.genreUpdate,
	onBoarded: state.auth.onBoarded,
	onBoardRedirect: state.auth.onBoardRedirect,
});

export default connect(mapStateToProps, {
	genreUpdateCall,
	onBoardingCall,
	loadUser,
})(genreUpdate);
