import React from "react";
import ConnectButton from "./connectButton";
import { NavbarConnect } from "../wm_navigation/Navbar";
import BottomNav from "../wm_navigation/BottomNav";
import "./connect.css";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import { CircularProgress, IconButton } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import CancelIcon from "@material-ui/icons/Cancel";
import { Avatar, Card, CardContent, CardHeader } from "@material-ui/core";
import {
  loadRequests,
  sendRequest,
  updateSentRequest,
  loadUsers,
  connectionUsers,
  acceptConnection,
  deleteConnection,
  searchUser,
  fetchMutualConnections,
} from "../wm_actions/connects";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import MyLoader from "../../loader/CircleLoader";
import { professionConstants, countryConstants } from "../wm_constants/index";
import { Link } from "react-router-dom";
import hamburger from "../../assets/images/sidebar/hamburger.png";
import SelectSearch from "react-select-search";
import Select from "react-select";
import fetchMutual from "./utils/fetchMutual";

const mergeById = (a1, a2) =>
  a1.map((itm) => ({
    ...a2.find((item) => item.receiver === itm.id && item),
    ...itm,
  }));
class Connect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      requestId: "",
      allUsers: [],
      array1: [],
      array2: [],
      loading: [],
      allName: [],
      connectSearch: "",
      listDisplay: false,
      pending: [],
    };
  }

  static propTypes = {
    areRequests: PropTypes.bool,
    connectRequests: PropTypes.array,
    allUsers: PropTypes.array.isRequired,
    fetchingRequests: PropTypes.bool,
    sentConnectRequests: PropTypes.array,
    fetchingSentRequest: PropTypes.bool.isRequired,
    sentRequest: PropTypes.bool,
    me: PropTypes.array,
    connectionsList: PropTypes.array,
    sending: PropTypes.bool.isRequired,
    error: PropTypes.object.isRequired,
    message: PropTypes.object.isRequired,
  };
  onSubmit = (e) => {
    e.preventDefault();
    this.props.sendRequest(this.state.requestId);
    // this.t = setTimeout(() => , 1000);
    // this.props.updateSentRequest();
    // this.time = setInterval(() => this.props.connectionUsers(), 2000);
    // this.endCall = setTimeout(() => clearInterval(this.time), 25100);
  };
  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  onAccept = (e) => {
    e.preventDefault();
  };
  listDisplay = () => {
    this.setState({
      listDisplay: !this.state.listDisplay,
    });
  };
  async componentDidMount() {
    // fetch mutual connections
    // await this.props.mutualConnections();
    await this.props.loadRequests();
    await this.props.connectionUsers();
    await this.props.loadUsers();
    await this.props.updateSentRequest();
    this.setState({
      allUsers: this.props.allUsers,
      allName: this.props.allUsers.map((user) => user.name),
    });
    window.addEventListener("scroll", this.loadFunc);
  }
  componentWillUnmount() {
    clearInterval(this.timer);
    window.removeEventListener("scroll", this.loadFunc);
  }
  onTextChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  onSearchUser = (search) => {
    this.props.searchUser(search);
  };
  loadFunc = () => {
    console.log(this.props.userConnectLoading, "userConnectLoading");
    if (
      window.innerHeight + document.documentElement.scrollTop ===
        document.scrollingElement.scrollHeight &&
      !this.props.userConnectLoading
    ) {
      this.props.loadUsers();
      // this.timer = setInterval(
      //   () =>
      //     this.setState({
      //       posts: this.props.posts,
      //     }),
      //   500
      // );
    }
  };

  render() {
    let DataSearch = this.state.allUsers.filter((item) => {
      return Object.keys(item).some((key) =>
        item.name.toLowerCase().includes(this.state.connectSearch.toLowerCase())
      );
    });
    if (this.props.fetchingRequests) {
      return <MyLoader />;
    }
    const options = [
      { value: "chocolate", label: "Chocolate" },
      { value: "strawberry", label: "Strawberry" },
      { value: "vanilla", label: "Vanilla" },
    ];

    console.log(this.props.sentConnectRequests, "sentConnectRequests");
    return (
      <>
        {/* <NavbarConnect /> */}
        <div className="navbar">
          <Link to="/Home" className="connect-menu-bars">
            <img alt="" src={hamburger} />
          </Link>
          <div id="connectsearch">
            <TextField
              placeholder="Search For Music Professionals"
              id="searchConnectInput"
              value={this.state.filter}
              onFocus={this.listDisplay}
              onChange={(ev) => this.onSearchUser(ev.target.value)}
              name="connectSearch"
              InputProps={{
                startAdornment: (
                  <InputAdornment>{/* <SearchIcon /> */}</InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment
                    id={
                      this.state.listDisplay
                        ? "dataSearchConnectButton"
                        : "dataSearchConnectButtonNot"
                    }
                    position="end"
                  >
                    <IconButton id="dataIconButton" onClick={this.listDisplay}>
                      {/* <AccountCircle /> */}
                      <CancelIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </div>
        </div>
        <div className="connect-wrapper">
          <div
            onClick={() => console.log(this.props.connectRequests)}
            id="connect-headingbar"
          >
            <p>Connection invitations</p>
          </div>
          {this.props.connectRequests.map((cReq) => (
            <div id="connect-invite-wrap">
              <Card id="connection-invite-card" className="connect-Card-Root">
                <CardHeader
                  avatar={<Avatar />}
                  title={cReq.sender.name}
                  subheader={cReq.sender.profile.profession[0]}
                  id="connection-invite-head"
                ></CardHeader>
                <div id="connect-invites-btns-wrapper">
                  <CardContent id="connect-invites-btns">
                    <button
                      value={cReq.sender.id}
                      onClick={() => this.props.acceptConnection(cReq.id)}
                      type="submit"
                      id="connect-accept-btn"
                    >
                      <CheckIcon />
                    </button>
                    <button
                      onClick={() => this.props.deleteConnection(cReq.id)}
                      id="connect-decline-btn"
                    >
                      <CloseIcon />
                    </button>
                  </CardContent>
                </div>
              </Card>
            </div>
          ))}
          <div
            onClick={() => console.log(this.props.searchUserData)}
            id="connect-headingbar"
          >
            <p>Music professionals you may want to connect with</p>
            <Link to="/allUsers">
              <strong id="connect-headBrowseBtn">Browse</strong>
            </Link>
          </div>
          <div id="music-connect-wraper">
            {this.props.allUsers ? (
              this.props.allUsers.map((user) => (
                <form onSubmit={this.onSubmit}>
                  <Card key={user?.id} id="connection-card">
                    {this.props?.me[0]?.id === user?.id ? (
                      <Link to={`/profile`}>
                        <Avatar
                          src={user?.profile?.avatar}
                          id="music-connect-avatar"
                        />
                        <p id="music-connect-heading">{user.name}</p>
                        <p id="music-connect-subline">
                          {professionConstants.map((proC) => (
                            <p key={proC.id}>
                              {proC.id === user.profile.profession[0]
                                ? proC.name
                                : null}
                            </p>
                          ))}
                        </p>
                        <p key={user.id} id="music-connect-location">
                          {countryConstants.map((counC) => (
                            <p>
                              {counC.id === user.country ? counC.name : null}
                            </p>
                          ))}
                        </p>
                        {/* <p id="music-connect-commons">
													{fetchMutualConnections(user.id)}10 common connections
												</p> */}
                      </Link>
                    ) : (
                      <Link to={`/profile/${user.id}`}>
                        <Avatar
                          src={user.profile.avatar}
                          id="music-connect-avatar"
                        />
                        <p id="music-connect-heading">{user.name}</p>
                        <p id="music-connect-subline">
                          {professionConstants.map((proC) => (
                            <p key={proC.id}>
                              {proC.id === user.profile.profession[0]
                                ? proC.name
                                : null}
                            </p>
                          ))}
                        </p>
                        <p key={user.id} id="music-connect-location">
                          {countryConstants.map((counC) => (
                            <p>
                              {counC.id === user.country ? counC.name : null}
                            </p>
                          ))}
                        </p>
                        {/* <p id="music-connect-commons">
													{user ? fetchMutual(user.id) : 0}
													common connections
												</p> */}
                      </Link>
                    )}
                    {this.props.sentConnectRequests?.filter(
                      (a, i) => a.receiver.id === user.id
                    ).length ? (
                      <button
                        onClick={() =>
                          console.log(
                            this.props.sentConnectRequests?.filter(
                              (a, i) => a.receiver.id === user.id
                            )
                          )
                        }
                        id="music-connect-pendingBtn"
                      >
                        Pending
                      </button>
                    ) : (
                      <button
                        value={user.id}
                        name="requestId"
                        id="music-connect-button"
                        type="submit"
                        onClick={(e) => this.onChange(e)}
                      >
                        {this.state.loading[user.id] && (
                          <CircularProgress size={14} />
                        )}
                        {!this.state.loading[user.id] && "Connect"}
                      </button>
                    )}
                  </Card>
                </form>
              ))
            ) : (
              <MyLoader />
            )}
          </div>
          <div
            id={
              this.state.listDisplay
                ? "dataSearchConnectWrapper"
                : "dataSearchConnectWrapperNot"
            }
          >
            {this.props.searchUserData &&
              this.props.searchUserData.map((user) => (
                <List dense={true}>
                  {
                    <div id="dataSearchConnect">
                      <Link to={`/profile/${user.id}`}>
                        <ListItem>
                          <ListItemAvatar>
                            <Avatar src={user.profile.avatar} />
                          </ListItemAvatar>
                          <ListItemText
                            primary={user.name}
                            secondary={professionConstants.map((proC) => (
                              <p key={proC.id}>
                                {proC.id === user.profile.profession[0]
                                  ? proC.name
                                  : null}
                              </p>
                            ))}
                          />
                        </ListItem>
                      </Link>
                    </div>
                  }
                </List>
              ))}
          </div>
        </div>
        {this.props.userConnectLoading ? <MyLoader /> : null}
        <BottomNav />
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  userConnectLoading: state.connects.userConnectLoading,
  connectRequests: state.connects.connectRequests,
  areRequests: state.connects.areRequests,
  allUsers: state.connects.allUsers,
  fetchingRequests: state.connects.fetchingRequests,
  requestId: state.connects.requestId,
  fetchingSentRequest: state.connects.fetchingSentRequest,
  sentConnectRequests: state.connects.sentConnectRequests,
  sentRequest: state.connects.sentRequest,
  me: state.auth.me,
  connectionsList: state.connects.connectionsList,
  sending: state.connects.sending,
  error: state.errors,
  message: state.messages,
  searchUserData: state.connects.searchUser,
  mutualConnectionCount: state.connects.mutualConnectionCount,
});
export default connect(mapStateToProps, {
  searchUser,
  connectionUsers,
  loadRequests,
  sendRequest,
  updateSentRequest,
  loadUsers,
  acceptConnection,
  deleteConnection,
  fetchMutualConnections,
})(Connect);
