import React, { Fragment } from "react";
import { getPersonalVideos } from "../../wm_actions/posts";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import _ from "lodash";
import axios from "axios";

import {
  postTextLike,
  postImageLike,
  postVideoLike,
  getAllPosts,
} from "../../wm_actions/posts";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import "../../wm_posts/styles/posts.css";
import like from "../../../assets/images/Posts/like.png";
import comment from "../../../assets/images/Posts/comment.png";
import share from "../../../assets/images/Posts/share.png";
import MyLoader from "../../../loader/CircleLoader";
import {
  professionConstants,
  countryConstants,
} from "../../wm_constants/index";
import VideoPlayer from "react-simple-video-player";
import { Link } from "react-router-dom";
import CommentSystem from "../../wm_posts/component/comments";
import Like from "../../wm_posts/like";
import { config } from "../../Endpoints";

const url = config.url.API_URL;
const frontend_url = config.url.FRONTEND_URL;

class MyVideos extends React.Component {
  state = {
    likedState: new Array(this.props.personalVideos.length).fill(false),
    commentSystem: false,
  };
  static propTypes = {
    personalVideos: PropTypes.array,
  };
  componentWillMount() {
    this.props.getPersonalVideos();
  }
  handleDropdownClick = (i) => {
    this.setState({ clicked: i, open: !this.state.open });
    console.log(this.state.open);
  };
  copyUrl = (id, type) => {
    const el = document.createElement("input");
    el.value = `${frontend_url}posts/${type}/${id}`;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    this.setState({ open: !this.state.open });
  };
  handleFetchPosts = () => {
    this.props.getPersonalVideos();
  };
  onLike = (id, i) => {
    const updateLikedState = this.state.likedState.map((item, index) =>
      index === i ? !item : item
    );

    this.setState({
      likedState: updateLikedState,
    });
    const { authKey } = this.props;

    axios
      .post(
        `${url}posts/posts/like/`,
        { id: id },
        {
          headers: {
            Authorization: `Token ${authKey}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          this.props.personalVideos();
        }
      })
      .catch((err) => console.log(err?.response));
  };

  render() {
    return (
      <div>
        <Fragment>
          <div className="post-wrapper">
            {this.props.personalVideos ? (
              this.props.personalVideos.length ? (
                this.props.personalVideos.reverse().map((post, index) => (
                  <Card className="root" key={post?.id}>
                    <CardHeader
                      avatar={
                        <Avatar
                          aria-label="recipe"
                          className="avatar"
                          src={post?.created_by.profile?.avatar}
                        ></Avatar>
                      }
                      title={post?.created_by?.name}
                      subheader={professionConstants.map((proC) => (
                        <div>
                          <p>
                            {proC?.id ===
                            post?.created_by?.profile?.profession[0]
                              ? proC?.name
                              : null}
                          </p>
                          <p>
                            {proC?.id ===
                            post?.created_by?.profile?.profession[1]
                              ? proC?.name
                              : null}
                          </p>
                        </div>
                      ))}
                    />
                    <CardContent id="cardContent">
                      {post ? (
                        <VideoPlayer
                          id="VideoPostContent"
                          url={post?.url}
                          autosize
                        />
                      ) : null}
                      <Typography id="caption">{post?.caption}</Typography>
                    </CardContent>
                    <div id="reaction-wrapper">
                      <div id="reactions">
                        <Like
                          liked={
                            post.likes?.find(
                              (like) => like.id === localStorage.getItem("id")
                            ) === undefined
                              ? false
                              : true
                          }
                          likes={post?.likes.length}
                          postId={post?.id}
                          func={() => this.onLike(post?.id, index)}
                        />
                      </div>
                      <div id="reactions">
                        <div id="commentWrap">
                          {this.state.commentSystem === post?.id ? (
                            <>
                              <button
                                name="postId"
                                value={post?.id}
                                onClick={() =>
                                  this.setState({ commentSystem: false })
                                }
                                id="post-like-btn"
                                type="submit"
                              >
                                <img
                                  alt=""
                                  className="w-full h-full object-fit object-cover"
                                  src={comment}
                                />
                              </button>
                              <p>{post?.Comments.length} Comments</p>
                            </>
                          ) : (
                            <>
                              <button
                                name="postId"
                                value={post?.id}
                                onClick={() =>
                                  this.setState({ commentSystem: post?.id })
                                }
                                id="post-like-btn"
                                type="submit"
                              >
                                <img
                                  alt=""
                                  className="LSCButtons"
                                  src={comment}
                                />
                              </button>
                              <p>{post?.Comments.length} Comments</p>
                            </>
                          )}
                        </div>
                      </div>
                      <div id="reactions">
                        <div id="shareWrap">
                          <button
                            name="postId"
                            value={post?.id}
                            onClick={() => this.handleDropdownClick(post?.id)}
                            id="post-like-btn"
                          >
                            <img alt="" className="LSCButtons" src={share} />
                          </button>
                          {this.state.open && post.id === this.state.clicked && (
                            <div className="dropdown">
                              <ul>
                                <li
                                  className="share-text"
                                  key="1"
                                  onClick={() => this.copyUrl(post.id, "post")}
                                >
                                  Copy link to Post
                                </li>
                              </ul>
                            </div>
                          )}
                          <p>{post?.Comments.length} Shares</p>
                        </div>
                      </div>
                    </div>
                    {this.state?.commentSystem === post?.id ? (
                      <>
                        <div>
                          <CommentSystem
                            commentId={post?.id}
                            authKey={this.props.authKey}
                            func={this.handleFetchPosts}
                          />
                        </div>
                        {post?.Comments?.map((comment) => (
                          <div className="px-4 py-2 flex justify-start gap-1 items-start">
                            <div className="w-12 h-10 rounded-full">
                              <img
                                src={comment?.comment_by.profile?.avatar}
                                className="rounded-full w-full h-full object-fit object-cover"
                                alt="avatar"
                              />
                            </div>
                            <div
                              style={{ backgroundColor: "#FFFCF6" }}
                              className="text-left w-full p-3"
                            >
                              <p
                                style={{
                                  color: "#9C7B39",
                                  fontFamily: "Poppins",
                                  fontWeight: 700,
                                }}
                                className="text-xs"
                              >
                                {_.capitalize(comment?.comment_by?.name)}
                              </p>
                              <div className="flex items-center">
                                {professionConstants.map((proC) => (
                                  <p
                                    style={{
                                      color: "##666666",
                                      fontFamily: "Poppins",
                                      fontWeight: 400,
                                      fontSize: "8px",
                                    }}
                                    className="mr-0.5"
                                  >
                                    {proC.id ===
                                    comment?.comment_by?.profile.profession[0]
                                      ? proC.name
                                      : null}
                                  </p>
                                ))}
                                <p
                                  style={{
                                    color: "##666666",
                                    fontFamily: "Poppins",
                                    fontWeight: 400,
                                    fontSize: "8px",
                                  }}
                                >
                                  ,{"  "}
                                </p>
                                {countryConstants?.map((country) => (
                                  <p
                                    style={{
                                      color: "##666666",
                                      fontFamily: "Poppins",
                                      fontWeight: 400,
                                      fontSize: "8px",
                                    }}
                                  >
                                    {country.id === comment?.comment_by?.country
                                      ? country.name
                                      : null}
                                  </p>
                                ))}
                              </div>
                              <p
                                style={{
                                  fontFamily: "Poppins",
                                  fontWeight: 400,
                                }}
                                className="text-sm mt-2"
                              >
                                {comment?.comment}
                              </p>
                            </div>
                          </div>
                          // <p>{comment.comment}</p>
                        ))}
                      </>
                    ) : null}
                  </Card>
                ))
              ) : (
                <div className="ProfileLoaderWrapper">
                  <h1>Nothing To Show</h1>
                </div>
              )
            ) : (
              <div className="ProfileLoaderWrapper">
                <div className="loader"></div>
              </div>
            )}
          </div>
        </Fragment>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  personalVideos: state.posts.personalVideos,
  me: state.auth.me,
  authKey: state.auth.key,
});
export default connect(mapStateToProps, { getPersonalVideos })(MyVideos);
