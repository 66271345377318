import axios from "axios";
import { createMessage, returnErrors } from "./messages";
import {
	NOTIFICATION_LOADING,
	NOTIFICATION_LOADED,
	NOTIFICATION_ERROR,
} from "./types";

// CHECK TOKEN & LOAD USER
export const GetNotification = () => (dispatch, getState) => {
	//User Loading
	dispatch({ type: NOTIFICATION_LOADING });
	// Get token from state
	const key = getState().auth.key;

	// Headers
	const config = {
		headers: {
			"Content-Type": "application/json",
		},
	};
	// If token, add to headers config
	if (key) {
		config.headers["Authorization"] = `Token ${key}`;
		axios
			.get("https://server.whipafrica.com/v1/notifications/api/unread_list/")
			.then((res) => {
				dispatch({
					type: NOTIFICATION_LOADED,
					payload: res.data.results,
				});
				dispatch(createMessage(res.data.results.unread_list));
			})
			.catch((err) => {
				dispatch(returnErrors(err?.response?.data, err?.response?.status));
				dispatch({
					type: NOTIFICATION_ERROR,
				});
			});
	}
};
