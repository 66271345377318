import React, { useEffect, useLayoutEffect, useState } from "react";
import {
	Route,
	BrowserRouter as Router,
	Switch,
	Redirect,
} from "react-router-dom";
import Login from "../wm_auth/Login";
import Landing from "../wm_landing/index";
import Error from "./error";
import SignUp from "../wm_auth/SignUp";
import ResetPassword from "../wm_auth/ResetPassword";
import ResetPasswordSuccess from "../wm_auth/resetPasswordSuccess";
import PrivateRoute from "../wm_common/PrivateRoute";
import Home from "../wm_home/home";
import Connect from "../wm_connect/connect";
import Browse from "../wm_browse/browse.js";
import Posts from "../wm_posts/posts";
import professionUpdate from "../wm_auth/newUserFlow/professionUpdate";
import genreUpdate from "../wm_auth/newUserFlow/genreUpdate";
import Profile from "../wm_profile/profile";
import Share from "../wm_share/share";
import Feedback from "../wm_feedback/feedback";
import Help from "../wm_help/help";
import About from "../wm_about/about";
import AllUsers from "../wm_allUsers/allUsers";
import PersonProfile from "../wm_profile/personProfile";
import EditProfile from "../wm_profile/editProfile/editProfile";
import ShareVideo from "../wm_share/shareComponents/shareVideo";
import SharePost from "../wm_share/shareComponents/sharePost";
import SharePhoto from "../wm_share/shareComponents/sharePhoto";
import ShareGig from "../wm_share/shareComponents/shareGig";
import ChatHome from "../wm_chat/Index";
import ShareCaptionScreen from "../wm_share/shareComponents/shareCaption";
import ShareVideoCaption from "../wm_share/shareComponents/shareVideoCaption";
import Logout from "../wm_auth/logout";
import VidBrowse from "../wm_browse/components/vidBrowse";
import Premium from "../wm_premium/premium";
import PaymentSuccess from "../wm_premium/paymentSuccess";
import Message from "../wm_chat/components/Message";
import NewChat from "../wm_chat/components/NewChat";
import Main from "../MainScreen";
import Tnc from "../MainScreen/partials/tnc";
import Promote from "../wm_profile/Promote";
import Post from "../wm_post/post";
import CreateGig from "../wm_gig/CreateGig";
import ListGigs from "../wm_gig/ListGigs";
import SingleGig from "../wm_gig/SingleGig";
import GigApplications from "../wm_gig/GigApplications";
import PostedGigs from "../wm_gig/PostedGigs";
import Contact from "../wm_contact_us/Contact";
import ExpandedGig from "../wm_gig/ExpandedGig";
import ArtistApplication from "../wm_gig/ArtistApplication";
import shareVideoGig from "../wm_share/shareComponents/shareVideoGig";
import SuccessfulApplication from "../wm_gig/SuccessfulApplication";
import LatestArtistApplicationForm from "../wm_gig/LatestArtistApplicationForm";
import { analytics } from "../../components/Firebase/firebase";
import LogRocket from "logrocket";
// Logrocket
LogRocket.init("eafgod/whip-dev");
export default function RoutingWM() {
	const [Key, setKey] = useState("");
	const [user, setUser] = useState(null);
	const [isFetched, setIsFetched] = useState(false);
	// fetch access token on page load

	useEffect(() => {
		if(!isFetched){
			setKey(localStorage.getItem("key"));
			setUser(JSON.parse(localStorage.getItem("wm_user")));
			LogRocket.identify(user?.email, {
				user_id: user?.id,
				email: user?.email,
				name: user?.name,
			});
		}
		return () => {
			setIsFetched(true)
		}

	//	cleanup useEffect

	}, [Key, user]);



	return (
		<Router>
			<Switch>
				{Key && <Redirect to="/Home" from="/" exact />}
				{Key && <Redirect to="/Home" from="/Login" />}
				<Route path="/" component={Main} exact />
				<PrivateRoute path="/Promote" component={Promote} />
				<PrivateRoute path="/Home" component={Home} />
				<Route path="/posts/:type/:id" component={Post} />
				<PrivateRoute path="/completeProfile" component={professionUpdate} />
				<PrivateRoute path="/completeProfile2" component={genreUpdate} />
				<PrivateRoute path="/share" component={Share} />
				<PrivateRoute path="/help" component={Help} />
				<PrivateRoute path="/feedback" component={Feedback} />
				<PrivateRoute path="/connect" component={Connect} />
				<PrivateRoute path="/browse" component={Browse} />
				<PrivateRoute exact path="/profile" component={Profile} />
				<PrivateRoute path="/posts" component={Posts} />
				<PrivateRoute path="/about" component={About} />
				<PrivateRoute path="/allusers" component={AllUsers} />
				<PrivateRoute path="/editProfile" component={EditProfile} />
				<PrivateRoute path="/shareVideo" component={ShareVideo} />
				<PrivateRoute path="/shareVideoGig" component={shareVideoGig} />
				<PrivateRoute path="/sharePost" component={SharePost} />
				<PrivateRoute path="/sharePhoto" component={SharePhoto} />
				<PrivateRoute path="/shareGig" component={ShareGig} />
				<PrivateRoute path="/chat" component={ChatHome} />
				<PrivateRoute path="/shareCaption" component={ShareCaptionScreen} />
				<PrivateRoute path="/shareVideoCaption" component={ShareVideoCaption} />
				<PrivateRoute path="/logout" component={Logout} />
				<PrivateRoute path="/videos" component={VidBrowse} />
				<PrivateRoute path="/premium" component={Premium} />
				<Route path="/contactUs" component={Contact} />
				<Route path="/terms" component={Tnc} />
				<PrivateRoute path="/success" component={PaymentSuccess} />
				<Route path="/profile/:id" component={PersonProfile} />
				<Route path="/landing" component={Landing} />
				<Route path="/Login" component={Login} />
				<Route path="/register" component={SignUp} />
				<Route path="/resetPassword" component={ResetPassword} />
				<Route path="/resetPasswordSuccess" component={ResetPasswordSuccess} />
				<Route path="/messages/:id/:New" component={Message} />
				<Route path="/newChat" component={NewChat} />
				<Route path="/createGig" component={CreateGig} />
				<Route path="/gigList" component={ListGigs} />
				<Route path="/viewGig/:id" component={SingleGig} />
				<Route path="/gigApplications" component={GigApplications} />
				<Route path="/postedGigs" component={PostedGigs} />
				<Route path="/expandedGig/:id" component={ExpandedGig} />
				{/* <Route path="/artistApplication" component={ArtistApplication} /> */}
				<Route
					path="/artistApplication"
					component={LatestArtistApplicationForm}
				/>

				<Route
					path="/successfulApplication"
					component={SuccessfulApplication}
				/>
				<Route component={Error} />
			</Switch>
		</Router>
	);
}
