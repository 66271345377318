import { ADD_VIDEO_STORAGE, ADD_VIDEO_STORAGE_FALSE } from "../wm_actions/types";

const initialState = {
  isUploadedToStorage: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case ADD_VIDEO_STORAGE:
      return {
        ...state,
        isUploadedToStorage: true,
      };
      case ADD_VIDEO_STORAGE_FALSE:
        return {
          ...state,
          isUploadedToStorage: false,
        };
    default:
      return state;
  }
}
