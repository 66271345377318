import { connect } from "react-redux";
import React, { useState } from "react";
import { BrowserView, MobileView } from "react-device-detect";
import logo from "../../assets/Logo-2.png";
import { resetPassword } from "../wm_actions/auth";
import "./styles/resetPassword.css";
import { Button } from "@material-ui/core";

function ResetPassword(props) {
	const [email, setEmail] = useState("");
	const [clicked, setClicked] = useState(false);

	const forgotFunction = () => {
		props.resetPassword(email);
		setClicked(!clicked);
		setTimeout(() => {
			setClicked(false);
		}, 3000);
	};

	return (
		<>
			<BrowserView>This is the Browser ResetPassword</BrowserView>
			<MobileView>
				<div id="reset-password-wrapper">
					<div id="reset-logo-wrapper">
						<img alt="WHIP MUSIC AFRICA" src={logo} id="reset-logo" />
					</div>
					<div id="reset-heading">
						<h1>Reset Password</h1>
						<p>Enter your email address to get the link to reset your password</p>
					</div>
					<div id="reset-input">
						<input
							onChange={(ev) => setEmail(ev.target.value)}
							id="login-email"
							placeholder="Email address"
						/>
					</div>
					<div id="reset-btn-wrapper">
						<Button onClick={() => forgotFunction()} type="submit" variant="contained">
							{clicked ? <div className="loaderReset"></div> : "Send Link"}
						</Button>
					</div>
				</div>
			</MobileView>
		</>
	);
}
const mapStateToProps = (state) => ({});
export default connect(mapStateToProps, { resetPassword })(ResetPassword);
