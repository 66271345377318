/* eslint-disable */

import React, { Component, Fragment } from "react";
import Navbar from "../wm_navigation/Navbar";

import { connect } from "react-redux";
import BottomNav from "../wm_navigation/BottomNav";
import CommentSystem from "../wm_posts/component/comments";
import _ from "lodash";
import comment from "../../assets/images/Posts/comment.png";
import share from "../../assets/images/Posts/share.png";
import PropTypes from "prop-types";
import {
  postTextLike,
  postImageLike,
  postVideoLike,
  getAllPosts,
} from "../wm_actions/posts";
import { professionConstants, countryConstants } from "../wm_constants/index";
import VideoPlayer from "react-simple-video-player";

import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import axios from "axios";
import { Link } from "react-router-dom";
import Linkify from "react-linkify";
import { config } from "../Endpoints";

const url = config.url.API_URL;
const frontend_url = config.url.FRONTEND_URL;
export class Post extends Component {
  state = {
    postType: "",
    postId: "",
    post: {},
    commentSystem: false,
    open: false,
    clicked: "",
  };

  async componentDidMount() {
    let postId = this.props.match.params.id;
    let postType = this.props.match.params.type;
    const { authKey } = this.props;
    // Headers
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    // If token, add to headers config
    // if (authKey) {
    //   config.headers["Authorization"] = `Token ${authKey}`;
    // }
    await axios
      .get(`${url}posts/${postType}/share/${postId}/`, config)
      .then((data) => {
        console.log(data.data);
        this.setState({ post: data.data });
      });
  }

  handleDropdownClick = (i) => {
    this.setState({ clicked: i, open: !this.state.open });
  };

  onSubmit = (e, pid) => {
    e.preventDefault();
  };

  copyUrl = (id, type) => {
    const el = document.createElement("input");
    el.value = `${frontend_url}posts/${type}/${id}`;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    this.setState({ open: !this.state.open });
  };

  commentSystemToggle = () => {
    const { commentSystem } = this.state;
    this.setState({
      commentSystem: !commentSystem,
    });
  };

  render() {
    const { commentSystem } = this.state;
    const { post } = this.state;

    return (
			<div>
				<Navbar />
				<Fragment>
					{post && (
						<Card className="root" key={post?.id}>
							{this.props?.me[0]?.id === post?.created_by_id ? (
								<Link to={`/profile`}>
									<CardHeader
										avatar={
											<Avatar
												aria-label="recipe"
												className="avatar"
												src={this.state.post?.created_by?.profile?.avatar}
											></Avatar>
										}
										title={this.state.post?.created_by?.name}
										subheader={professionConstants.map((proC) => (
											<div>
												<p>
													{proC?.id ===
													this.state.post?.created_by?.profile?.profession[0]
														? proC?.name
														: null}
												</p>
												<p>
													{proC?.id ===
													this.state.post?.created_by?.profile?.profession[1]
														? proC?.name
														: null}
												</p>
											</div>
										))}
									/>
								</Link>
							) : (
								<Link to={`/profile/${this.state.post?.created_by_id}/`}>
									<CardHeader
										avatar={
											<Avatar
												aria-label="recipe"
												className="avatar"
												src={this.state.post?.created_by.profile?.avatar}
											></Avatar>
										}
										title={this.state.post?.created_by?.name}
										subheader={professionConstants.map((proC) => (
											<div>
												<p>
													{proC?.id ===
													this.state.post?.created_by?.profile?.profession[0]
														? proC?.name
														: null}
												</p>
												<p>
													{proC?.id ===
													this.state.post?.created_by?.profile?.profession[1]
														? proC?.name
														: null}
												</p>
											</div>
										))}
									/>
								</Link>
							)}
							<CardContent id="cardContent">
								{post?.post_type === "text" && (
									<Typography id="textPostContent" variant="h1">
										<Linkify
											componentDecorator={(
												decoratedHref,
												decoratedText,
												key,
											) => (
												<a
													target="blank"
													href={decoratedHref}
													rel="nonreferrer"
													key={key}
												>
													{decoratedText}
												</a>
											)}
										>
											<div
												className="text-body"
												style={{
													"white-space": "pre-wrap",

												}}
											>
												<p className="text-2xl font-bold">{post?.caption}</p>
											</div>
										</Linkify>
									</Typography>
								)}
								{post?.post_type === "image" && (
									<>
										<img
											id="ImagePostContent"
											src={post?.url}
											alt={post?.caption}
										/>
										<Typography id="caption">{post?.caption}</Typography>
									</>
								)}
								{post?.post_type === "video" ? (
									<VideoPlayer
										id="VideoPostContent"
										url={this.state.post?.url}
										autosize
									/>
								) : null}
							</CardContent>
							<div id="reaction-wrapper">
								<form onSubmit={this.onSubmit} id="reactionForm">
									<div id="reactions">
										{post?.post_type === "video" ? (
											<div id="commentWrap">
												{commentSystem === post?.id ? (
													<>
														<button
															name="postId"
															value={post?.id}
															onClick={() =>
																this.setState({ commentSystem: false })
															}
															id="post-like-btn"
															type="submit"
														>
															<img
																alt=""
																className="w-full h-full object-fit object-cover"
																src={comment}
															/>
														</button>
														<p>{post?.Comments.length} Comments</p>
													</>
												) : (
													<>
														<button
															name="postId"
															value={post?.id}
															onClick={() =>
																this.setState({ commentSystem: post?.id })
															}
															id="post-like-btn"
															type="submit"
														>
															<img
																alt=""
																className="LSCButtons"
																src={comment}
															/>
														</button>
														<p>{post?.Comments.length} Comments</p>
													</>
												)}
											</div>
										) : post?.post_type === "image" ? (
											<div id="commentWrap" style={{ paddingBottom: "100%" }}>
												{commentSystem === post?.id ? (
													<>
														<button
															name="postId"
															value={post?.id}
															onClick={() =>
																this.setState({ commentSystem: false })
															}
															type="submit"
															id="post-like-btn"
														>
															<img
																alt=""
																className="w-full h-full object-fit object-cover"
																src={comment}
															/>
														</button>
														<p>{post?.Comments.length} Comments</p>
													</>
												) : (
													<>
														<button
															name="postId"
															value={post?.id}
															onClick={() =>
																this.setState({ commentSystem: post?.id })
															}
															type="submit"
															id="post-like-btn"
														>
															<img
																alt=""
																className="LSCButtons"
																src={comment}
															/>
														</button>
														<p>{post?.Comments.length} Comments</p>
													</>
												)}
											</div>
										) : post?.post_type === "text" ? (
											<div id="commentWrap">
												{commentSystem === post?.id ? (
													<>
														<button
															onClick={() =>
																this.setState({ commentSystem: false })
															}
															type="submit"
															id="post-like-btn"
														>
															<img
																alt=""
																className="w-full h-full object-fit object-cover"
																src={comment}
															/>
														</button>
														<p>{post?.Comments.length} Comments</p>
													</>
												) : (
													<>
														<button
															onClick={() =>
																this.setState({ commentSystem: post?.id })
															}
															type="submit"
															id="post-like-btn"
														>
															<img
																alt=""
																className="LSCButtons"
																src={comment}
															/>
														</button>
														<p>{post?.Comments.length} Comments</p>
													</>
												)}
											</div>
										) : null}
									</div>
									<div id="reactions">
										{/* {post?.type === "video" ? (
                      <div id="shareWrap">
                        <button
                          name="postId"
                          value={post?.id}
                          onClick={() => this.handleDropdownClick(post?.id)}
                          id="post-like-btn"
                          type="submit"
                        >
                          <img alt="" className="LSCButtons" src={share} />
                        </button>
                        {this.state.open && post.id === this.state.clicked && (
                          <div className="dropdown">
                            <ul>
                              <li
                                className="share-text"
                                key="1"
                                onClick={() => this.copyUrl(post.id, "post")}
                              >
                                Copy link to Post
                              </li>
                            </ul>
                          </div>
                        )}
                        <p>{post?.Comments.length} Shares</p>
                      </div>
                    ) : post?.post_type("image") ? (
                      <div id="shareWrap">
                        <button
                          name="postId"
                          value={post?.id}
                          onClick={() => this.handleDropdownClick(post?.id)}
                          type="submit"
                          id="post-like-btn"
                        >
                          <img alt="" className="LSCButtons" src={share} />
                        </button>
                        {this.state.open && post.id === this.state.clicked && (
                          <div className="dropdown">
                            <ul>
                              <li
                                className="share-text"
                                key="1"
                                onClick={() => this.copyUrl(post.id, "image")}
                              >
                                Copy link to Post
                              </li>
                            </ul>
                          </div>
                        )}
                        <p>{post?.Comments.length} Shares</p>
                      </div>
                    ) : post?.post_type === "text" ? (
                      <div id="shareWrap">
                        <button
                          onClick={() => this.handleDropdownClick(post?.id)}
                          type="submit"
                          id={post.id}
                        >
                          <img alt="" className="LSCButtons" src={share} />
                        </button>{" "}
                        {this.state.open && post.id === this.state.clicked && (
                          <div className="dropdown">
                            <ul>
                              <li
                                className="share-text"
                                key="1"
                                onClick={() => this.copyUrl(post.id, "text")}
                              >
                                Copy link to Post
                              </li>
                            </ul>
                          </div>
                        )}
                        <p>{post?.Comments.length} Shares</p>
                      </div>
                    ) : null} */}
									</div>
								</form>
							</div>
							{commentSystem === this.state.post?.id ? (
								<>
									<div>
										<CommentSystem
											commentId={this.state.post?.id}
											authKey={this.props.authKey}
											func={this.handleFetchPosts}
										/>
									</div>
									{this.state.post?.Comments?.map((comment) => (
										<div className="px-4 py-2 flex justify-start gap-1 items-start">
											<div className="w-12 h-10 rounded-full">
												<img
													src={comment?.comment_by.profile?.avatar}
													className="rounded-full w-full h-full object-fit object-cover"
													alt="avatar"
												/>
											</div>
											<div
												style={{ backgroundColor: "#FFFCF6" }}
												className="text-left w-full p-3"
											>
												<p
													style={{
														color: "#9C7B39",
														fontFamily: "Poppins",
														fontWeight: 700,
													}}
													className="text-xs"
												>
													{_.capitalize(comment?.comment_by?.name)}
												</p>
												<div className="flex items-center">
													{professionConstants.map((proC) => (
														<p
															style={{
																color: "##666666",
																fontFamily: "Poppins",
																fontWeight: 400,
																fontSize: "8px",
															}}
															className="mr-0.5"
														>
															{proC.id ===
															comment?.comment_by?.profile.profession[0]
																? proC.name
																: null}
														</p>
													))}
													<p
														style={{
															color: "##666666",
															fontFamily: "Poppins",
															fontWeight: 400,
															fontSize: "8px",
														}}
													>
														,{"  "}
													</p>
													{countryConstants?.map((country) => (
														<p
															style={{
																color: "##666666",
																fontFamily: "Poppins",
																fontWeight: 400,
																fontSize: "8px",
															}}
														>
															{country.id === comment?.comment_by?.country
																? country.name
																: null}
														</p>
													))}
												</div>
												<p
													style={{
														fontFamily: "Poppins",
														fontWeight: 400,
													}}
													className="text-sm mt-2"
												>
													{comment?.comment}
												</p>
											</div>
										</div>
										// <p>{comment.comment}</p>
									))}
								</>
							) : null}
						</Card>
					)}
				</Fragment>
				<BottomNav />
			</div>
		);
  }
}

const mapStateToProps = (state) => ({
  posts: state.posts.posts,
  isFetching: state.posts.isFetching,
  me: state.auth.me,
  authKey: state.posts.key,
  productLoading: state.posts.productLoading,
  likeArr: state.posts.likeArr,
});

export default connect(mapStateToProps, {
  postTextLike,
  postImageLike,
  postVideoLike,
  getAllPosts,
})(Post);
