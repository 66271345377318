/* eslint-disable */

import axios from "axios";
import { returnErrors, createMessage } from "./messages";
import mixpanel from "../../components/Mixpanel";
// import { API_URL } from "../Endpoints";
import { config } from "../Endpoints";
import {
  GENRE_UPDATED,
  LOGOUT,
  ONBOARDED,
  GENRE_UPDATE_FAIL,
  PROFESSION_UPDATED,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  AUTH_ERROR,
  USER_LOADED,
  USER_LOADING,
  REGISTER_FAIL,
  REGISTER_SUCCESS,
  PROFESSION_UPDATE_FAIL,
} from "./types";

const url = config.url.API_URL;
// CHECK TOKEN & LOAD USER
export const loadUser = () => (dispatch, getState) => {
  //User Loading
  dispatch({ type: USER_LOADING });
  // Get token from state
  // const key = getState().auth.key;
  const key = localStorage.getItem("key");

  // Headers
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  // If token, add to headers config
  if (key) {
    config.headers["Authorization"] = `Token ${key}`;
    axios
      .get(`${url}users/me/`, config)
      .then((res) => {
        dispatch({
          type: USER_LOADED,
          payload: res.data.results,
        });
        mixpanel.identify(res.data.results[0].id);
        localStorage.setItem("id", res.data.results[0].id);
        localStorage.setItem("wm_user", JSON.stringify(res.data.results[0]));
      })
      .catch((err) => {
        dispatch(returnErrors(err?.response?.data, err?.response?.status));
        dispatch({
          type: AUTH_ERROR,
        });
      });
  }
};
// // GET USER ID
// export const loadId = () => (dispatch, getState) => {
//     dispatch({type: USER_LOADING})
// }

// LOGIN USER
export const login = (email, password) => (dispatch) => {
  // Headers
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  // Request Body
  const body = JSON.stringify({ email, password });
  axios
    .post(`${url}auth/login/`, body, config)
    .then((res) => {
      dispatch({
        type: LOGIN_SUCCESS,
        payload: res.data,
      });
      // dispatch(createMessage(res.data));
      mixpanel.track("Successful Login");
    })
    .catch((err) => {
      dispatch(returnErrors(err?.response?.data, err?.response?.status));
      dispatch({
        type: LOGIN_FAIL,
      });
      mixpanel.track("Failed Login");
      console.log(err?.response);
    });
};
// REGISTER USER
export const register =
  ({ email, name, country, password1, password2 }) =>
  (dispatch) => {
    // Headers
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    // Request Body
    const body = JSON.stringify({ email, name, country, password1, password2 });

    axios
      .post(`${url}auth/registration/`, body, config)
      .then((res) => {
        dispatch({
          type: REGISTER_SUCCESS,
          payload: res.data,
        });
        // dispatch(createMessage(res.data));
        mixpanel.track("Successful SignUp");
        mixpanel.identify(res.data.results[0].id);
        mixpanel.people.set({
          Email: email,
          "Full Name": name,
          Country: country,
          "SignUp Date": new Date(),
        });
        mixpanel.register({
          Email: email,
          "Full Name": name,
        });
        mixpanel.register_once({
          "First Login Date": new Date().toISOString(),
        });
      })
      .catch((err) => {
        dispatch(returnErrors(err?.response?.data, err?.response?.status));
        dispatch({
          type: REGISTER_FAIL,
        });
        mixpanel.track("Failed SignUp", {
          Error: err?.response?.data,
        });
      });
  };
export const logout = () => (dispatch) => {
  console.log("Logout From Action");
  dispatch({ type: LOGOUT });
};
// PATCH UPDATE THE PROFILE PROFESSION
export const professionUpdateCall = (profession) => (dispatch, getState) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const key = getState().auth.key;
  if (key) {
    config.headers["Authorization"] = `Token ${key}`;
  }
  const user = getState().auth.users;
  const body = { profession };
  axios
    .patch(user, body, config)
    .then((res) => {
      dispatch({
        type: PROFESSION_UPDATED,
        payload: res.data,
      });
      mixpanel.track("Profession Updated");
    })
    .catch((err) => {
      dispatch(returnErrors(err?.response?.data, err?.response?.status));
      dispatch({
        type: PROFESSION_UPDATE_FAIL,
      });
      mixpanel.track("Profession Update Failed");
    });
};
// PATCH UPDATE THE PROFILE GENRE
export const genreUpdateCall = (genre) => (dispatch, getState) => {
  // GENRE_UPDATED
  // GENRE_UPDATE_FAIL
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const key = getState().auth.key;
  if (key) {
    config.headers["Authorization"] = `Token ${key}`;
  }
  const user = getState().auth.users;
  const body = { genre };
  axios
    .patch(user, body, config)
    .then((res) => {
      dispatch({
        type: GENRE_UPDATED,
        payload: res.data,
      });
      mixpanel.track("Genre Updated");
    })
    .catch((err) => {
      dispatch(returnErrors(err?.response?.data, err?.response?.status));
      dispatch({
        type: GENRE_UPDATE_FAIL,
      });
      mixpanel.track("Genre Update Failed");
    });
};
// PATCH ONBOARDING BOOL
export const onBoardingCall = (is_onboarded) => (dispatch, getState) => {
  // ONBOARDED
  // GENRE_UPDATE_FAIL
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const key = getState().auth.key;
  if (key) {
    config.headers["Authorization"] = `Token ${key}`;
  }
  const user = getState().auth.users;
  const body = { is_onboarded };
  axios
    .patch(user, body, config)
    .then((res) => {
      dispatch({
        type: ONBOARDED,
        payload: res.data,
      });
      mixpanel.people.set({
        onBoarded: true,
      });
    })
    .catch((err) => {
      dispatch(returnErrors(err?.response?.data, err?.response?.status));
      dispatch({
        type: GENRE_UPDATE_FAIL,
      });
      mixpanel.track("Onboarding Failed");
    });
};

export const resetPassword = (email) => (dispatch) => {
  // Headers
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  // Request Body
  const body = new FormData();
  body.append("email", email);

  axios
    .post(`${url}auth/password/reset/`, body, config)
    .then((res) => {
      dispatch(createMessage(res.data.detail));
      mixpanel.track("Password Reset");
    })
    .catch((err) => {
      dispatch(returnErrors(err?.response?.data, err?.response?.status));
      dispatch({
        type: REGISTER_FAIL,
      });
      mixpanel.track("Password Reset Failed");
    });
};

export const changePassword = (data) => (dispatch, getState) => {
  // Headers
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const key = getState().auth.key;
  if (key) {
    config.headers["Authorization"] = `Token ${key}`;
  }

  axios
    .post(`${url}auth/password/change/`, data, config)
    .then((res) => {
      if (res) {
        dispatch(createMessage(res.data));
        mixpanel.track("Password Changed");
      }
    })
    .catch((err) => {
      dispatch(returnErrors(err?.response?.data, err?.response?.status));
      mixpanel.track("Password Change Failed");
    });
};
