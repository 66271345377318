export const countryConstants = [
    { id: "AF", name: "Afghanistan" },
    { id: "AL", name: "Albania" },
    { id: "DZ", name: "Algeria" },
    { id: "AS", name: "American Samoa" },
    { id: "AD", name: "Andorra" },
    { id: "AO", name: "Angola" },
    { id: "AI", name: "Anguilla" },
    { id: "AQ", name: "Antarctica" },
    { id: "AG", name: "Antigua and Barbuda" },
    { id: "AR", name: "Argentina" },
    { id: "AM", name: "Armenia" },
    { id: "AW", name: "Aruba" },
    { id: "AU", name: "Australia" },
    { id: "AT", name: "Austria" },
    { id: "AZ", name: "Azerbaijan" },
    { id: "BS", name: "Bahamas (the)" },
    { id: "BH", name: "Bahrain" },
    { id: "BD", name: "Bangladesh" },
    { id: "BB", name: "Barbados" },
    { id: "BY", name: "Belarus" },
    { id: "BE", name: "Belgium" },
    { id: "BZ", name: "Belize" },
    { id: "BJ", name: "Benin" },
    { id: "BM", name: "Bermuda" },
    { id: "BT", name: "Bhutan" },
    { id: "BO", name: "Bolivia (Plurinational State of)" },
    { id: "BQ", name: "Bonaire, Sint Eustatius and Saba" },
    { id: "BA", name: "Bosnia and Herzegovina" },
    { id: "BW", name: "Botswana" },
    { id: "BV", name: "Bouvet Island" },
    { id: "BR", name: "Brazil" },
    { id: "IO", name: "British Indian Ocean Territory (the)" },
    { id: "BN", name: "Brunei Darussalam" },
    { id: "BG", name: "Bulgaria" },
    { id: "BF", name: "Burkina Faso" },
    { id: "BI", name: "Burundi" },
    { id: "CV", name: "Cabo Verde" },
    { id: "KH", name: "Cambodia" },
    { id: "CM", name: "Cameroon" },
    { id: "CA", name: "Canada" },
    { id: "KY", name: "Cayman Islands (the)" },
    { id: "CF", name: "Central African Republic (the)" },
    { id: "TD", name: "Chad" },
    { id: "CL", name: "Chile" },
    { id: "CN", name: "China" },
    { id: "CX", name: "Christmas Island" },
    { id: "CC", name: "Cocos (Keeling) Islands (the)" },
    { id: "CO", name: "Colombia" },
    { id: "KM", name: "Comoros (the)" },
    { id: "CD", name: "Congo (the Democratic Republic of the)" },
    { id: "CG", name: "Congo (the)" },
    { id: "CK", name: "Cook Islands (the)" },
    { id: "CR", name: "Costa Rica" },
    { id: "HR", name: "Croatia" },
    { id: "CU", name: "Cuba" },
    { id: "CW", name: "Curaçao" },
    { id: "CY", name: "Cyprus" },
    { id: "CZ", name: "Czechia" },
    { id: "CI", name: "Côte d'Ivoire" },
    { id: "DK", name: "Denmark" },
    { id: "DJ", name: "Djibouti" },
    { id: "DM", name: "Dominica" },
    { id: "DO", name: "Dominican Republic (the)" },
    { id: "EC", name: "Ecuador" },
    { id: "EG", name: "Egypt" },
    { id: "SV", name: "El Salvador" },
    { id: "GQ", name: "Equatorial Guinea" },
    { id: "ER", name: "Eritrea" },
    { id: "EE", name: "Estonia" },
    { id: "SZ", name: "Eswatini" },
    { id: "ET", name: "Ethiopia" },
    { id: "FK", name: "Falkland Islands (the) [Malvinas]" },
    { id: "FO", name: "Faroe Islands (the)" },
    { id: "FJ", name: "Fiji" },
    { id: "FI", name: "Finland" },
    { id: "FR", name: "France" },
    { id: "GF", name: "French Guiana" },
    { id: "PF", name: "French Polynesia" },
    { id: "TF", name: "French Southern Territories (the)" },
    { id: "GA", name: "Gabon" },
    { id: "GM", name: "Gambia (the)" },
    { id: "GE", name: "Georgia" },
    { id: "DE", name: "Germany" },
    { id: "GH", name: "Ghana" },
    { id: "GI", name: "Gibraltar" },
    { id: "GR", name: "Greece" },
    { id: "GL", name: "Greenland" },
    { id: "GD", name: "Grenada" },
    { id: "GP", name: "Guadeloupe" },
    { id: "GU", name: "Guam" },
    { id: "GT", name: "Guatemala" },
    { id: "GG", name: "Guernsey" },
    { id: "GN", name: "Guinea" },
    { id: "GW", name: "Guinea-Bissau" },
    { id: "GY", name: "Guyana" },
    { id: "HT", name: "Haiti" },
    { id: "HM", name: "Heard Island and McDonald Islands" },
    { id: "VA", name: "Holy See (the)" },
    { id: "HN", name: "Honduras" },
    { id: "HK", name: "Hong Kong" },
    { id: "HU", name: "Hungary" },
    { id: "IS", name: "Iceland" },
    { id: "IN", name: "India" },
    { id: "ID", name: "Indonesia" },
    { id: "IR", name: "Iran (Islamic Republic of)" },
    { id: "IQ", name: "Iraq" },
    { id: "IE", name: "Ireland" },
    { id: "IM", name: "Isle of Man" },
    { id: "IL", name: "Israel" },
    { id: "IT", name: "Italy" },
    { id: "JM", name: "Jamaica" },
    { id: "JP", name: "Japan" },
    { id: "JE", name: "Jersey" },
    { id: "JO", name: "Jordan" },
    { id: "KZ", name: "Kazakhstan" },
    { id: "KE", name: "Kenya" },
    { id: "KI", name: "Kiribati" },
    { id: "KP", name: "Korea (the Democratic People's Republic of)" },
    { id: "KR", name: "Korea (the Republic of)" },
    { id: "KW", name: "Kuwait" },
    { id: "KG", name: "Kyrgyzstan" },
    { id: "LA", name: "Lao People's Democratic Republic (the)" },
    { id: "LV", name: "Latvia" },
    { id: "LB", name: "Lebanon" },
    { id: "LS", name: "Lesotho" },
    { id: "LR", name: "Liberia" },
    { id: "LY", name: "Libya" },
    { id: "LI", name: "Liechtenstein" },
    { id: "LT", name: "Lithuania" },
    { id: "LU", name: "Luxembourg" },
    { id: "MO", name: "Macao" },
    { id: "MG", name: "Madagascar" },
    { id: "MW", name: "Malawi" },
    { id: "MY", name: "Malaysia" },
    { id: "MV", name: "Maldives" },
    { id: "ML", name: "Mali" },
    { id: "MT", name: "Malta" },
    { id: "MH", name: "Marshall Islands (the)" },
    { id: "MQ", name: "Martinique" },
    { id: "MR", name: "Mauritania" },
    { id: "MU", name: "Mauritius" },
    { id: "YT", name: "Mayotte" },
    { id: "MX", name: "Mexico" },
    { id: "FM", name: "Micronesia (Federated States of)" },
    { id: "MD", name: "Moldova (the Republic of)" },
    { id: "MC", name: "Monaco" },
    { id: "MN", name: "Mongolia" },
    { id: "ME", name: "Montenegro" },
    { id: "MS", name: "Montserrat" },
    { id: "MA", name: "Morocco" },
    { id: "MZ", name: "Mozambique" },
    { id: "MM", name: "Myanmar" },
    { id: "NA", name: "Namibia" },
    { id: "NR", name: "Nauru" },
    { id: "NP", name: "Nepal" },
    { id: "NL", name: "Netherlands (the)" },
    { id: "NC", name: "New Caledonia" },
    { id: "NZ", name: "New Zealand" },
    { id: "NI", name: "Nicaragua" },
    { id: "NE", name: "Niger (the)" },
    { id: "NG", name: "Nigeria" },
    { id: "NU", name: "Niue" },
    { id: "NF", name: "Norfolk Island" },
    { id: "MP", name: "Northern Mariana Islands (the)" },
    { id: "NO", name: "Norway" },
    { id: "OM", name: "Oman" },
    { id: "PK", name: "Pakistan" },
    { id: "PW", name: "Palau" },
    { id: "PS", name: "Palestine, State of" },
    { id: "PA", name: "Panama" },
    { id: "PG", name: "Papua New Guinea" },
    { id: "PY", name: "Paraguay" },
    { id: "PE", name: "Peru" },
    { id: "PH", name: "Philippines (the)" },
    { id: "PN", name: "Pitcairn" },
    { id: "PL", name: "Poland" },
    { id: "PT", name: "Portugal" },
    { id: "PR", name: "Puerto Rico" },
    { id: "QA", name: "Qatar" },
    { id: "MK", name: "Republic of North Macedonia" },
    { id: "RO", name: "Romania" },
    { id: "RU", name: "Russian Federation (the)" },
    { id: "RW", name: "Rwanda" },
    { id: "RE", name: "Réunion" },
    { id: "BL", name: "Saint Barthélemy" },
    { id: "SH", name: "Saint Helena, Ascension and Tristan da Cunha" },
    { id: "KN", name: "Saint Kitts and Nevis" },
    { id: "LC", name: "Saint Lucia" },
    { id: "MF", name: "Saint Martin (French part)" },
    { id: "PM", name: "Saint Pierre and Miquelon" },
    { id: "VC", name: "Saint Vincent and the Grenadines" },
    { id: "WS", name: "Samoa" },
    { id: "SM", name: "San Marino" },
    { id: "ST", name: "Sao Tome and Principe" },
    { id: "SA", name: "Saudi Arabia" },
    { id: "SN", name: "Senegal" },
    { id: "RS", name: "Serbia" },
    { id: "SC", name: "Seychelles" },
    { id: "SL", name: "Sierra Leone" },
    { id: "SG", name: "Singapore" },
    { id: "SX", name: "Sint Maarten (Dutch part)" },
    { id: "SK", name: "Slovakia" },
    { id: "SI", name: "Slovenia" },
    { id: "SB", name: "Solomon Islands" },
    { id: "SO", name: "Somalia" },
    { id: "ZA", name: "South Africa" },
    { id: "GS", name: "South Georgia and the South Sandwich Islands" },
    { id: "SS", name: "South Sudan" },
    { id: "ES", name: "Spain" },
    { id: "LK", name: "Sri Lanka" },
    { id: "SD", name: "Sudan (the)" },
    { id: "SR", name: "Suriname" },
    { id: "SJ", name: "Svalbard and Jan Mayen" },
    { id: "SE", name: "Sweden" },
    { id: "CH", name: "Switzerland" },
    { id: "SY", name: "Syrian Arab Republic" },
    { id: "TW", name: "Taiwan (Province of China)" },
    { id: "TJ", name: "Tajikistan" },
    { id: "TZ", name: "Tanzania, United Republic of" },
    { id: "TH", name: "Thailand" },
    { id: "TL", name: "Timor-Leste" },
    { id: "TG", name: "Togo" },
    { id: "TK", name: "Tokelau" },
    { id: "TO", name: "Tonga" },
    { id: "TT", name: "Trinidad and Tobago" },
    { id: "TN", name: "Tunisia" },
    { id: "TR", name: "Turkey" },
    { id: "TM", name: "Turkmenistan" },
    { id: "TC", name: "Turks and Caicos Islands (the)" },
    { id: "TV", name: "Tuvalu" },
    { id: "UG", name: "Uganda" },
    { id: "UA", name: "Ukraine" },
    { id: "AE", name: "United Arab Emirates (the)" },
    {
        id: "GB",
        name: "United Kingdom of Great Britain and Northern Ireland (the)",
    },
    { id: "UM", name: "United States Minor Outlying Islands (the)" },
    { id: "US", name: "United States of America (the)" },
    { id: "UY", name: "Uruguay" },
    { id: "UZ", name: "Uzbekistan" },
    { id: "VU", name: "Vanuatu" },
    { id: "VE", name: "Venezuela (Bolivarian Republic of)" },
    { id: "VN", name: "Viet Nam" },
    { id: "VG", name: "Virgin Islands (British)" },
    { id: "VI", name: "Virgin Islands (U.S.)" },
    { id: "WF", name: "Wallis and Futuna" },
    { id: "EH", name: "Western Sahara" },
    { id: "YE", name: "Yemen" },
    { id: "ZM", name: "Zambia" },
    { id: "ZW", name: "Zimbabwe" },
    { id: "AX", name: "Åland Islands" },
];

export const genreConstants = [
    { id: "Afro_Beat/Afro_Pop", name: "Afro-Beat/ Afro-pop" },
    { id: "HIP_HOP/RAP", name: "Hip-Hop/ Rap" },
    { id: "Pop_Music", name: "Pop Music" },
    { id: "Rock_Music", name: "Rock Music" },
    { id: "Gospel", name: "Gospel" },
    { id: "RNB_Soul", name: "R&B/ Soul" },
    { id: "Jazz", name: "Jazz" },
    { id: "Alternative", name: "Alternative" },
    { id: "Blues", name: "Blues" },
    { id: "Electronic", name: "Electronic" },
    { id: "Indie_pop", name: "Indie Pop" },
    { id: "Accapella", name: "Acapella" },
    { id: "Country_music", name: "Country Music" },
    { id: "Reggae", name: "Reggae" },
    { id: "Classical", name: "Classical" },
];

export const professionConstants = [
    { id: "SHOW_ALL", name: "Show All", value: "" },
    { id: "MUSIC_PRODUCER", name: "Music Producer", value: "Music Producer" },
    { id: "RECORD_LABEL", name: "Record Label", value: "Record Label" },
    { id: "SONGWRITER", name: "Songwriter", value: "Songwriter" },
    { id: "BOOKING_AGENT", name: "Booking Agent", value: "Booking Agent" },
    { id: "SINGER", name: "Singer", value: "Singer" },
    { id: "RAPPER", name: "Rapper", value: "Rapper" },
    { id: "SINGER_&_RAPPER", name: "Singer & Rapper", value: "Singer & Rapper" },
    {
        id: "A_&_R_COORDINATOR",
        name: "A&R Coordinator",
        value: "A&R Coordinator",
    },
    {
        id: "PERSONAL_MANAGER",
        name: "Personal manager",
        value: "Personal manager",
    },
    {
        id: "CONCERT_PROMOTER",
        name: "Concert promoter",
        value: "Concert promoter",
    },
    { id: "TOUR_MANAGER", name: "Tour Manager", value: "Tour Manager" },
    {
        id: "TOUR_COORDINATOR",
        name: "Tour Coordinator",
        value: "Tour Coordinator",
    },
    { id: "RECORD_PRODUCER", name: "Record producer", value: "Record producer" },
    { id: "LYRICIST", name: "Lyricist", value: "Lyricist" },
    { id: "COMPOSER", name: "Composer", value: "Composer" },
    {
        id: "BACKGROUND_SINGER",
        name: "Background Singer",
        value: "Background Singer",
    },
    { id: "JAZZ_MUSICIAN", name: "Jazz Musician", value: "Jazz Musician" },
    { id: "MUSIC_PUBLISHER", name: "Music Publisher", value: "Music Publisher" },
    {
        id: "SESSION_MUSICIAN",
        name: "Session Musician",
        value: "Session Musician",
    },
    { id: "OTHER", name: "Other", value: "" },
];
export const AllProfessions = {
    MUSIC_PRODUCER: "Music Producer",
    RECORD_LABEL: "Record Label",
    SONGWRITER: "Songwriter",
    BOOKING_AGENT: "Booking Agent",
    SINGER: "Singer",
    RAPPER: "Rapper",
    "SINGER_&_RAPPER": "Singer & Rapper",
    "A_&_R_COORDINATOR": "A&R Coordinator",
    PERSONAL_MANAGER: "Personal manager",
    CONCERT_PROMOTER: "Concert promoters",
    "TOUR_MANAGER": "Tour Manager",
    "TOUR_COORDINATOR": "Tour coordinator",
    "RECORD_PRODUCER": "Record producer",
    "LYRICIST": "Lyricist",
    "COMPOSER": "Composer",
    "BACKGROUND_SINGER": "Background Singer",
    "JAZZ_MUSICIAN": "Jazz Musician",
    "MUSIC_PUBLISHER": "Music Publisher",
    "SESSION_MUSICIAN": "Session Musician",


}
    ;

export const currencyConstants = {
    KE: "KES",
    GH: "GHS",
    ZA: "ZAR",
    TZ: "TZS",
    NG: "NG",
};

export const nigeriaCitiesConstants = [
    { id: "Lagos", name: "Lagos" },
    { id: "Kano", name: "Kano" },
    { id: "Ibadan", name: "Ibadan" },
    { id: "Benin_City", name: "Benin City" },
    { id: "Port_Harcourt", name: "Port Harcourt" },
    { id: "Jos", name: "Jos" },
    { id: "Ilorin", name: "Ilorin" },
    { id: "Abuja", name: "Abuja" },
    { id: "Kaduna", name: "Kaduna" },
    { id: "Enugu", name: "Enugu" },
    { id: "Zaria", name: "Zaria" },
    { id: "Warri", name: "Warri" },
    { id: "Ikorodu", name: "Ikorodu" },
    { id: "Maiduguri", name: "Maiduguri" },
    { id: "Aba", name: "Aba" },
    { id: "Ife", name: "Ife" },
    { id: "Bauchi", name: "Bauchi" },
    { id: "Akure", name: "Akure" },
    { id: "Abeokuta", name: "Abeokuta" },
    { id: "Oyo", name: "Oyo" },
];

export const kenyaCitiesConstants = [
    { id: "Nairobi", name: "Nairobi" },
    { id: "Mombasa", name: "Mombasa" },
    { id: "Kisumu", name: "Kisumu" },
    { id: "Machakos", name: "Machakos" },
    { id: "Nakuru", name: "Nakuru" },
    { id: "Naivasha", name: "Naivasha" },
    { id: "Eldoret", name: "Eldoret" },
    { id: "Thika", name: "Thika" },
    { id: "Kericho", name: "Kericho" },
    { id: "Lamu", name: "Lamu" },
    { id: "Kilifi", name: "Kilifi" },
];

export const southafricaCitiesConstants = [
    { id: "Cape_Town", name: "Cape Town" },
    { id: "Johannesburg", name: "Johannesburg" },
    { id: "Durban", name: "Durban" },
    { id: "Pretoria", name: "Pretoria" },
    { id: "Soweto", name: "Soweto" },
    { id: "Port_Elizabeth", name: "Port Elizabeth" },
    { id: "Pietermaritzburg", name: "Pietermaritzburg" },
];

export const gigCountryConstants = [
    { id: "KE", name: "Kenya", cities: kenyaCitiesConstants, i: 0 },
    { id: "NG", name: "Nigeria", cities: nigeriaCitiesConstants, i: 1 },
    { id: "ZA", name: "South Africa", cities: southafricaCitiesConstants, i: 2 },
];
