import React, { Component } from "react";
import completeProfile from "../../../assets/images/Register/completeProfile-top.png";
import "../styles/professionUpdate.css";
import { professionConstants } from "../../wm_constants/index.js";
import { Redirect } from "react-router-dom";
import { professionUpdateCall, loadUser } from "../../wm_actions/auth";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { MobileView } from "react-device-detect";
import ProfessionUpdateDesktop from "./professionUpdateDesktop";
import CircleLoader from "../../../loader/CircleLoader";

class professionUpdate extends Component {
	state = {
		profession: "",
		checked: false,
		show: false,
		onBoarded: true
	};
	static propTypes = {
		professionUpdate: PropTypes.bool
	};
	async componentDidMount() {
		await this.setState({ profession: this.props.profession });
		this.props.loadUser();

		console.log(this.props.profession);

		this.timer = setInterval(
			() =>
				this.setState({
					onBoarded: this.props.onBoarded
				}),
			1000
		);
		let time = setInterval(() => console.log(`onBoarded : ${this.props.onBoarded}`), 500);
		// clearInterval(time)
		setTimeout(() => {
			clearInterval(time);
		}, 2000);
	}
	disabledLimit(id) {
		return this.state.profession.length > 1 && this.state.profession.indexOf(id) === -1;
	}
	nextBtn() {
		if (this.state.profession.length === 1) {
			this.setState({ show: true });
		} else {
			return null;
		}
	}
	onSubmit = (e) => {
		e.preventDefault();
		this.props.professionUpdateCall(this.state.profession);
		this.props.loadUser();
	};
	render() {
		// if (this.props.profession.length === 2) {
		// 	window.location.href = "/completeProfile2";
		// 	// return <Redirect to="/completeProfile2" />;
		// }

		if (this.state.onBoarded) {
			return <CircleLoader />;
		} else {
			return (
				<div>
					<ProfessionUpdateDesktop />
					<MobileView>
						<div className="profession-wrapper">
							<img alt="" src={completeProfile} id="completeProfile-img" />
							<h2>Two more steps and you’re done</h2>
							<h4>What is your profession in the music industry</h4>
							<p id="limitlabel">
								(Pick at <span>MOST</span> 2 professions)
							</p>
							<form onSubmit={this.onSubmit}>
								<ul className="container" id="checkboxes-wrapper">
									{professionConstants.map((profession) => (
										<li key={profession.id} className="checkbox-wrapper">
											<input
												type="checkbox"
												id={profession.id}
												value={profession.id}
												name={profession.id}
												onChange={(e) => {
													let checked = e.target.checked;
													professionConstants.map((prof) => {
														if (profession.id === prof.id) {
															prof.checked = checked;
															this.nextBtn();
														}
														return prof.id;
													});
													if (e.target.checked === true) {
														this.setState((prevState) => ({
															profession: [...prevState.profession, e.target.value]
														}));
													} else if (e.target.checked === false) {
														this.setState({
															profession: this.state.profession.filter((profession) => {
																return profession !== e.target.value;
															})
														});
														return null;
													}
												}}
												checked={profession.checked}
												disabled={this.disabledLimit(profession.id)}
											/>
											<div>
												<label htmlFor={profession.id}>{profession.name}</label>
											</div>
										</li>
									))}
								</ul>
								{this.state.show && <input id="cPNextBtn" type="submit" value="NEXT" />}
							</form>
						</div>
					</MobileView>
				</div>
			);
		}
	}
}
const mapStateToProps = (state) => ({
	profession: state.auth.profession,
	professionUpdate: state.auth.professionUpdate,
	onBoarded: state.auth.onBoarded
});
export default connect(mapStateToProps, { professionUpdateCall, loadUser })(professionUpdate);
