/* eslint-disable */

import React from "react";
import { BrowserView, MobileView } from "react-device-detect";
import { Route, Redirect } from "react-router-dom";
import DesktopMessage from "./DesktopMessage";
function PrivateRoute({ component: Component, ...rest }) {
  const currentUser = localStorage.getItem("key");

  return (
    <Route
      {...rest}
      render={(props) => {
        return currentUser ? (
          <div>
            <BrowserView>
              <DesktopMessage />
            </BrowserView>
            <MobileView>
              <Component {...props} />
            </MobileView>
          </div>
        ) : (
          <Redirect
            to={{
              pathname: "/Login",
              state: { from: props.location },
            }}
          />
        );
      }}
    ></Route>
  );
}

export default PrivateRoute;
