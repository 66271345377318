import React from "react";

function Microphone() {
	return (
		<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M10 12.5629V4.93591C9.78378 4.95591 9.57005 4.98966 9.35256 4.99966H3.12545C2.78047 4.99966 2.50049 5.27964 2.50049 5.62462V6.24958H0.625602C0.280624 6.24958 0.000640869 6.52956 0.000640869 6.87454V10.6243C0.000640869 10.9693 0.280624 11.2493 0.625602 11.2493H2.50049V11.8742C2.50049 12.2192 2.78047 12.4992 3.12545 12.4992H3.50417L6.01652 19.1963C6.17026 19.5975 6.51399 19.8887 6.93646 19.9737C7.02021 19.9912 7.10395 20 7.18644 20C7.52017 20 7.83765 19.8675 8.06514 19.63L9.5663 18.1413C9.95002 17.7576 10.0413 17.1589 9.79628 16.6952L7.69891 12.4992H9.35881C9.5738 12.5092 9.78628 12.5429 10 12.5629Z"
				fill="white"
			/>
			<path
				d="M19.8969 0.302481C19.8844 0.281232 19.8732 0.263734 19.8569 0.243735C19.7882 0.15624 19.7019 0.0849947 19.5957 0.0437473C19.5919 0.0424973 19.5894 0.0374977 19.5857 0.0362477C19.5732 0.032498 19.5619 0.0387476 19.5494 0.0349978C19.4932 0.0187488 19.4369 0 19.3744 0C19.3319 0 19.2944 0.016249 19.2532 0.0249984C19.2294 0.0299981 19.2069 0.031248 19.1832 0.0387476C19.0757 0.0737454 18.9832 0.133742 18.9095 0.216236C18.9045 0.221236 18.897 0.222486 18.892 0.227486C16.9433 2.59359 14.226 4.17599 11.2499 4.75595V12.7442C14.226 13.3242 16.9433 14.9053 18.892 17.2714C18.897 17.2777 18.9057 17.2802 18.912 17.2877C18.9557 17.3364 19.0095 17.3752 19.067 17.4077C19.0857 17.4189 19.1007 17.4352 19.1194 17.4439C19.1982 17.4777 19.2832 17.4989 19.3744 17.4989C19.4457 17.4989 19.5157 17.4864 19.5857 17.4627C19.5894 17.4614 19.5919 17.4564 19.5957 17.4552C19.7019 17.4139 19.7882 17.3439 19.8569 17.2552C19.8719 17.2352 19.8832 17.2177 19.8969 17.1964C19.9569 17.1002 19.9994 16.9939 19.9994 16.8739V0.624961C19.9994 0.504968 19.9569 0.398725 19.8969 0.302481Z"
				fill="white"
			/>
		</svg>
	);
}

export default Microphone;
