import React from "react";
import { getPersonalPosts } from "../../wm_actions/posts";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import { professionConstants } from "../../wm_constants/index";
import like from "../../../assets/images/Posts/like.png";
import comment from "../../../assets/images/Posts/comment.png";
import share from "../../../assets/images/Posts/share.png";
import CircleLoader from "../../../loader/CircleLoader";
import {
  postTextLike,
  postImageLike,
  postVideoLike,
} from "../../wm_actions/posts";
import CommentSystem from "../../wm_posts/component/comments";
import { Checkbox } from "@material-ui/core";
import VideoPlayer from "react-simple-video-player";
import { Box } from "@material-ui/system";
import IconButton from "@material-ui/core/IconButton";
import { Chip, Tooltip } from "@material-ui/core";

class MyPosts extends React.Component {
  state = {
    selectedPost: [],
    checkedState: new Array(this.props.personalPosts.length).fill(false),
  };
  static propTypes = {
    personalPosts: PropTypes.array,
    me: PropTypes.array,
  };

  componentDidMount() {
    this.props.getPersonalPosts();
  }

  handleChecked = (e) => {
    const updatedCheckedState = this.state.checkedState.map((item, index) =>
      index === e ? !item : item
    );

    this.setState({ checkedState: updatedCheckedState });
    const newSelected = updatedCheckedState
      .map((item, index) => {
        if (item === true) {
          return this.props.personalPosts[index].id;
        }
      })
      .filter((item) => item !== undefined);
    this.setState({
      selectedPost: newSelected,
    });
    this.props.func(newSelected);
  };
  onText = (e) => {
    this.setState({
      postId: e,
    });
    console.log(e);
    this.t = setTimeout(() => this.props.postTextLike(this.state.postId), 200);
  };
  onVideo = (e) => {
    this.setState({
      postId: e,
    });
    this.t = setTimeout(() => this.props.postVideoLike(this.state.postId), 200);
  };
  onImage = (e) => {
    this.setState({
      postId: e,
    });
    this.t = setTimeout(() => this.props.postImageLike(this.state.postId), 200);
  };
  onSubmit = (e, pid) => {
    e.preventDefault();
    console.log(e);
  };
  commentSystemToggle = () => {
    this.setState({
      commentSystem: !this.state.commentSystem,
    });
  };
  render() {
    return (
      <div
        id="myPostsContainer"
        style={{ height: "100%", overflow: "scroll", marginBottom: "140px" }}
      >
        {this.props.personalPosts ? (
          this.props.personalPosts.length ? (
            this.props.personalPosts.map((post, index) => (
              <Card className="root" key={post.id}>
                {/* <Link to={`/profile/${post.created_by.id}/`}> */}
                <CardHeader
                  avatar={
                    <Avatar
                      aria-label="recipe"
                      className="avatar"
                      src={this.props.me[0].profile.avatar}
                    ></Avatar>
                  }
                  title={this.props.me[0].name}
                  subheader={professionConstants.map((proC) => (
                    <div>
                      <p>
                        {proC.id === this.props.me[0].profile.profession[0]
                          ? proC.name
                          : null}
                      </p>
                      <p>
                        {proC.id === this.props.me[0].profile.profession[1]
                          ? proC.name
                          : null}
                      </p>
                    </div>
                  ))}
                />
                <CardContent id="cardContent">
                  <Typography id="textPostContent">
                    {post?.text_body}
                  </Typography>
                  <img id="ImagePostContent" src={post?.image_url} />
                  {post?.hasOwnProperty("video_url") ? (
                    <VideoPlayer
                      id="VideoPostContent"
                      url={post?.video_url}
                      autosize
                    />
                  ) : null}
                  <Typography id="caption">{post?.caption}</Typography>
                </CardContent>

                <div
                  id="reaction-wrapper"
                  style={{ justifyContent: "space-between" }}
                >
                  <form onChange={this.onSubmit} id="reactionForm">
                    <div id="reactions">
                      {post?.hasOwnProperty("video_url") ? (
                        <div id="likeWrap">
                          <button
                            name="postId"
                            value={post?.id}
                            onClick={() => this.onVideo(post?.id)}
                            id="post-like-btn"
                            type="submit"
                          >
                            <img alt="" className="LSCButtons" src={like} />
                          </button>
                          <p>{post?.likes} likes</p>
                        </div>
                      ) : post?.hasOwnProperty("image_url") ? (
                        <div id="likeWrap">
                          <button
                            name="postId"
                            value={post?.id}
                            onClick={() => this.onImage(post?.id)}
                            type="submit"
                            id="post-like-btn"
                          >
                            <img alt="" className="LSCButtons" src={like} />
                          </button>
                          <p>{post?.likes} likes</p>
                        </div>
                      ) : post?.hasOwnProperty("text_body") ? (
                        <div id="likeWrap">
                          <button
                            onClick={() => this.onText(post?.id)}
                            type="submit"
                            id="post-like-btn"
                          >
                            <img src={like} />
                          </button>
                          <p>{post?.likes} likes</p>
                        </div>
                      ) : null}
                    </div>
                    <div id="reactions">
                      {post?.hasOwnProperty("video_url") ? (
                        <div id="commentWrap">
                          <button
                            name="postId"
                            value={post?.id}
                            id="post-like-btn"
                            type="submit"
                          >
                            <img alt="" className="LSCButtons" src={comment} />
                          </button>
                          <p>{post?.VideoComments.length} Comments</p>
                        </div>
                      ) : post?.hasOwnProperty("image_url") ? (
                        <div id="commentWrap">
                          <button
                            name="postId"
                            value={post?.id}
                            onClick={() =>
                              this.setState({ commentSystem: post?.id })
                            }
                            type="submit"
                            id="post-like-btn"
                          >
                            <img alt="" className="LSCButtons" src={comment} />
                          </button>
                          <p>{post?.ImageComments.length} Comments</p>
                        </div>
                      ) : post?.hasOwnProperty("text_body") ? (
                        <div id="commentWrap">
                          <button
                            onClick={() =>
                              this.setState({ commentSystem: post?.id })
                            }
                            type="submit"
                            id="post-like-btn"
                          >
                            <img alt="" className="LSCButtons" src={comment} />
                          </button>
                          <p>{post?.TextComments.length} Comments</p>
                        </div>
                      ) : null}
                    </div>
                    <div id="reactions">
                      {post?.hasOwnProperty("video_url") ? (
                        <div id="shareWrap">
                          <button
                            name="postId"
                            value={post?.id}
                            onClick={() => this.onVideo(post?.id)}
                            id="post-like-btn"
                            type="submit"
                          >
                            <img alt="" className="LSCButtons" src={share} />
                          </button>
                          <p>{post?.VideoComments.length} Shares</p>
                        </div>
                      ) : post?.hasOwnProperty("image_url") ? (
                        <div id="shareWrap">
                          <button
                            name="postId"
                            value={post?.id}
                            onClick={() => this.onImage(post?.id)}
                            type="submit"
                            id="post-like-btn"
                          >
                            <img alt="" className="LSCButtons" src={share} />
                          </button>
                          <p>{post?.ImageComments.length} Shares</p>
                        </div>
                      ) : post?.hasOwnProperty("text_body") ? (
                        <div id="shareWrap">
                          <button
                            onClick={() => this.onText(post?.id)}
                            type="submit"
                            id="post-like-btn"
                          >
                            <img alt="" className="LSCButtons" src={share} />
                          </button>
                          <p>{post?.TextComments.length} Shares</p>
                        </div>
                      ) : null}
                    </div>
                  </form>
                  {post?.is_promoted ? (
                    <div className="promoted" title="Promoted">
                      <IconButton aria-label="settings" disabled={true}>
                        <Chip
                          variant="outlined"
                          label="Promoted"
                          color="primary"
                          size="small"
                        />
                      </IconButton>
                    </div>
                  ) : (
                    <Checkbox
                      disabled={post?.is_promoted ? true : false}
                      checked={this.state.checkedState[index]}
                      onClick={() => this.handleChecked(index)}
                    />
                  )}
                </div>
                {this.state?.commentSystem == post?.id ? (
                  <CommentSystem />
                ) : null}
              </Card>
            ))
          ) : (
            <div className="ProfileLoaderWrapper">
              <h1>Nothing To Show</h1>
            </div>
          )
        ) : (
          <div className="ProfileLoaderWrapper">
            <div className="loader"></div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  personalPosts: state.posts.personalPosts,
  me: state.auth.me,
  selected: state.selectedPost,
});
export default connect(mapStateToProps, {
  getPersonalPosts,
  postTextLike,
  postImageLike,
  postVideoLike,
})(MyPosts);
