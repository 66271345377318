import React, { useEffect, useState } from "react";
import { Avatar } from "@material-ui/core";
import { Link } from "react-router-dom";
import "./PostCard.css";
import Linkify from "react-linkify";
import VideoPlayer from "react-simple-video-player";
import { AllProfessions, professionConstants } from "../../wm_constants";
import CommentsCard from "./CommentsCard";

//look up table
const professionLookUp = (profession) => AllProfessions[profession] || '';


const PostCard = ({
    avatar,
    name,
    profession1,
    profession2,
    caption,
    profileLink,
    commentsCount,
    likesCount,
    mediaType,
    url,
    postId,
    onLike,
    comments,
    liked,
    handleFetchPosts,
    authKey,
    postIdx,
    handleShare

}) => {
    const [toggleComments, setToggleComments] = useState(false);
    const [isLiked, setIsLiked] = useState(!!liked);
    const [likes, setLikes] = useState(likesCount);

    useEffect(() => {
        console.log('url exists', url);
    }, [url])



    const likePost = async (postId, postIdx) => {


        if (isLiked) {
            setLikes(likes - 1);
            console.log("post is liked", isLiked);
        } else {
            setLikes(likes + 1);
        }
        setIsLiked(!isLiked);
        await onLike(postId, postIdx);


    };


    return (
        <div className="PostCard bg-white rounded border-1 mx-3 my-6  shadow">
            <Link to={profileLink}>
                <div className="PostCard__UserInfo flex flex-row pl-3 pt-4 pb-1">
                    {/*  avatar 10% */}
                    <div>
                        <Avatar src={avatar} className="h-36" />
                    </div>
                    {/*  user profile 90% */}
                    <div className="pl-2 flex flex-col items-start">
                        <p className={"text-[13px] font-bold text-black-600 capitalize"}>
                            {name}
                        </p>
                        {/*loop through professional constants and build text*/}
                        <p className="text-gray-600 capitalize text-[11px]">{professionLookUp(profession1)}{profession2 && "/"}{professionLookUp(profession2)}</p>


                    </div>
                </div>
            </Link>
            <div className="PostCard__Content pb-4">
                {/*  image or video */}
                {mediaType === "image" && (
                    <>
                        <img id="ImagePostContent"
                            src={url || ""}
                            alt={caption}
                            className="w-full h-48" />

                    </>
                )}

                {mediaType === "video" ? (
                    <>
                        <VideoPlayer id="VideoPostContent" url={url} autosize className={"object-fill"} />
                    </>
                ) : null}

                {/*  content */}
                <div className={"px-3 pt-4"}>
                    <Linkify
                        componentDecorator={(decoratedHref, decoratedText, key) => (
                            <a target="blank" href={decoratedHref} rel={"nonreferrer"} key={key}>
                                {decoratedText}
                            </a>
                        )}
                    >
                        <div
                            className="text-body"
                            style={{
                                "whiteSpace": "pre-wrap",
                            }}
                        >
                            <p className="text-left text-[14px] font-medium break-words leading-normal">{caption ? caption : ""}</p>
                        </div>
                    </Linkify>
                </div>
            </div>
            <div className="PostCard__Actions flex px-3 pb-4 justify-between">
                {/*  Like| comment | Share Button */}

                <div className="flex flex-grow flex-1 space-x-2 pl-1 pb-1 justify-items-center">
                    {/*likes*/}
                    <div className="flex flex-col items-center" onClick={() => (likePost(postId, postIdx))}>
                        <div className="">
                            <svg
                                width="18"
                                height="18"
                                viewBox="0 0 18 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M9.00001 15.6459L7.79167 14.5459C3.50001 10.6542 0.666672 8.08752 0.666672 4.93752C0.666672 2.37085 2.68334 0.354187 5.25001 0.354187C6.70001 0.354187 8.09167 1.02919 9.00001 2.09585C9.90834 1.02919 11.3 0.354187 12.75 0.354187C15.3167 0.354187 17.3333 2.37085 17.3333 4.93752C17.3333 8.08752 14.5 10.6542 10.2083 14.5542L9.00001 15.6459Z"
                                    fill={isLiked ? '#CF1111' : '#C4C4C4'}
                                />
                            </svg>
                        </div>

                        <div className="">
                            <p className="actions flex-1 text-black font-normal lowercase">
                                <span>{likes} {'likes'} </span>
                            </p>
                        </div>
                    </div>
                    {/*comments*/}
                    <div className="flex flex-col items-center" onClick={() => setToggleComments(!toggleComments)}>
                        <div className="">
                            <svg
                                width="18"
                                height="18"
                                viewBox="0 0 15 15"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M6.19034 13.9836C6.32974 13.9526 6.41767 13.8145 6.3869 13.6751C6.3556 13.5357 6.21543 13.4491 6.07836 13.4788L4.20284 13.8962C4.20207 13.8957 4.20103 13.8954 4.20026 13.8949L4.15603 13.9066L4.07172 13.9254C4.06474 13.927 4.05957 13.9316 4.05284 13.9337L0 15L1.29 11.1303C0.635431 10.0293 0.258621 8.74448 0.258621 7.37069C0.258621 3.3 3.55862 0 7.62931 0C11.7 0 15 3.3 15 7.37069C15 11.4414 11.7 14.7414 7.62931 14.7414C6.6975 14.7414 5.80655 14.5673 4.98595 14.2516L6.19034 13.9836Z"
                                    fill="#7E96D5"
                                />
                            </svg>
                        </div>

                        <div className="">
                            <p className="actions flex-1 text-black font-normal lowercase">
                                {commentsCount} Comments
                            </p>
                        </div>
                    </div>

                    {/*share*/}
                    <div className="flex flex-col items-center" onClick={() => handleShare(postId, mediaType)}>
                        <div className="">
                            <svg
                                width="18"
                                height="18"
                                viewBox="0 0 18 18"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M17.8093 6.29796L12.6664 1.15513C12.5391 1.02788 12.3885 0.964233 12.2144 0.964233C12.0403 0.964233 11.8895 1.02788 11.7624 1.15513C11.6351 1.28245 11.5714 1.43314 11.5714 1.60722V4.17863H9.32144C4.5467 4.17863 1.61717 5.528 0.532197 8.22667C0.177387 9.12388 0 10.239 0 11.5716C0 12.6831 0.425243 14.1931 1.27566 16.1017C1.29571 16.1486 1.33076 16.2288 1.38107 16.3426C1.43134 16.4564 1.47648 16.5568 1.51666 16.6439C1.55697 16.7309 1.60053 16.8046 1.64736 16.8647C1.72764 16.9786 1.82144 17.0357 1.9286 17.0357C2.02905 17.0357 2.10775 17.0022 2.16471 16.9353C2.22153 16.8684 2.24999 16.7846 2.24999 16.6844C2.24999 16.6239 2.24161 16.5353 2.22483 16.4181C2.20809 16.3008 2.19968 16.2223 2.19968 16.1821C2.16615 15.7269 2.1494 15.3147 2.1494 14.9467C2.1494 14.2704 2.20809 13.6644 2.32517 13.1287C2.44243 12.5929 2.6048 12.1293 2.81244 11.7375C3.02005 11.3455 3.28782 11.0076 3.61603 10.723C3.9441 10.4384 4.29729 10.2058 4.67564 10.0249C5.05406 9.84405 5.49935 9.70174 6.01164 9.59799C6.52388 9.4942 7.03947 9.42218 7.55851 9.38197C8.07755 9.34176 8.66516 9.32177 9.32144 9.32177H11.5714V11.8933C11.5714 12.0673 11.635 12.2181 11.7622 12.3452C11.8895 12.4724 12.0401 12.5361 12.2141 12.5361C12.3882 12.5361 12.5389 12.4724 12.6664 12.3452L17.8092 7.20224C17.9364 7.07499 18 6.92437 18 6.75026C18 6.57618 17.9364 6.42546 17.8093 6.29796Z"
                                    fill="#C5944B"
                                />
                            </svg>
                        </div>

                        <div className="">
                            <p className="actions flex-1 text-black font-normal lowercase">Share</p>
                        </div>

                    </div>

                </div>
                <div className="basis-1/2"></div>
            </div>
            {toggleComments && <CommentsCard comments={comments} postId={postId} authKey={authKey} handleFetchPosts={handleFetchPosts} />}

        </div>
    )
};

export default PostCard;
