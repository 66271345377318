import React from "react";
import "./styles/TabsVertical.css";
function TabsVertical({ Data }) {
	return (
		<div className="tabPanel">
			{Data.map((tab) => (
				<div className="TabFull" id={tab.id}>
					<div className={`Tab ${tab.isActive && "activeTab"}`}>
						<span className={`Circle ${tab.isActive && "activeCircle"}`}></span>
						{tab.name}
					</div>
					{tab.NoLine ? (
						false
					) : (
						<span
							className={`Line  ${tab.id === tab.id + 1 && tab.isActive && "activeLine"}`}
						></span>
					)}
				</div>
			))}
		</div>
	);
}

export default TabsVertical;
