import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import BottomNav from "../../wm_navigation/BottomNav";
import profilePicture from "./../Ellipse 26.png";
import { connectedUsers, loadUsers } from "../../wm_actions/connects";
import { Avatar } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import Loader from "../../../loader/CircleLoader";

function NewChat(props) {
  const history = useHistory();
  // const [connectedUsers, setConnectedUsers] = useState([]);
  
  useEffect(() => {
    props.connectedUsers();
    // setConnectedUsers(props.connectedUsers);
    // props.loadUsers();
    // props.connectedUsers();
    // console.log(props.allUsers, "all users");
    // console.log(props.connectedUserss, "connected");
  }, []);
  const [Filter, setFilter] = useState("");

  return (
    <>
      <div className="NewChat">
        <div className="newChatHeader">
          <svg
            onClick={() => history.goBack()}
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M16.6667 9.16683H6.52504L11.1834 4.5085L10 3.3335L3.33337 10.0002L10 16.6668L11.175 15.4918L6.52504 10.8335H16.6667V9.16683Z"
              fill="white"
            />
          </svg>
          New Chat
        </div>
        <input
          className="chatTo"
          placeholder="To"
          onChange={(a) => setFilter(a.target.value)}
        />
        <div className="suggestList">
          {/* TODO refactor to only show connected users */}
          <h5>Suggested</h5>
          {props?.connectedUserss ? (
            props?.connectedUserss.map(
              (user, i) =>
                user?.name?.toLowerCase().includes(Filter?.toLowerCase()) && (
                  <div
                  key={user.id}
                    className="chatPeople"
                    onClick={() => history.push(`/messages/${user.id}/true`)}
                  >
                    <Avatar
                      src={user?.profile?.avatar}
                      className="profilePhoto"
                    />

                    <div className="nameProf">
                      <div className="personName">{user.name}</div>
                      <div className="personPro">
                        {user.profile.profession
                          ? `${user.profile.profession} , `
                          : null}
                        {""}
                        {user.country}
                      </div>
                    </div>
                  </div>
                )
            )
          ) : (
            <Loader />
          )}
        </div>
      </div>
      <BottomNav />
    </>
  );
}
const mapStateToProps = (state) => ({
  allUsers: state.connects.allUsers,
  connectedUserss: state.connects.connectedUsers,
});
export default connect(mapStateToProps, { loadUsers, connectedUsers })(
  withRouter(NewChat)
);
