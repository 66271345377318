import React from 'react';
import arrowBack from '../../../assets/images/Profile/arrowBack.png';
import './styles/sharePost.css';
import Avatar from '@material-ui/core/Avatar';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { postImage, startPost } from '../../wm_actions/posts';
import firebase, { storage } from '../../../components/Firebase/firebase';
import LinearProgress from '@material-ui/core/LinearProgress';
import { toast } from 'react-toastify';

class ShareCaptionScreen extends React.Component {
  static propTypes = {
    postImage: PropTypes.func.isRequired,
    uploadedIMG: PropTypes.bool.isRequired,
  };
  state = {
    caption: '',
    image_url: '',
    imageName: '',
    percentUploaded: 0,
    isUploading: false,
    fetchedDownloadURL: true,
    imageToGet: '',
    isPosting: false,
  };
  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  handleDownloadUrl = () => {
    // let storageRef = firebase.storage().ref();
    // let spaceRef = storageRef.child(
    //   'post/image/' + sessionStorage.getItem('imgCapture').key
    // );
    // let ImageToGet = `https://firebasestorage.googleapis.com/v0/b/whip-mvp.appspot.com/o/post%2Fimage%2F${this.state.imageToGet}?alt=media&token=426fca5f-a866-498b-a084-ff739e6c1008`;
    // console.log('image url to be posted to server', ImageToGet);
    // let sessionimage = sessionStorage.getItem('imgCapture');
    // console.log('image from session', sessionimage)
    // this.setState({
    //   image_url: ImageToGet,
    //   fetchedDownloadURL: false,
    // });
    console.log('image url', this.state.image_url);
  };

  handlePost = async () => {
    const { caption, image_url } = this.state;
    const token = localStorage.getItem('key');
    this.setState(
      {
        isPosting: true
      }
    )
    await postImage(image_url, caption, token).then((res) => {
      // console.log(res);
      if (res.status === 201) {
        // message.success('Address Added successfully');
        toast('Image Posted successfully');
        // alert('Image Posted successfully');
        this.props.history.push('/Home');
        window.location.reload();
      }
      this.setState({
        isPosting: false,
      })
    });
  };

  //   onSubmit = (e) => {
  //     e.preventDefault();
  //     this.props.postImage(this.state.image_url, this.state.caption);
  //     this.props.history.push('/Home');
  //   };
  async componentDidMount() {
    this.props.startPost();
    // let bucketName = "post";
    let file = await fetch(sessionStorage.getItem("imgCapture")).then((r) =>
      r.blob()
    );
    // console.log(file);
    this.setState({
      ...this.state,
      imageToGet: new Date().getTime(),
    });

    let storageRef = firebase
      .storage()
      .ref(`post/image/${this.state.imageToGet}`);
    let uploadTask = storageRef.put(file);

    console.log('storage ref after accept', storageRef)

    uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, (snapshot) => {
      const percentUploaded = Math.round(
        (snapshot.bytesTransferred / snapshot.totalBytes) * 100
      );
      const isUploading =
        Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100) === 100;
      this.setState({ percentUploaded, isUploading });
      this.setState({
        // imageName: firebase.storage().ref().child(`${storageRef.location.path_}`) 
        imageName: firebase.storage().ref().child(`${storageRef}`),
      });

    },
      (error) => {
        // Handle unsuccessful uploads
        console.log('error occurred ', error)
      },
      () => {
        // Handle successful uploads on complete
        // For instance, get the download URL: https://firebasestorage.googleapis.com/...
        uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
          this.setState(state => ({
            ...state,
            image_url: downloadURL
          }))
          console.log('File available at', downloadURL);
        });
      }


    );
    console.log('image name', this.state.imageName);
  }
  render() {
    const image = sessionStorage.getItem('imgCapture');
    const { caption, image_url, fetchedDownloadURL } = this.state;
    return (
      <div>
        <div id="editTopBar">
          <img alt="" src={arrowBack} onClick={this.props.history.goBack} />
          <button id="postShareBtn" onClick={this.handlePost} disabled={this.state.isPosting}>
            Post
          </button>
        </div>
        <div id="sharePostInputSection">
          <Avatar />
          <textarea
            placeholder="What do you want to share?"
            id="postInputContentField"
            onClick={this.handleDownloadUrl}
            required
            value={caption}
            rows={10}
            onChange={this.onChange}
            name="caption"
          />
          <img
            src={image}
            alt=""
            value={image_url}
            width="120px"
            height="155px"
          />
        </div>
        {!this.state.isUploading && (
          <div id="uploadingProgress">
            <div>
              <p>Upload In Progress...</p>
              <LinearProgress value={this.state.percentUploaded} />
            </div>
          </div>
        )}
        <img id="imgUploaded" />
        <div id="sharePhotoBottomLine" />
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  uploadedIMG: state.posts.uploadedIMG,
});
export default connect(mapStateToProps, { postImage, startPost })(
  ShareCaptionScreen
);
