import React from "react";
import { BrowserView, MobileView } from "react-device-detect";
import "./styles/signupDesktop.css";
import { Link } from "react-router-dom";
import { register } from "../wm_actions/auth";
import { createMessage } from "../wm_actions/messages";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import logoDesktop from "../../assets/Logo-2.png";
import TabsVertical from "./TabsVertical";

class SignUp extends React.Component {
  state = {
    name: "",
    email: "",
    country: "",
    password1: "",
    password2: "",
    open: true,
  };
  static propTypes = {
    register: PropTypes.func.isRequired,
    isAuthenticated: PropTypes.bool,
    registerSuccessful: PropTypes.bool,
  };
  onSubmit = (e) => {
    e.preventDefault();
    const { name, email, country, password1 } = this.state;
    const newUser = {
      name,
      email,
      country,
      password1,
      password2: password1,
    };
    this.props.register(newUser);
  };
  handleOpen = () => {
    this.setState({
      open: true,
    });
  };
  handleClose = () => {
    this.setState({
      open: false,
    });
  };
  onChange = (e) =>
    this.setState({
      [e.target.name]: e.target.value,
    });
  render() {
    const { name, email, country, password1 } = this.state;
    console.log(this.state.open);
    return (
      <>
        <BrowserView>
          <div className="SignupSignupDesktop">
            <div className="SignupHeader">
              <img
                alt="WHIP MUSIC AFRICA"
                src={logoDesktop}
                height="250"
                width="250"
              />
            </div>
            <div className="SignupRow">
              <div className="SignupSectionOne">
                <h1 className="SignupHeading">Welcome to Whip Music Africa</h1>
                <TabsVertical
                  Data={[
                    { id: 1, name: "Personal Information", isActive: true },
                    {
                      id: 2,
                      name: "Professional Information",
                      isActive: false,
                    },
                    {
                      id: 3,
                      name: "Music Interest",
                      isActive: false,
                      NoLine: true,
                    },
                  ]}
                />
              </div>
              <div className="SignupSectionTwo">
                <div className="SignupForm">
                  <form onSubmit={this.onSubmit}>
                    <input
                      value={name}
                      name="name"
                      onChange={this.onChange}
                      placeholder="Full name"
                      className="SignupInputSignUp margin"
                    />
                    <input
                      type="text"
                      onChange={this.onChange}
                      value={country}
                      name="country"
                      placeholder="Country of Residence"
                      className="SignupInputSignUp margin"
                    />
                    <input
                      value={email}
                      name="email"
                      onChange={this.onChange}
                      placeholder="Email address"
                      className="SignupInputSignUp margin"
                    />
                    <input
                      type="password"
                      onChange={this.onChange}
                      value={password1}
                      name="password1"
                      placeholder="Password"
                      className="SignupInputSignUp margin"
                    />

                    <input
                      type="submit"
                      value="Continue"
                      className="SignupContinueButton margin"
                    />
                    <p className="SignupSignup margin">
                      Already Have an account?{" "}
                      <Link to="/Login" className="SignupNewAccount">
                        Login
                      </Link>
                    </p>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </BrowserView>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  registerSuccessful: state.auth.registerSuccessful,
});

export default connect(mapStateToProps, { register, createMessage })(SignUp);
