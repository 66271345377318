import axios from "axios";
import {
  GET_POSTS,
  GET_POST,
  NUMBER_OF_LIKES,
  GET_PERSONAL_POSTS,
  GET_PERSONAL_VIDEOS,
  // IMG_UPLOADED,
  IMG_UPLOADING,
  GET_VIDEO,
  POST_LOADING,
  LIKE_POST,
  UNLIKE_POST,
  SUBMIT_COMMENT,
} from "./types";
import { createMessage } from "./messages";
import mixpanel from "../../components/Mixpanel";
import { config } from "../Endpoints";

const url = config.url.API_URL;

// let limit = 0;

// Get Text Posts
export const getPosts = (postStartValue) => (dispatch, getState) => {
  const key = getState().auth.key;
  console.log(process.env.NODE_ENV);
  // Headers
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  // If token, add to headers config
  if (key) {
    config.headers["Authorization"] = `Token ${key}`;
  }
  const textPosts = axios.get(
    `${url}posts/feed/text/?start=${postStartValue}`,
    config
  );
  const imagePosts = axios.get(
    `${url}posts/feed/image/?start=${postStartValue}`,
    config
  );
  const videoPosts = axios.get(
    `${url}posts/feed/video/?start=${postStartValue}`,
    config
  );

  axios
    .all([textPosts, imagePosts, videoPosts])
    .then(
      axios.spread((...responses) => {
        const textRes = responses[0].data.results;
        const imageRes = responses[1].data.results;
        const videoRes = responses[2].data.results;
        dispatch({
          type: GET_POSTS,
          payload: textRes.concat(imageRes).concat(videoRes),
          vids: videoRes,
        });
        console.log(videoRes.video_url);
      })
    )
    .catch((err) => {
      console.log(err?.response);
    });
};

export const postLoader = (bool) => {
  return (dispatch) => {
    dispatch({
      type: POST_LOADING,
      payload: bool,
    });
  };
};

export const getAllPosts =
  (limit = 10, offset = 0) =>
  (dispatch, getState) => {
    const key = getState().auth.key;
    const prevState = getState().posts.posts;
    const likeId = localStorage.getItem("id");
    dispatch(postLoader(true));

    // Headers
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const likeFilters = (posts) => {
      var likes = [];
      console.log(posts[0].id, likeId);
      for (var i = 0; i < posts.length; i++) {
        if (posts[i]?.liked_text_post.find((like) => like.user === likeId)) {
          likes = [...likes, posts[i].id];
        }
      }
      return likes;
    };

    // If token, add to headers config
    if (key) {
      config.headers["Authorization"] = `Token ${key}`;
    }

    // if (limit <= 100) {
    //   limit = limit + 5;
    // }

    axios
      // .get(`${url}posts/feed/all/?limit=${limit}`, config)
      .get(`${url}posts/feed/live/?limit=${limit}/&offset=${offset}`, config)
      .then((res) => {
        dispatch(postLoader(false));
        if (offset > 0 && limit === 10) {
          dispatch({
            type: GET_POSTS,
            payload: {
              count: res.data.count,
              next: res.data.next,
              previous: res.data.previous,
              results: prevState.results.concat(res.data.results),
            },
            // likes: likeFilters(res.data.results),
          });
        } else {
          dispatch({
            type: GET_POSTS,
            payload: res.data,
            // likes: likeFilters(res.data.results),
          });
        }
      })
      .catch((err) => {
        console.log(err?.response);
      });
  };

export const getInitialPosts =
  (postStartValue, postLimit) => (dispatch, getState) => {
    const key = getState().auth.key;

    // Headers
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    // If token, add to headers config
    if (key) {
      config.headers["Authorization"] = `Token ${key}`;
    }
    const textPosts = axios.get(
      `${url}posts/feed/text/?limit=${postLimit}&start=${postStartValue}`,
      config
    );
    const imagePosts = axios.get(
      `${url}posts/feed/image/?limit=${postLimit}&start=${postStartValue}`,
      config
    );
    const videoPosts = axios.get(
      `${url}posts/feed/video/?limit=${postLimit}&start=${postStartValue}`,
      config
    );

    axios
      .all([textPosts, imagePosts, videoPosts])
      .then(
        axios.spread((...responses) => {
          const textRes = responses[0].data.results;
          const imageRes = responses[1].data.results;
          const videoRes = responses[2].data.results;
          dispatch({
            type: GET_POSTS,
            payload: textRes.concat(imageRes).concat(videoRes),
            vids: videoRes,
          });
          console.log(videoRes.video_url);
        })
      )
      .catch((err) => {
        console.log(err?.response);
      });
  };

// Get Likes
export const getLikes = () => (dispatch, getState) => {
  const key = getState().auth.key;

  // Headers
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  // If token, add to headers config
  if (key) {
    config.headers["Authorization"] = `Token ${key}`;
  }
  axios
    .get(`${url}posts/text/likes/`, config)
    .then((res) => {
      dispatch({
        type: NUMBER_OF_LIKES,
        payload: res.data,
      });
    })
    .catch((err) => console.log(err?.response));
};
export const postTextLike = (postId) => (dispatch, getState) => {
  const key = getState().auth.key;

  // Headers
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  // If token, add to headers config
  if (key) {
    config.headers["Authorization"] = `Token ${key}`;
  }
  axios
    .post(`${url}posts/text/like/${postId}/`, {}, config)
    .then((res) => {
      mixpanel.track("Like Text Post");
      mixpanel.people.increment("Likes", 1);
      mixpanel.people.increment("goal_achieved", 1);
      console.log(config);
      console.log(res);
    })
    .catch((err) => console.log(err?.response));
};
export const postImageLike = (postId) => (dispatch, getState) => {
  const key = getState().auth.key;

  // Headers
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  // If token, add to headers config
  if (key) {
    config.headers["Authorization"] = `Token ${key}`;
  }
  axios
    .post(`${url}posts/image/like/${postId}/`, {}, config)
    .then((res) => {
      console.log(res);
    })
    .catch((err) => console.log(err?.response));
};
export const postVideoLike = (postId) => (dispatch, getState) => {
  const key = getState().auth.key;

  // Headers
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  // If token, add to headers config
  if (key) {
    config.headers["Authorization"] = `Token ${key}`;
  }
  axios
    .post(`${url}posts/video/like/${postId}/`, {}, config)
    .then((res) => {
      console.log(res);
      mixpanel.track("Like Video Post");
    })
    .catch((err) => console.log(err?.response));
};
// GET PERSONAL POSTS
export const getPersonalPosts = () => (dispatch, getState) => {
	const key = getState()?.auth?.key;
	// const userId = getState()?.auth?.me[0]?.id;

	const userId = localStorage.getItem("id").toString();

	console.log("this is my id", getState()?.auth?.me[0]?.id);

	//Headers
	const config = {
		headers: {
			"Content-Type": "application/json",
		},
	};
	// If token, add to headers config
	if (key) {
		config.headers["Authorization"] = `Token ${key}`;
	}
	const allPosts = axios.get(`${url}posts/feed/posts/${userId}/`, config);
	// const imagePosts = axios.get(`${url}posts/feed/image/${userId}/`, config);
	// const videoPosts = axios.get(`${url}posts/feed/video/${userId}/`, config);

	axios
		.all([allPosts])
		.then(
			axios.spread((...responses) => {
				const allPostsRes = responses[0].data.results;
				// const imageRes = responses[1].data.results;
				// const videoRes = responses[2].data.results;
				dispatch({
					type: GET_PERSONAL_POSTS,
					payload: allPostsRes,
				});
			}),
		)
		.catch((err) => {
			console.log(err?.response);
		});
};;;
export const getPersonalVideos = () => (dispatch, getState) => {
  const key = getState()?.auth?.key;
  const userId = getState()?.auth?.me[0]?.id;

  // Headers
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  // If token, add to headers config
  if (key) {
    config.headers["Authorization"] = `Token ${key}`;
  }
  axios
    .get(`${url}posts/feed/video/${userId}/`, config)
    .then((res) => {
      dispatch({
        type: GET_PERSONAL_VIDEOS,
        payload: res.data.results,
      });
      console.log(res.data.results);
    })
    .catch((err) => console.log(err?.response));
};

export const postText = async (params, token) => {
  try {
    const data = {
      post_type: "text",
      caption: params,
    };
    const response = await axios
      .post(`${url}posts/text/new-text-post/`, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      })
      .catch((err) => err.response);
    console.log(response);
    return response;
  } catch (err) {
    return err;
  }
};

// export const postText = (text_body) => (dispatch, getState) => {
//   const key = getState().auth.key;

//   // Headers
//   const config = {
//     headers: {
//       'Content-Type': 'application/json',
//     },
//   };
//   // If token, add to headers config
//   if (key) {
//     config.headers['Authorization'] = `Token ${key}`;
//   }
//   const body = JSON.stringify({ text_body });
//   // const textPosts = axios.get(
//   //   '${url}posts/feed/text/',
//   //   config
//   // );
//   // const imagePosts = axios.get(
//   //   '${url}posts/feed/image/',
//   //   config
//   // );
//   // const videoPosts = axios.get(
//   //   '${url}posts/feed/video/',
//   //   config
//   // );
//   const postText = axios.post(
//     `${url}posts/text/new-text-post/`,
//     body,
//     config
//   );

//   axios
//     .all([textPosts, imagePosts, videoPosts, postText])
//     .then(
//       axios.spread((...responses) => {
//         const textRes = responses[0].data.results;
//         const imageRes = responses[1].data.results;
//         const videoRes = responses[2].data.results;

//         dispatch({
//           type: GET_POSTS,
//           payload: textRes.concat(imageRes).concat(videoRes),
//         });
//         dispatch(createMessage('Post Created Successfully'));
//       })
//     )
//     .catch((err) => console.log(err?.response));
// };
export const startPost = () => (dispatch, getState) => {
  dispatch({ type: IMG_UPLOADING });
};

export const postImage = async (image_url, caption, token) => {
  try {
    const data = {
      url: image_url,
      caption: caption,
      post_type: "image",
    };
    const body = JSON.stringify({ image_url, caption });
    const request = {
      method: "post",
      url: `${url}posts/image/new-image-post/`,
      data,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    };
    const response = await axios(request);
    mixpanel.track("Added an Image Post");
    return response;
  } catch (err) {
    return err;
  }
};

// export const postImage = (image_url, caption) => (dispatch, getState) => {
//   const key = getState().auth.key;

//   // Headers
//   const config = {
//     headers: {
//       'Content-Type': 'application/json',
//     },
//   };
//   // If token, add to headers config
//   if (key) {
//     config.headers['Authorization'] = `Token ${key}`;
//   }
//   const body = JSON.stringify({ image_url, caption });
//   const textPosts = axios.get(
//     '${url}posts/feed/text/',
//     config
//   );
//   const imagePosts = axios.get(
//     '${url}posts/feed/image/',
//     config
//   );
//   const videoPosts = axios.get(
//     '${url}posts/feed/video/',
//     config
//   );
//   axios
//     .all(textPosts, imagePosts, videoPosts)
//     .then(
//       axios.spread((...responses) => {
//         const textRes = responses[0].data.results;
//         const imageRes = responses[1].data.results;
//         const videoRes = responses[2].data.results;
//         dispatch({
//           type: GET_POSTS,
//           payload: textRes.concat(imageRes).concat(videoRes),
//         });
//       })
//     )
//     .catch((err) => console.log(err?.response));
// };

export const postVideo = async (video_url, caption, token) => {
  try {
    const data = {
      url: video_url,
      caption: caption,
      post_type: "video",
    };
    const body = JSON.stringify({ video_url, caption });
    const request = {
      method: "post",
      url: `${url}posts/video/new-video-post/`,
      data,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    };
    const response = await axios(request);
    mixpanel.track("Added a Video Post");
    return response;
  } catch (err) {
    return err;
  }
};
// export const postVideo = (video_url, caption) => (dispatch, getState) => {
//   const key = getState().auth.key;

//   // Headers
//   const config = {
//     headers: {
//       'Content-Type': 'application/json',
//     },
//   };
//   // If token, add to headers config
//   if (key) {
//     config.headers['Authorization'] = `Token ${key}`;
//   }
//   const body = JSON.stringify({ video_url, caption });

//   const textPosts = axios.get(
//     '${url}posts/feed/text/',
//     config
//   );
//   const imagePosts = axios.get(
//     '${url}posts/feed/image/',
//     config
//   );
//   const videoPosts = axios.get(
//     '${url}posts/feed/video/',
//     config
//   );
//   const postVideo = axios.post(
//     '${url}posts/video/new-video-post/',
//     body,
//     config
//   );
//   axios
//     .all([textPosts, imagePosts, videoPosts, postVideo])
//     .then(
//       axios.spread((...responses) => {
//         const textRes = responses[0].data.results;
//         const imageRes = responses[1].data.results;
//         const videoRes = responses[2].data.results;
//         dispatch({
//           type: GET_POSTS,
//           payload: textRes.concat(imageRes).concat(videoRes),
//         });
//       })
//     )
//     .catch((err) => console.log(err?.response));
// };

export const getVideos = () => (dispatch, getState) => {
  const key = getState().auth.key;
  const userId = getState()?.auth?.me[0]?.id;

  // Headers
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  // If token, add to headers config
  if (key) {
    config.headers["Authorization"] = `Token ${key}`;
  }
  axios
    .get(`${url}posts/feed/video/`, config)
    .then((res) => {
      // console.log(res,'getVideo')

      dispatch({
        type: GET_VIDEO,
        payload: res.data.results,
      });
      console.log(res.data.results);
    })
    .catch((err) => console.log(err?.response));
};
export const likePost = (postId) => (dispatch, getState) => {
  console.log("calling like");
  // const key = getState().auth.key;
  const key = localStorage.getItem('key');
  console.log('user key', key);

  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  // If token, add to headers config
  if (key) {
    config.headers["Authorization"] = `Token ${key}`;
  }
  axios
    .patch(
      `${url}posts/posts/like/${postId}/`,
      { id: postId },
      {
        headers: {
          Authorization: `Token ${key}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      console.log(res);
      dispatch({
        type: LIKE_POST,
        payload: res.data,
      });
    })
    .catch((err) => console.log(err));
};
export const commentOnPost = (postId, comment) => (dispatch, getState) => {
  console.log("calling comment");
  const key = getState().auth.key;
  const body = JSON.stringify({ comment });

  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  // If token, add to headers config
  if (key) {
    config.headers["Authorization"] = `Token ${key}`;
  }
  axios
    .post(`${url}posts/text/comment/${postId}/`, body, {
      headers: {
        Authorization: `Token ${key}`,
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: SUBMIT_COMMENT,
        payload: res.data,
      });
    })
    .catch((err) => console.log(err));
};
// Unlike a scream
export const unlikePost = (postId) => (dispatch) => {
  axios
    .get(`/scream/${postId}/unlike`)
    .then((res) => {
      dispatch({
        type: UNLIKE_POST,
        payload: res.data,
      });
    })
    .catch((err) => console.log(err));
};
