import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { CurrentChats, fetchChat } from "../wm_actions/chat";
import Navbar from "../wm_navigation/Navbar";
import PropTypes from "prop-types";
import BottomNav from "../wm_navigation/BottomNav";
// import { setIn } from 'formik';
import Avatar from "@material-ui/core/Avatar";
import { Link, withRouter } from "react-router-dom";
import "./style/chat.css";
import CardHeader from "@material-ui/core/CardHeader";
import NPS from "../wm_nps/nps";
import profilePicture from "./Ellipse 26.png";
import SwipeToDelete from "react-swipe-to-delete-component";
import "react-swipe-to-delete-component/dist/swipe-to-delete.css";
import { loadUsers } from "../wm_actions/connects";
import { useHistory } from "react-router-dom";
import Loader from "../../loader/CircleLoader";

const ChatHome = (props) => {
	const [chatList, setChatList] = useState(false);
	const history = useHistory();

	// 	static propTypes = {
	// 	chatArray: PropTypes.array.isRequired
	// };
	useEffect(() => {
		props.fetchChat();
		props.CurrentChats();
	}, []);

	useEffect(() => {
		if (Array.isArray(props.currentChat)) {
			setChatList(props.currentChat);
		}
	}, [props]);

	console.log(props?.currentChat, "currentChat");
	console.log(props?.searchUsers, "searchUsers");

	const getDay = (date) => {
		var dayNames = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

		return dayNames[new Date(date).getDay()];
	};

	return (
		<div>
			<div>
				<Navbar chatNav={true} />
			</div>
			<div id="chats">
				{chatList ? (
					chatList.map(
						(chat, i) =>
							chat.users[1]?.name.toLowerCase().includes(props?.searchUsers.toLowerCase()) && (
								<SwipeToDelete key={i}>
									<div
										id="chat"
										onClick={() =>
											history.push(
												`/messages/${
													chat?.users?.filter((a, j) => a?.id != localStorage.getItem("userId"))[0]
														?.id
												}/false`
											)
										}
									>
										<Avatar src={chat.users[1]?.profile?.avatar} className="profilePhoto" />

										<div className="nameMsg">
											<div className="name">
												<h5>
													{
														chat?.users?.filter(
															(a, j) => a?.id != localStorage.getItem("userId")
														)[0]?.name
													}
												</h5>
												<p>{getDay(chat?.messages[chat?.messages?.length - 1]?.created_at)}</p>
											</div>
											<div className="msg unRead">
												{chat?.messages[chat?.messages?.length - 1]?.text}
											</div>
										</div>
									</div>
								</SwipeToDelete>
							)
					)
				) : (
					<Loader />
				)}
			</div>
			{/* <NPS /> */}
			<BottomNav />
		</div>
		// </div>
	);
};
const mapStateToProps = (state) => ({
	chatArray: state.chat.chatArray,
	allUsers: state.connects.allUsers,
	currentChat: state.chat.currentChat,
	searchUsers: state.chat.searchUsers
});
export default connect(mapStateToProps, { fetchChat, loadUsers, CurrentChats })(
	withRouter(ChatHome)
);
