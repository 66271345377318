import React from "react";
import "./contact.css";
import logo from "../../assets/Logo.png";
import instagram from "../../assets/images/socials/instagram.png";
import twitter from "../../assets/images/socials/twitter.png";
import linkedin from "../../assets/images/socials/linkedin.png";
import tiktok from "../../assets/images/socials/tiktok.png";

function Contact() {
  return (
    <div>
      <div className="grid md:grid-cols-2 justify-center items-center">
        <div className="md:w-full logo-section md:h-screen md:pt-6">
          <h1 className="font-bold text-white contact-text pt-16">
            GET IN TOUCH!
          </h1>
          <img className="mx-auto whip-logo object-fit" src={logo} alt="" />
        </div>
        <div className="md:w-1/2 mx-auto">
          <div className="">
            <p className="leading-8 connect-section px-3 pt-6">
              Do you have an enquiry, want to know more about Whip Music Africa
              or just want to say hi?
            </p>
            <p className="leading-8 connect-section my-8">
              Don't be shy! Feel free to contact us.
            </p>
          </div>
          <div className="flex justify-center items-center">
            <a
              rel="noreferrer"
              href="mailto:hello@whipafrica.com"
              target="_blank"
            >
              <svg
                className="w-8 mx-3"
                fill="gray"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z"></path>
                <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z"></path>
              </svg>
            </a>
            <p className="connect-section">hello@whipafrica.com</p>
          </div>

          <div className="mt-10">
            <p className="connect-section">Let's be friends!</p>
            <div className="flex justify-center mt-8 pb-20">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.instagram.com/whipmusicafrica/"
              >
                <img className="object-cover w-6 mx-5" src={instagram} alt="" />
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://twitter.com/whipmusicafrica"
              >
                <img className="object-cover w-6 mx-5 " src={twitter} alt="" />
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.linkedin.com/company/whipmusicafrica/"
              >
                <img className="object-cover w-6 mx-5" src={linkedin} alt="" />
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.tiktok.com/@whipmusicafrica"
              >
                <img className="object-cover w-6 mx-5" src={tiktok} alt="" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
