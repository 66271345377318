import React from 'react';
import {
  countryConstants,
  professionConstants,
  genreConstants,
} from '../../../wm_constants/index';
import '../editProfile.css';
import axios from 'axios';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { avatarUpdate } from '../../../wm_actions/profile';
import { storage } from '../../../../components/Firebase/firebase';
import proficPic from '../../../../assets/profilePic.png';
import cameraPic from '../../../../assets/images/Profile/photo-camera.png';

class PatchProfile extends React.Component {
  state = {
    country: '',
    profession: '',
    genre: '',
    name: '',
    prof: '',
    img: null,
    imgUrl: null,
  };
  static propTypes = {
    id: PropTypes.string,
    users: PropTypes.string,
  };
  handleProfileImg = (e) => {
    this.setState({
      img: e.target.files[0],
    });
    setTimeout(() => {
      const { img } = this.state;
      e.preventDefault();
      const ref = storage.ref(`/profile/images/${img.name}`);
      const uploadTask = ref.put(img);
      uploadTask.on('state_changed', console.log, console.error, () => {
        ref.getDownloadURL().then((url) => {
          this.setState({ img: null });
          this.setState({ imgUrl: url });
          this.props.avatarUpdate(url);
        });
      });
    }, 1000);
    console.log(e.target.files[0]);
  };
  //   handleImgUpload(e) {}
  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
    // console.log(e.target.name + ' : ' + e.target.value);
    // console.log(this.props.id);
  };
  onSubmit = (e) => {
    const { id, users } = this.props;
    const { name, country, profession, prof } = this.state;
    e.preventDefault();
    console.log('Submitted');
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${id}`,
      },
    };
    const user = users;
    const body = JSON.stringify({
      name: name,
      country: country,
      profession: [profession, prof],
    });
    axios
      .patch(user, body, config)
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {});
    console.log(user, body, config);
  };
  render() {
    const { country, name, profession, genre, prof } = this.state;
    return (
      <div id="editProfileWrapper">
        <form id="editProfileForm">
          <div className="userImgWrapper">
            <img alt="profilePic" src={proficPic} className="userImg" />
            <div className="profileOverlay">
              <img src={cameraPic} alt="camera" className="camera" />
            </div>
            <input
              type="file"
              id="actual-btn"
              hidden
              onChange={this.handleProfileImg}
            />
            <label for="actual-btn" className="profileImgUpload" />
          </div>
          <p>Full Name</p>
          <input
            value={name}
            name="name"
            onChange={this.onChange}
            placeholder="Your Name"
          />
          <p>Country of Residence</p>
          <select
            className="form-control"
            name="country"
            type="choice"
            value={country}
            onChange={this.onChange}
            id="countryConstants"
          >
            <option value="">Select country</option>
            {countryConstants.map((k) => (
              <option value={k.id}>{k.name}</option>
            ))}
          </select>
          <p>Primary Profession</p>
          <select
            className="form-control"
            name="profession"
            type="choice"
            value={profession}
            onChange={this.onChange}
            id="professionConstants"
          >
            <option value="">Select Profession</option>
            {professionConstants.map((k) => (
              <option value={k.id}>{k.name}</option>
            ))}
          </select>
          <p>Secondary Profession</p>
          <select
            className="form-control"
            name="prof"
            type="choice"
            value={prof}
            onChange={this.onChange}
            id="professionConstants"
          >
            <option value="">Select Second Profession</option>
            {professionConstants.map((k) => (
              <option value={k.id}>{k.name}</option>
            ))}
          </select>
          <p>Genre</p>
          <select
            className="form-control"
            name="genre"
            type="choice"
            value={genre}
            onChange={this.onChange}
            id="genreConstants"
          >
            <option value="">Select Genre</option>
            {genreConstants.map((k) => (
              <option value={k.id}>{k.name}</option>
            ))}
          </select>
          <div id="edit-btn-wrapper" className="editProBtn">
            <button onClick={this.onSubmit}>Submit</button>
          </div>
        </form>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  id: state.auth.key,
  users: state.auth.users,
});
export default connect(mapStateToProps, { avatarUpdate })(PatchProfile);
