import React, { useState, useEffect } from "react";
import BottomNav from "../wm_navigation/BottomNav";
import Navbar from "../wm_navigation/Navbar";
import microphone from "../../assets/images/gigs/microphone 1.png";
import profile from "../../assets/images/gigs/profiles 1.png";
import { Link } from "react-router-dom";
import axios from "axios";
import BounceLoader from "react-spinners/BounceLoader";
import moment from "moment";
import LocationOn from "@material-ui/icons/LocationOnOutlined";
import { config } from "../Endpoints";

const url = config.url.API_URL;
function GigApplications() {
	const [gigs, setGigs] = useState([]);
	const [loading, setLoading] = useState(true);
	const [artist, setArtist] = useState("");

	useEffect(() => {
		const user_id = localStorage.getItem("userId");
		const api = axios.create({
			baseURL: `${url}gigs/`,
		});
		api.get("/").then((res) => {
			console.log(res.data);
			setLoading(false);
			const applied = res.data.results.filter((gig) => {
				return gig.gig_artists.some((artist) => artist.id === user_id);
			});
			setGigs(applied);

			// setArtist(res.data.results[1].gig_artists[0].id);
			// console.log(res.data.results[1].gig_artists[0].id, 'artist');
			console.log(user_id, "logged in");
		});
	}, []);

	if (loading) {
		return (
			<div className="loader-wrapper items-center">
				<BounceLoader color={"#34529E"} size={80} />
			</div>
		);
	} else {
		return (
      <div>
        <div>
          <Navbar />
        </div>
        <div className="flex justify-around my-10">
          <div>
            <Link to="/gigList">
              <button className="text-gray-600 py-3  px-3 rounded-full font-bold text-xs text-gray-600 bg-slate-300 border-none  active:text-blue-900 hover:bg-white hover:text-blue-900">
                All Gigs
              </button>
            </Link>
          </div>
          <div>
            <Link to="/postedGigs">
              <button className="text-gray-600 py-3  px-3 rounded-full font-bold text-xs text-gray-600 bg-slate-300 border-none  active:text-blue-900 hover:bg-white hover:text-blue-900">
                <div className="flex justify-between">
                  <img className="px-3 object-cover" src={microphone} alt="" />
                  Posted Gigs
                </div>
              </button>
            </Link>
          </div>
          <div>
            <Link to="/gigApplications">
              <button className="text-gray-600 py-3  px-3 rounded-full font-bold text-xs text-gray-600 bg-slate-300 border-none  active:text-blue-900 hover:bg-white hover:text-blue-900">
                <div className="flex justify-between">
                  <img className="px-3 object-cover" src={profile} alt="" /> Gig
                  Applications
                </div>
              </button>
            </Link>
          </div>
        </div>

        <div>
          {gigs.map((gig) => (
            <div className="border bg-white flex h-36 shadow-md mx-2 rounded-sm my-5">
              <div>
                {/* <Link to={`/viewGig/${gig.id}`}> */}
                <img
                  className="gig-banner object-cover rounded h-full"
                  src={gig.gig_banner}
                  alt=""
                />
                {/* </Link> */}
              </div>
              <div className="text-left pl-5 mt-3">
                <p className="font-bold text-sm" key={gig.id}>
                  {gig.gig_name}
                </p>
                <p className="gig-date my-2 text-sm" key={gig.id}>
                  {moment(gig.gig_date).format("Do MMMM YYYY")}
                </p>
                <p className="text-sm text-gray-700 text-left" key={gig.id}>
                  <LocationOn className=""></LocationOn>
                  {gig.gig_location_city}, {gig.gig_location_country}
                </p>
                <div className="flex justify-around mt-5 mb-2">
                  <p className="text-xs pt-1">Applied</p>
                  {/* <p className="text-red-600 text-xs rounded-md border border-red-700 py-1 px-3 font-bold">
                Withdraw
              </p> */}
                </div>
              </div>
            </div>
          ))}
        </div>

        <div>
          <BottomNav />
        </div>
      </div>
    );
	}
}

export default GigApplications;
