import React, { useState, useEffect } from "react";
import "./app.scss";
import logoDesktop from "../../assets/Logo-2.png";
import SectionOneImage from "../../assets/images/Main/1.png";
import SectionFourImage from "../../assets/images/Main/2.svg";
import SectionFourImageTwo from "../../assets/images/Main/3.svg";
import SectionFiveImage from "../../assets/images/Main/4.svg";
import SectionSixImage from "../../assets/images/Main/Section6.png";
import SectionSevenImage from "../../assets/images/Main/Section7.png";
import Address from "./address.svg";
import Career from "./Career.svg";
import Find from "./Find.svg";
import { BsChevronRight } from "react-icons/bs";
import { Link } from "react-router-dom";
import mixpanel from "../../components/Mixpanel";
import { useHistory } from "react-router-dom";

function Main() {
	const [supportsPWA, setSupportsPWA] = useState(false);
	const [promptInstall, setPromptInstall] = useState(null);
	const history = useHistory();

	const handleSignUp = () => {
		mixpanel.track("SignUp Attempt");
		history.push("/register");
	};

	useEffect(() => {
		const handler = (e) => {
			e.preventDefault();
			setSupportsPWA(true);
			setPromptInstall(e);
			mixpanel.track("PWA installed from MainScreen Button");
		};
		window.addEventListener("beforeinstallprompt", handler);

		return () => window.removeEventListener("transitionend", handler);
	}, []);

	const installPWA = (evt) => {
		evt.preventDefault();
		mixpanel.track("Install Button Pressed");
		if (!promptInstall) {
			return;
		}
		promptInstall.prompt();
	};

	return (
		<div className="Desktop">
			<div className="Margin">
				{/* Header */}
				<div className="Header">
					<Link to="/Home">
						<img src={logoDesktop} width="100px" />
					</Link>
					<div>
						<Link to="/Login">
							<button className="Login">Login</button>
						</Link>
						<Link to="/register">
							<button className="Join">Join</button>
						</Link>
					</div>
				</div>
				{/* Header End */}

				{/*  */}
				{/*  */}
				{/*  */}
				{/*  */}

				{/* Section 1 */}
				<div className="SectionOne">
					<div className="HalfRow">
						<h2 className="blueTitle">Take Control of Your Music Career</h2>
						<input
							placeholder="Find Music Opportunities Now"
							className="MusicField"
							disabled={true}
						/>
					</div>
					<img src={SectionOneImage} className="Image" />
				</div>
				{/* Section 1 End */}
			</div>

			{/*  */}
			{/*  */}
			{/*  */}
			{/*  */}

			{/* Section 2 */}
			<div className="SectionTwo">
				<h2 className="WhipPlatHeading">On Whip's Platform</h2>
				<div className="SectionTwoRow">
					<div className="Card" style={{ background: "#1a5ece" }}>
						<div className="vectorImgBox">
							<img src={Address} className="CardVectorImage" />
						</div>
						<div className="infoBoxText mt-5">
							<h3 className="titleCard">Showcase your music profile</h3>
							<p className="textCard">
								Position yourself for more reach and opportunities
							</p>
						</div>
					</div>
					<div className="Card" style={{ background: "#EF6F79" }}>
						<div className="vectorImgBox">
							<img src={Career} className="CardVectorImage" />
						</div>
						<div className="infoBoxText mt-5">
							<h3 className="titleCard">
								Take your music career to the next level
							</h3>
							<p className="textCard">
								Book events across Africa. Connect with record labels, producers
								and other artists
							</p>
						</div>
					</div>
					<div className="Card" style={{ background: "#B3995B" }}>
						<div className="vectorImgBox">
							<img src={Find} className="CardVectorImage" />
						</div>
						<div className="infoBoxText mt-5">
							<h3 className="titleCard">
								Find artists for record deals and events
							</h3>
							<p className="textCard">
								Scout for talents at the tip of your fingers
							</p>
						</div>
					</div>
				</div>
			</div>
			{/* Section 2 End */}

			{/*  */}
			{/*  */}
			{/*  */}
			{/*  */}

			{/* Section 3 */}
			<div className="SectionThree">
				<div className="SectionThreeInner">
					<h2 className="HeadingSectionThree">
						Post gigs for artists to see and apply
					</h2>
					<button className="PostButton" onClick={handleSignUp}>
						Post Gigs
					</button>
				</div>
			</div>
			{/* Section 3 End */}

			{/*  */}
			{/*  */}
			{/*  */}
			{/*  */}

			{/* Section 4  */}
			<div className="Section4">
				<div className="Section4Inner">
					<div className="Section4InnerRow">
						<div className="Box Box1Section4">
							<img src={SectionFourImage} className="ImageSectionFour" />
							<h5 className="BoxHeading">CONNECT WITH INDUSTRY LEADERS</h5>
							<p className="BoxText">
								Africa's music industry is overflowing with talents.
							</p>
							<p className="BoxText">
								From artists and producers to booking agents and record labels.
							</p>
							<p className="BoxText">Whip is here to unite all.</p>
						</div>
						<div className="Box">
							<img src={SectionFourImageTwo} className="ImageSectionFour" />
							<h5 className="BoxHeading">FIND COLLABORATORS</h5>
							<p className="BoxText">
								We are here to help you find the best people to collaborate with
								youon a song, album or music festival.
							</p>
							<p className="BoxText">
								Find collaborators from accross the continent.
							</p>
						</div>
					</div>
					<button className="SectionFourButton" onClick={handleSignUp}>
						Find professionals
					</button>
				</div>
			</div>
			{/* Section 4 End */}

			{/*  */}
			{/*  */}
			{/*  */}
			{/*  */}

			{/* Section 5  */}
			<div className="Section5">
				<img src={SectionFiveImage} className="ImageSection5" />
				<div className="RightSection5">
					<h2 className="RightHeading">Who is Whip Music for?</h2>
					<p className="RightText">
						For all music professionals. Artists, Managers, A&amp;R, Producers,
						Record Labels, Songwriters etc
					</p>
					<div className="SecFiveTabPanel">
						<div className="SecFiveTab">
							Find a music professional
							{/*<BsChevronRight />*/}
						</div>
						<div className="SecFiveTab">
							Find your next Gig
							{/*<BsChevronRight />*/}
						</div>
						<div className="SecFiveTab">
							Find a Record Label
							{/*<BsChevronRight />*/}
						</div>
					</div>
				</div>
			</div>
			{/* Section 5 End */}

			{/*  */}
			{/*  */}
			{/*  */}
			{/*  */}

			{/* Section 6  */}
			<div className="Section6">
				<div className="LeftSection6">
					<h2 className="LeftHeading">Who is Whip Music for?</h2>
					<button className="LeftButton" onClick={handleSignUp}>
						Join
					</button>
				</div>
				<img src={SectionSixImage} className="ImageSection6" />
			</div>

			{/* Section 6 End */}

			{/*  */}
			{/*  */}
			{/*  */}
			{/*  */}

			{/* Section 7  */}
			<div className="Section7">
				<img src={SectionSevenImage} className="ImageSection7" />
				<div className="Section7Right">
					<p className="TextRight">
						You can make use of our platform anywhere, even offline
					</p>
					<button className="InstallButton" onClick={installPWA}>
						Install
					</button>
				</div>
			</div>

			{/* Section 7 End */}

			{/*  */}
			{/*  */}
			{/*  */}
			{/*  */}

			{/* Footer */}
			<div className="footerSection">
				<div className="TabletVisible">
					<img src={logoDesktop} />
					<Link to="#" className="CopyRight">
						<svg
							width="15"
							height="15"
							viewBox="0 0 15 15"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
							style={{ margin: "0% 20px" }}
						>
							<path
								d="M7.5 0C3.36 0 0 3.36 0 7.5C0 11.64 3.36 15 7.5 15C11.64 15 15 11.64 15 7.5C15 3.36 11.64 0 7.5 0ZM7.5 13.5C4.2 13.5 1.5 10.8 1.5 7.5C1.5 4.2 4.2 1.5 7.5 1.5C10.8 1.5 13.5 4.2 13.5 7.5C13.5 10.8 10.8 13.5 7.5 13.5Z"
								fill="#AAAAAA"
							/>
							<path
								d="M5.52002 5.19C5.79002 4.89 6.12002 4.65 6.51002 4.5C6.90002 4.32 7.29002 4.23 7.71002 4.23C8.22002 4.23 8.73002 4.35 9.15002 4.62C9.57002 4.86 9.96002 5.22 10.23 5.61L11.31 4.8C10.89 4.26 10.38 3.78 9.78002 3.48C9.15002 3.15 8.49002 3 7.74002 3C7.14002 3 6.54002 3.12 6.00002 3.36C5.46002 3.6 4.98002 3.93 4.59002 4.32C4.20002 4.74 3.87002 5.19 3.63002 5.76C3.39002 6.3 3.27002 6.9 3.27002 7.5C3.27002 8.13 3.39002 8.7 3.63002 9.24C3.87002 9.78 4.17002 10.26 4.59002 10.68C4.98002 11.1 5.46002 11.4 6.00002 11.64C6.54002 11.88 7.11002 12 7.74002 12C8.49002 12 9.18002 11.85 9.78002 11.52C10.38 11.19 10.92 10.74 11.31 10.2L10.23 9.39C9.96002 9.81 9.60002 10.14 9.15002 10.38C8.70002 10.62 8.22002 10.74 7.71002 10.74C7.29002 10.74 6.90002 10.65 6.51002 10.47C6.15002 10.29 5.82002 10.05 5.52002 9.78C5.25002 9.48 5.01002 9.15 4.86002 8.76C4.71002 8.37 4.62002 7.95 4.62002 7.5C4.62002 7.05 4.71002 6.63 4.86002 6.24C5.01002 5.85 5.25002 5.49 5.52002 5.19Z"
								fill="#AAAAAA"
							/>
						</svg>
						Whip Music Africa 2020
					</Link>
				</div>
				<img src={logoDesktop} className="TabletInVisible" />
				<div className="MobilLinkWrapper">
					<Link to="/terms" className="Link">
						About
					</Link>
					<Link to="/terms" className="Link">
						Privacy Policy
					</Link>
					<Link to="/terms" className="Link">
						Terms and Conditions
					</Link>
					<Link to="/terms" className="Link">
						Help Center
					</Link>
					<Link to="/contactUs" className="Link">
						Contact Us
					</Link>
				</div>
				<Link to="/terms" className="CopyRight TabletInVisible">
					<svg
						width="15"
						height="15"
						viewBox="0 0 15 15"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
						style={{ margin: "0% 20px" }}
					>
						<path
							d="M7.5 0C3.36 0 0 3.36 0 7.5C0 11.64 3.36 15 7.5 15C11.64 15 15 11.64 15 7.5C15 3.36 11.64 0 7.5 0ZM7.5 13.5C4.2 13.5 1.5 10.8 1.5 7.5C1.5 4.2 4.2 1.5 7.5 1.5C10.8 1.5 13.5 4.2 13.5 7.5C13.5 10.8 10.8 13.5 7.5 13.5Z"
							fill="#AAAAAA"
						/>
						<path
							d="M5.52002 5.19C5.79002 4.89 6.12002 4.65 6.51002 4.5C6.90002 4.32 7.29002 4.23 7.71002 4.23C8.22002 4.23 8.73002 4.35 9.15002 4.62C9.57002 4.86 9.96002 5.22 10.23 5.61L11.31 4.8C10.89 4.26 10.38 3.78 9.78002 3.48C9.15002 3.15 8.49002 3 7.74002 3C7.14002 3 6.54002 3.12 6.00002 3.36C5.46002 3.6 4.98002 3.93 4.59002 4.32C4.20002 4.74 3.87002 5.19 3.63002 5.76C3.39002 6.3 3.27002 6.9 3.27002 7.5C3.27002 8.13 3.39002 8.7 3.63002 9.24C3.87002 9.78 4.17002 10.26 4.59002 10.68C4.98002 11.1 5.46002 11.4 6.00002 11.64C6.54002 11.88 7.11002 12 7.74002 12C8.49002 12 9.18002 11.85 9.78002 11.52C10.38 11.19 10.92 10.74 11.31 10.2L10.23 9.39C9.96002 9.81 9.60002 10.14 9.15002 10.38C8.70002 10.62 8.22002 10.74 7.71002 10.74C7.29002 10.74 6.90002 10.65 6.51002 10.47C6.15002 10.29 5.82002 10.05 5.52002 9.78C5.25002 9.48 5.01002 9.15 4.86002 8.76C4.71002 8.37 4.62002 7.95 4.62002 7.5C4.62002 7.05 4.71002 6.63 4.86002 6.24C5.01002 5.85 5.25002 5.49 5.52002 5.19Z"
							fill="#AAAAAA"
						/>
					</svg>
					Whip Music Africa 2020
				</Link>
			</div>
		</div>
	);
}

export default Main;
