/* eslint-disable */
import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  postTextLike,
  postImageLike,
  postVideoLike,
  getAllPosts,
  likePost,
  unlikePost,
  commentOnPost,
} from "../wm_actions/posts";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import "./styles/posts.css";
import Like from "./like";
import comment from "../../assets/images/Posts/comment.png";
import share from "../../assets/images/Posts/share.png";
import MyLoader from "../../loader/CircleLoader";
import { professionConstants, countryConstants } from "../wm_constants/index";
import VideoPlayer from "react-simple-video-player";
import { Link } from "react-router-dom";
import CommentSystem from "./component/comments";
import axios from "axios";
import _ from "lodash";
import Linkify from "react-linkify";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { Bookmark } from "@material-ui/icons";
import IconButton from "@material-ui/core/IconButton";
import { Chip, Tooltip } from "@material-ui/core";
import { config } from "../Endpoints";
import posts from "../wm_reducers/posts";
import PostCard from "../wm_post/components/PostCard";


const url = config.url.API_URL;
const frontend_url = config.url.FRONTEND_URL;
export class Posts extends Component {
  state = {
    postType: "",
    postId: "",
    posts: [],
    commentSystem: false,
    liked: [],
    fetching: true,
    open: false,
    clicked: "",
    likedState: [],
    likes: [],
    currentLimit: 10,
    currentOffset: 0,
  };

  static propTypes = {
    // posts: PropTypes.array.isRequired,
    likes: PropTypes.array,
    isFetching: PropTypes.bool,
    me: PropTypes.array,
  };

  async componentDidMount() {

    await this.props.getAllPosts();
    this.setState({
      // currentLimit: this.state.currentLimit + 10,
      currentOffset: this.state.currentOffset + 10,
    });

    console.log(this.props.posts);
    // this.setState({
    //   likedState: new Array(this.props.posts.length).fill(false),
    // });
    // let output = [];
    // for (let index = 0; index < this.props.posts.length; index++) {
    //   const element = this.props.posts[index].likes.length;
    //   output.push(element);
    // }
    // console.log(output);
    // this.setState({
    //   likes: output,
    // });
    // this.state.posts.forEach()
    // this.timer = setInterval(
    //   () =>
    //     this.setState({
    //       posts: this.props.posts,
    //     }),
    //   500
    // );
    window.addEventListener("scroll", console.log("scrolling"));

    window.addEventListener("scroll", this.loadFunc);
  }

  componentWillUnmount() {
    clearInterval(this.timer);
    window.removeEventListener("scroll", this.loadFunc);
  }
  // componentDidUpdate(prevProps) {
  //   console.log('method hit')
  //   if (this.props !== prevProps) console.log('component updated', this.props)
  // }

  handleFetchPosts = (postId, comment) => {
    this.props.commentOnPost(postId, comment);
    this.setState({ commentSystem: false });
  };
    // share post dropdown
  handleDropdownClick = (i) => {
    this.setState({ clicked: i, open: !this.state.open });
    console.log(this.state.open);
  };
    // copy url from dropdown
  copyUrl = (id, type) => {

    const el = document.createElement("input");
    el.value = `${frontend_url}posts/${type}/${id}`;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    this.setState({ open: !this.state.open });
    console.log("copying path");
  };

  onLike = (id, i) => {

    const { authKey } = this.props;

    this.props.likePost(id);
  };

  onSubmit = (e, pid) => {
    e.preventDefault();
  };
  commentSystemToggle = () => {
    const { commentSystem } = this.state;
    this.setState({
      commentSystem: !commentSystem,
    });
  };
  loadFunc = () => {
    console.log("scrolling");
    // console.log(this.props.toFetchNext);
    console.log(this.props.productLoading, "productLoading");
    if (
      window.innerHeight + document.documentElement.scrollTop ===
      document.scrollingElement.scrollHeight &&
      !this.props.productLoading &&
      this.props.toFetchNext !== null
    ) {
      console.log("fetching extra data");
      this.props.getAllPosts(this.state.currentLimit, this.state.currentOffset);
      this.setState({
        // currentLimit: this.state.currentLimit + 10,
        currentOffset: this.state.currentOffset + 10,
      });
      // this.timer = setInterval(
      //   () =>
      //     this.setState({
      //       posts: this.props.posts,
      //     }),
      //   500
      // );
    }
  };

  render() {
    if (this.props.isFetching) {
      return (
        <div className="loading-wrap">
          <MyLoader />
        </div>
      );
    }

    const { commentSystem } = this.state;
    const { me, posts } = this.props;
    return (
      <>
        <div className="post-wrapper">

          {posts?.map((post, index) => (
            <PostCard
              key={index}
              postIdx={index}
              postId={post?.id}
              name={post?.created_by.name}
              avatar={post?.created_by?.profile?.avatar}
              caption={post?.caption}
              profession1={post?.created_by?.profile?.profession[0]}
              profession2={post?.created_by?.profile?.profession[1]}
              commentsCount={post?.Comments.length}
              likesCount={post?.likes.length}
              mediaType={post?.post_type}
              url={post?.url}
              onLike={this.onLike}
              toggleComments={false}
              liked={post.likes?.find((like) => like.id === localStorage.getItem("id"))}
              comments={post?.Comments}
              handleFetchPosts={this.handleFetchPosts}
              authKey={this.props?.authKey}
              handleShare={this.copyUrl}


                    />

          ))}


          {this.props.productLoading ? (
            <div className={"z-50"}>
              <MyLoader />
            </div>
          ) : null}
        </div>
      </>
    );
  }
}


const mapStateToProps = (state) => ({
  posts: state.posts.posts.results,
  toFetchNext: state.posts.posts.next,
  isFetching: state.posts.isFetching,
  me: state.auth.me,
  authKey: state.posts.key,
  productLoading: state.posts.productLoading,
  likeArr: state.posts.likeArr,
});



export default connect(mapStateToProps, {
  postTextLike,
  postImageLike,
  postVideoLike,
  getAllPosts,
  likePost,
  unlikePost,
  commentOnPost,
})(Posts);
