import React from "react";
import Home from "../../assets/images/bottomNav/home.png";
import Connect from "../../assets/images/bottomNav/connect.png";
import Share from "../../assets/images/bottomNav/share.png";
import Browse from "../../assets/images/bottomNav/browse.png";
import Chat from "../../assets/images/bottomNav/chat.png";
import Gig from "../../assets/images/bottomNav/mic.png";
import "./styles/BottomNav.css";
import { Link } from "react-router-dom";

export default function BottomNav() {
	return (
		<div className="btmNav-wrapper">
			{/* TODO can be extracted to a component <NavLink to={path} title={title} icon={icon} /> , loop through an object and build this..*/}
			<Link to="/Home">
				<img alt="" src={Home} />
				<p>Home</p>
			</Link>
			<Link to="/browse" id="browse-wrap">
				<img alt="" id="browseimg" src={Browse} />
				<p>Browse</p>
			</Link>
			<Link to="/gigList">
				<img id="gig-icon" alt="" src={Gig} />
				<p>Gigs</p>
			</Link>
			<Link to="/share">
				<img alt="" src={Share} />
				<p>Share</p>
			</Link>
			<Link to="/connect">
				<img alt="" src={Connect} />
				<p>Connect</p>
			</Link>
			<Link to="/chat">
				<img alt="" src={Chat} />
				<p>Chat</p>
			</Link>
		</div>
	);
}
