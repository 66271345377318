import React, { useRef, useState, useEffect } from 'react';
import { Link, Redirect } from 'react-router-dom';
import Back from '../../../assets/images/Profile/arrowBack.png';
import Webcam from 'react-webcam';
import './styles/sharePhoto.css';
import accept from '../../../assets/images/share/accept.png';
import cancel from '../../../assets/images/share/cancel.png';
import camera from '../../../assets/images/share/camera.png';
import flip from '../../../assets/images/share/flip.png';
import { storage } from '../../../components/Firebase/firebase';
import ShareCaptionScreen from './shareCaption';

class SharePhoto extends React.Component {
  state = {
    image: null,
    showCamera: false,
    showCapture: true,
    cameraMode: 'environment',
    back: true,
    front: false,
    modalOpen: false,
  };
  setRef = (webcam) => {
    this.webcam = webcam;
  };

  capture = () => {
    const imageSrc = this.webcam.getScreenshot();
    this.setState({
      image: imageSrc,
      showCamera: true,
      showCapture: false,
    });
    sessionStorage.setItem('imgCapture', imageSrc);
  };
  acceptImg = () => {};
  rejectImg = () => {
    this.setState({
      image: null,
      showCamera: false,
      showCapture: true,
      modalOpen: false,
    });
  };
  frontCamera = () => {
    this.setState({
      cameraMode: 'user',
      front: true,
      back: false,
    });
  };
  backCamera = () => {
    this.setState({
      cameraMode: 'environment',
      back: true,
      front: false,
    });
  };
  modalClose = () => {
    this.setState({
      modalOpen: false,
    });
  };
  modalOpen = () => {
    this.setState({
      modalOpen: true,
    });
  };
  shareCaption = () => {
    const { image } = this.state;
    if (image) {
      return <Redirect to="/shareCaption" />;
    }
  };
  render() {
    const videoConstraints = {
      width: 1920,
      height: 1040,
      facingMode: this.state.cameraMode,

    };
    const { front, back, showCamera, showCapture, image, modalOpen } =
      this.state;
    return (
      <div id="sharePhotoWrapper">
        <img
          id="shareBackButton"
          alt=""
          src={Back}
          onClick={this.props.history.goBack}
        />
        <div id="sharePhotoCamera">
          {!showCamera && (
            <div id="innerSharePhotoCamera">
              {!front && (
                <img
                  alt=""
                  src={flip}
                  onClick={this.frontCamera}
                  className="sharePhotoFlip"
                />
              )}
              {!back && (
                <img
                  alt=""
                  src={flip}
                  onClick={this.backCamera}
                  className="sharePhotoFlip"
                />
              )}
              <p className="sharePhotoText">Flip</p>
              <Webcam
                audio={false}
                height={1080}
                ref={this.setRef}
                screenshotFormat="image/jpeg"
                width={1080}
                videoConstraints={videoConstraints}
                mirrored={true}
                imageSmoothing
                // mirrored
                style={{
                  height: '100vh',
                  width: '100%',
                  objectFit: 'cover',
                }}
              />
            </div>
          )}
          <img
            id="shareCaptureButton"
            alt=""
            src={camera}
            onClick={this.capture}
          />
          {!showCapture && (
            <div>
              <img
                id="sharePhotoCapture"
                src={image}
                style={{
                  height: '100vh',
                  width: '100%',
                  objectFit: 'cover',
                }}
                alt="CapturePhoto"
              />
              <div id="sharePhotoUploadingBtns">
                <img
                  id="sharePhotoCancel"
                  onClick={this.modalOpen}
                  src={cancel}
                  alt="Cancel Button"
                />
                <Link to="/shareCaption">
                  <img id="sharePhotoAccept" src={accept} />
                </Link>
              </div>
              {modalOpen && (
                <div id="shareModalWrapper">
                  <div id="shareModal">
                    <p>Delete this photo?</p>
                    <div id="shareModalBtnsWrapper">
                      <div id="shareModalBtns">
                        <button onClick={this.modalClose}>CANCEL</button>
                        <button onClick={this.rejectImg}>DELETE</button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default SharePhoto;
