import React from "react";
import { othersRatings } from "../../wm_actions/profile";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import StarRatings from "react-star-ratings";

class OtherRatings extends React.Component {
	state = {
		showRating: null
	};
	static propTypes = {
		personRatings: PropTypes.array,
		me: PropTypes.array
	};
	componentWillMount() {
		this?.props?.othersRatings(this?.props?.me[0]?.id);
	}
	showIt = (e) => {
		e.preventDefault();
		this.setState({ showRating: !this.state.showRating });
	};
	render() {
		console.log(this.props.personRatings, "this.props.personRatings");
		return (
			<div className="RateMain">
				{this.props.personRatings ? (
					this.props.personRatings.map((rec) => (
						<div className="rateContainer">
							<div className="RateInner" onClick={(e) => {
		e.preventDefault();
		this.setState({ showRating: rec.rating_by.id});
	}}>
								<button className="rateButton" >
									<StarRatings
										rating={rec.talent_rating}
										starRatedColor="#cca20f"
										changeRating={this.props.funcTalent}
										numberOfStars={5}
										name="rating"
										starDimension="25px"
										starHoverColor="#cca20f"
										starEmptyColor="#666666"
									/>
								</button>
								<div>
									<p className="RaterName">{rec.rating_by.name}</p>
									<p className="RaterProffesion">Producer</p>
								</div>
							</div>
							{this.state.showRating == rec.rating_by.id ? (
								<div className="hiddenRateContainer">
									<p className="ratingHeadings">Talent</p>
									<StarRatings
										rating={rec.talent_rating}
										starRatedColor="#cca20f"
										changeRating={this.props.funcTalent}
										numberOfStars={5}
										name="rating"
										starDimension="20px"
										starHoverColor="#cca20f"
										starEmptyColor="#666666"
									/>
									<p className="ratingHeadings">Professionalism</p>
									<StarRatings
										rating={rec.professionalism_rating}
										starRatedColor="#cca20f"
										changeRating={this.props.functionProfessionalism}
										numberOfStars={5}
										name="rating"
										starDimension="20px"
										starHoverColor="#cca20f"
										starEmptyColor="#666666"
									/>
									<p className="ratingHeadings">Communication</p>
									<StarRatings
										rating={rec.communication_rating}
										starRatedColor="#cca20f"
										changeRating={this.props.functionCommunication}
										numberOfStars={5}
										name="rating"
										starDimension="20px"
										starHoverColor="#cca20f"
										starEmptyColor="#666666"
									/>
								</div>
							) : null}
						</div>
					))
				) : (
					<div className="ProfileLoaderWrapper">
						<div className="loader"></div>
					</div>
				)}
			</div>
		);
	}
}
const mapStateToProps = (state) => ({
	personRatings: state.profile.personRatings,
	me: state.auth.me
});

export default connect(mapStateToProps, { othersRatings })(OtherRatings);
