import React from "react";
import arrowBack from "../../../assets/images/Profile/arrowBack.png";
import "./styles/sharePost.css";
import Avatar from "@material-ui/core/Avatar";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { postText } from "../../wm_actions/posts";
import Picker from "emoji-picker-react";
import { getProfileInfo } from "../../wm_actions/profile";

class SharePost extends React.Component {
	//   static propTypes = {
	//     postText: PropTypes.func.isRequired,
	//   };
	state = {
		text_body: "",
		showPicker: false,
	};

	onEmojiClick = (event, emojiObject) => {
		let textarea = document.getElementById("postInputContentField");
		textarea.focus();
		let start = this.state.text_body.substring(0, textarea.selectionStart);
		let end = this.state.text_body.substring(textarea.selectionStart);
		let msg = start + emojiObject.emoji + end;
		this.setState((prevInput) => ({
			text_body: msg,
		}));
	};

	// when component mounts load user from redux state
	async componentDidMount() {
		await this.props.getProfileInfo(localStorage.getItem("id"));
	}

	handleTextPost = async (text_body) => {
		const token = localStorage.getItem("key");
		await postText(text_body, token).then((res) => {
			if (res.status === 201) {
				// message.success('Address Added successfully');
				this.props.history.push("/Home");
				window.location.reload();
			}
		});
	};
	render() {
		const { text_body } = this.state;
		return (
			<div>
				<div id="editTopBar">
					<img alt="" src={arrowBack} onClick={this.props.history.goBack} />
					<button
						id="postShareBtn"
						onClick={() => {
							this.handleTextPost(this.state.text_body);
							console.log(text_body);
						}}
					>
						Post
					</button>
				</div>
				<div id="sharePostInputSection">
					<Avatar src={this.props.person?.profile?.avatar} />
					<textarea
						placeholder="What do you want to share?"
						id="postInputContentField"
						required
						value={text_body}
						rows={10}
						onChange={(e) => this.setState({ text_body: e.target.value })}
						name="text_body"
					/>
					<img
						alt="emoji picker"
						className="emoji-icon"
						src="https://icons.getbootstrap.com/assets/icons/emoji-smile.svg"
						onClick={() =>
							this.setState({ showPicker: !this.state.showPicker })
						}
					/>
				</div>
				<div>
					{this.state.showPicker && (
						<Picker
							pickerStyle={{ width: "100%" }}
							onEmojiClick={this.onEmojiClick}
						/>
					)}
				</div>
			</div>
		);
	}
}
const mapStateToProps = (state) => ({
	person: state.profile.person,
});
export default connect(mapStateToProps, { getProfileInfo })(SharePost);
