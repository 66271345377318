import VideoRecorder from "react-video-recorder";
import "./styles/VideoRecorder.scss";
import { useHistory, withRouter, useParams } from "react-router";
import { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { loadUser } from "../../wm_actions/auth";
import accept from "../../../assets/images/share/accept.png";
import cancel from "../../../assets/images/share/cancel.png";
import { Link } from "react-router-dom";

const RecordView = (props) => {
	const history = useHistory();
	const [openPremModal, setOpenPremModal] = useState(false);
	const [sixty, setSixty] = useState("");
	const [thirtySec, sixtySec] = useState(30000);
	const [ComRec, setComRec] = useState(false);
	// const [slugone, setSlugone] = useState("");
	const [myUser, setMyUser] = useState(false);

	let { slug } = useParams();
	const { loadUser, me } = props;

	// use effect to load current user
	useEffect(() => {
		loadUser();
	}, [loadUser]);

	// if current user data exist set myUser
	useEffect(() => {
		let isFetching = true;
		if (isFetching & me) {
			setMyUser(me[0]);
		}
		return () => {
			let isFetching = false;
		}
	}, [me]);

	// setting slug
	// useEffect(() => {
	// 	setSlugone(slug);
	// 	document
	// 		.getElementsByClassName("button__Button-hkteey-0 dWiTLG")[0]
	// 		?.click();
	// }, [slug]);

	console.log(myUser);

	// const handleRedirect = () => {
	//   console.log("working");
	// };
	const changeDurationToSixty = useCallback(() => {
		sixtySec(60000);
	}, []);

	const changeDurationToThirty = useCallback(() => {
		sixtySec(30000);
	}, []);

	return (
		<div className="ShareVideo">
			<VideoRecorder
				chunkSize={250}
				constraints={{
					audio: true,
					video: true,

				}}
				countdownTime={3000}
				dataAvailableTimeout={500}
				isFlipped
				isOnInitially
				showReplayControls
				replayVideoAutoplayAndLoopOff
				timeLimit={thirtySec}
				onRecordingComplete={(videoBlob) => {
					const url = URL.createObjectURL(videoBlob);
					sessionStorage.setItem("video", url);
					setComRec(true);
				}}
			/>
			{ComRec ? (
				<div>
					<div id="sharePhotoUploadingBtns">
						<img id="sharePhotoCancel" src={cancel} alt="" />
						<Link to="/shareVideoCaption">
							<img id="sharePhotoAccept" src={accept} alt="" />
						</Link>
					</div>
				</div>
			) : myUser?.is_premium ? (
				<div className="durationTime">
					<button
						className={
							sixty
								? "secondText sixty changeDurationBtn"
								: "secondText sixty changeDurationBtnInactive"
						}
						onClick={() => {
							if (myUser?.is_premium) {
								setSixty(true);
								changeDurationToSixty();
							} else {
								setOpenPremModal(true);
							}
						}}
					>
						60s
					</button>
					<button
						className={
							!sixty
								? "secondText thirty changeDurationBtn"
								: "secondText thirty changeDurationBtnInactive"
						}
						onClick={() => {
							setSixty(false);
							changeDurationToThirty();
						}}
					>
						30s
					</button>
				</div>
			) : (
				<div className="durationTime">
					<button
						className={
							!sixty
								? "secondText thirty changeDurationBtn"
								: "secondText thirty changeDurationBtnInactive"
						}
						onClick={() => {
							setSixty(false);
							changeDurationToThirty();
						}}
					>
						30s
					</button>
				</div>
			)}

			{openPremModal ? (
				<div className="activatePremiumModal">
					<div className="innerActivatePremiumModal">
						<div
							onClick={() => setOpenPremModal(false)}
							className="closeButton"
						>
							<svg
								width="12"
								height="12"
								viewBox="0 0 12 12"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M11.8203 1.35156L7.17188 6L11.8203 10.6484L10.6484 11.8203L6 7.17188L1.35156 11.8203L0.179688 10.6484L4.82812 6L0.179688 1.35156L1.35156 0.179688L6 4.82812L10.6484 0.179688L11.8203 1.35156Z"
									fill="black"
								/>
							</svg>
						</div>
						<p>
							This is only accessible by premium subscribers. Update to premium
							to have access to this feature
						</p>
						<button
							className="premiumModalButton"
							onClick={() => history.push("/premium")}
						>
							Activate Premium
						</button>
					</div>
				</div>
			) : null}
		</div>
	);
};;

const mapStateToProps = (state) => ({
  isPremium: state.auth.isPremium,
  me: state.auth.me,
});
export default connect(mapStateToProps, { loadUser })(withRouter(RecordView));
