import axios from "axios";
import mixpanel from "../../components/Mixpanel";
import {
  CONNECTION_REQUESTS_RECIEVED,
  CONNECTION_REQUESTS_FETCHING,
  // FETCHING_CONNECTION_USERS,
  FETCHING_ALL_USERS,
  // FETCHING_USERS_FAILED,
  FETCHING_USERS_SUCCESS,
  SENDING_CONNECT_REQUEST,
  USER_CONNECT_LOADING,
  SENT_CONNECT_REQUEST,
  FAILED_CONNECT_REQUEST,
  NUMBER_OF_CONNECTS,
  FETCHING_NUMBER_OF_CONNECTS,
  FETCHING_SENT_REQUEST,
  SENT_REQUESTS_RECEIVED,
  DELETED_CONNECT_REQUEST,
  DELETING_CONNECT_REQUEST,
  MERGED_CONNECT_USERS,
  ACCEPTED_CONNECT_REQUEST,
  ACCEPTING_CONNECT_REQUEST,
  UNABLE_TO_ACCEPT_CONNECT_REQUEST,
  SEARCH_USER,
  FETCHING_ALL_USERS_SUCCESS,
  DISCONNECT_CONNECT_REQUEST,
  FETCHING_MUTUAL_CONNECTIONS_SUCCESS,
  FETCHING_MUTUAL_CONNECTIONS_FAILED,
  FETCHING_MUTUAL_CONNECTIONS,
  FETCHING_CONNECTION_USERS,
} from "./types";
import { createMessage, returnErrors } from "./messages";

import { config } from "../Endpoints";

const url = config.url.API_URL;

// import { isLegacyEdge } from 'react-device-detect';
// import { ContactMailSharp } from '@material-ui/icons';

// Fetching the Connection Request Recieved
export const loadRequests = () => (dispatch, getState) => {
  // Connection Requests Fetching State
  dispatch({ type: CONNECTION_REQUESTS_FETCHING });
  // Get Token from state
  const key = getState().auth.key;

  //Headers
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  // If token, add to headers config
  if (key) {
    config.headers["Authorization"] = `Token ${key}`;
  }

  axios
    .get(`${url}connections/users/connectrequests/`, config)
    .then((res) => {
      console.log(res, "res");
      dispatch({
        type: CONNECTION_REQUESTS_RECIEVED,
        payload: res.data.results,
      });
    })
    .catch((err) => {
      console.log(err?.response);
    });
};
// Fetching Connection Users
export const connectionUsers = () => (dispatch, getState) => {
  // Get Token from state
  const key = getState().auth?.key;
  const userID = getState().auth?.me[0]?.id;

  // Headers
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  // If token, add to headers config
  if (key) {
    config.headers["Authorization"] = `Token ${key}`;
  }
  const mergeById = (a1, a2) =>
    a1.map((itm) => ({
      ...a2.find((item) => item.receiver === itm.id && item),
      ...itm,
    }));
  const allConnectUsers = axios.get(`${url}users/`, config);
  const sentRequestUsers = axios.get(
    `${url}connections/users/connectrequests/pending/`,
    config
  );
  const connects = axios.get(`${url}connections/users/connects/`, config);
  axios
    .all([allConnectUsers, sentRequestUsers, connects])
    .then(
      axios.spread((...responses) => {
        const allConnectUsersRes = responses[0].data.results;
        const sentRequestUsersRes = responses[1].data.results;
        const prevMerge = mergeById(allConnectUsersRes, sentRequestUsersRes);
        const connectsRes = responses[2].data.results;
        const user = [userID];
        const finalArr = prevMerge.filter(
          ({ id }) => !connectsRes.some((exclude) => exclude.id === id)
        );
        const end = finalArr.filter(
          ({ id }) => !user.some((exclude) => exclude === id)
        );
        dispatch({
          type: MERGED_CONNECT_USERS,
          payload: end,
        });
      })
    )
    .catch((err) => {
      console.log(err?.response);
    });
};
export const userConnectLoader = (bool) => {
  return (dispatch) => {
    dispatch({
      type: USER_CONNECT_LOADING,
      payload: bool,
    });
  };
};
// Fetching All Users in the App For Connection Recommendations
let limit = 0;
export const loadUsers = () => (dispatch, getState) => {
  console.log("userAll");
  // User Fetching State
  dispatch(userConnectLoader(true));

  // Get Token from state
  const key = getState().auth.key;

  // Headers
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  // If token, add to headers config
  if (key) {
    config.headers["Authorization"] = `Token ${key}`;
  }
  if (limit <= 100) {
    limit = limit + 15;
  }

  axios
    .get(`${url}users/?limit=${limit}`, config)
    .then((res) => {
      dispatch(userConnectLoader(false));
      dispatch({
        type: FETCHING_ALL_USERS_SUCCESS,
        payload: res.data.results,
      });
    })
    .catch((err) => {
      console.log(err?.response);
    });
};
export const connectedUsers = () => (dispatch, getState) => {
  console.log("userAll");
  // User Fetching State
  dispatch(userConnectLoader(true));

  // Get Token from state
  const key = getState().auth.key;

  // Headers
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  // If token, add to headers config
  if (key) {
    config.headers["Authorization"] = `Token ${key}`;
  }
  if (limit <= 100) {
    limit = limit + 15;
  }

  axios
    .get(`${url}connections/users/connects/?limit=${limit}`, config)
    .then((res) => {
      dispatch(userConnectLoader(false));
      console.log(res);
      dispatch({
        type: FETCHING_CONNECTION_USERS,
        payload: res.data.results,
      });
    })
    .catch((err) => {
      console.log(err?.response);
    });
};

// Connection Request System
export const sendRequest = (requestId) => (dispatch, getState) => {
  // Sending Request State
  dispatch({ type: SENDING_CONNECT_REQUEST });

  // Get Token from state
  const key = getState().auth.key;

  // Headers
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `token ${key}`,
    },
  };

  // If token, add to headers config
  if (key) {
    config.headers["Authorization"] = `Token ${key}`;
  }

  // const requestUrl = JSON.stringify(requestId)
  console.log(requestId);
  console.log(
    `${url}connections/users/connectrequests/${requestId}/`,
    {},
    config
  );

  axios
    .post(`${url}connections/users/connectrequests/${requestId}/`, {}, config)
    .then((res) => {
      dispatch({
        type: SENT_CONNECT_REQUEST,
        payload: res,
      });
      dispatch(updateSentRequest());
      dispatch(createMessage(res.data));
      console.log(res.data);
    })
    .catch((err) => {
      dispatch(returnErrors(err?.response?.data, err?.response?.status));
      dispatch({ type: FAILED_CONNECT_REQUEST });
      console.log(err?.response);
    });
};
export const updateSentRequest = () => (dispatch, getState) => {
  dispatch({ type: FETCHING_SENT_REQUEST });

  // Get Token from state
  const key = getState().auth.key;

  // Headers
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  // If token, add to headers config
  if (key) {
    config.headers["Authorization"] = `Token ${key}`;
  }

  axios
    .get(`${url}connections/users/connectrequests/pending/`, config)
    .then((res) => {
      dispatch({
        type: SENT_REQUESTS_RECEIVED,
        payload: res.data.results,
      });
      console.log(res.data);
    })
    .catch((err) => {
      console.log(err?.response);
    });
};
// Fetching Number of Connections for a single User
export const numberOfConnections = () => (dispatch, getState) => {
  // Fetching Number of Connections
  dispatch({ type: FETCHING_NUMBER_OF_CONNECTS });
  // Get Token from state
  const key = getState().auth.key;

  //Headers
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  // If token, add to headers config
  if (key) {
    config.headers["Authorization"] = `Token ${key}`;
  }

  axios
    .get(`${url}connections/users/connects/`, config)
    .then((res) => {
      dispatch({
        type: NUMBER_OF_CONNECTS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err?.response);
    });
};

// Delete Connection /connections/users/connects/disconnect/
export const deleteConnection = (requestId) => (dispatch, getState) => {
  // Deleting Request State
  dispatch({ type: DELETING_CONNECT_REQUEST });

  // Get Token from state
  const key = getState().auth.key;

  // Headers
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  // If token, add to headers config
  if (key) {
    config.headers["Authorization"] = `token ${key}`;
  }

  // const requestUrl = JSON.stringify(requestId)
  console.log(requestId);
  console.log(
    `${url}connections/users/connectrequests/reject/${requestId}/`,
    config
  );

  axios
    .delete(
      `${url}connections/users/connectrequests/reject/${requestId}/`,
      config
    )
    .then((res) => {
      dispatch({
        type: DELETED_CONNECT_REQUEST,
        payload: res,
      });
      mixpanel.track("Connection Rejected", {
        requester_id: requestId,
        status: "rejected",
      });
      console.log(res.data);
      dispatch(loadRequests());
    })
    .catch((err) => {
      dispatch(returnErrors(err?.response?.data, err?.response?.status));
      dispatch({ type: FAILED_CONNECT_REQUEST });
      console.log(err?.response);
    });
};

// disConnect Connection /connections/users/connects/disconnect/
export const disConnectConnection = (requestId) => (dispatch, getState) => {
  // Deleting Request State
  dispatch({ type: DISCONNECT_CONNECT_REQUEST });

  // Get Token from state
  const key = getState().auth.key;

  // Headers
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  // If token, add to headers config
  if (key) {
    config.headers["Authorization"] = `token ${key}`;
  }

  // const requestUrl = JSON.stringify(requestId)
  console.log(requestId);
  console.log(
    `${url}connections/users/connects/disconnect/${requestId}/`,
    config
  );

  axios
    .delete(`${url}connections/users/connects/disconnect/${requestId}/`, config)
    .then((res) => {
      dispatch({
        type: DISCONNECT_CONNECT_REQUEST,
        payload: res,
      });
      mixpanel.track("Connection removed", {
        requester_id: requestId,
        status: "removed connection",
      });
      console.log(res.data);
    })
    .catch((err) => {
      // dispatch(returnErrors("DoesNotExist", "400"));
      dispatch({ type: FAILED_CONNECT_REQUEST });
    });
};

// Accept Connection /connections/users/connects/accept/
export const acceptConnection = (requestId, alert) => (dispatch, getState) => {
  // Accepting Request State
  dispatch({ type: ACCEPTING_CONNECT_REQUEST });

  // Get Token from state
  const key = getState().auth.key;

  // Headers
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `token ${key}`,
    },
  };

  // If token, add to headers config
  if (key) {
    config.headers["Authorization"] = `Token ${key}`;
  }

  // const requestUrl = JSON.stringify(requestId)
  // console.log(requestId)
  console.log(
    `${url}connections/users/connectrequests/accept/${requestId}/`,
    {},
    config
  );

  axios
    .post(
      `${url}connections/users/connectrequests/accept/${requestId}/`,
      {},
      config
    )
    .then((res) => {
      dispatch({
        type: ACCEPTED_CONNECT_REQUEST,
        payload: res,
      });
      mixpanel.track("Connection Accepted", {
        requester_id: requestId,
        status: "accepted",
      });
      mixpanel.people.increment("goal_achieved");

      console.log(res.data);
      dispatch(loadRequests());
      alert();
    })
    .catch((err) => {
      dispatch(returnErrors(err?.response?.data, err?.response?.status));
      dispatch({ type: UNABLE_TO_ACCEPT_CONNECT_REQUEST });
      mixpanel.track("Error ocurred while accepting connection request", {
        error: err?.response?.data,
      });
      console.log(err?.response);
    });
};

export const searchUser = (search) => (dispatch, getState) => {
  dispatch({ type: FETCHING_SENT_REQUEST });

  // Get Token from state
  const key = getState().auth.key;

  // Headers
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  // If token, add to headers config
  if (key) {
    config.headers["Authorization"] = `Token ${key}`;
  }

  axios
    .get(`${url}users/?search=${search}`, config)
    .then((res) => {
      dispatch({
        type: SEARCH_USER,
        payload: res.data.results,
      });
      console.log(res.data);
    })
    .catch((err) => {
      console.log(err?.response);
    });
};
export const loadAllUsers =
  (page = 1, search = "", page_size = 12) =>
  (dispatch, getState) => {
    // console.log('userAll')
    // User Fetching State
    dispatch({ type: FETCHING_ALL_USERS });
    const key = getState().auth.key;
    const prevState = getState().connects.allUsers;
    // Get Token from state
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    // If token, add to headers config
    if (key) {
      config.headers["Authorization"] = `Token ${key}`;
    }

    axios
      .get(
        `${url}users/?search=${search}&page=${page}&page_size=${page_size}`,
        config
      )
      .then((res) => {
        console.log(res);
        if (page === 1) {
          dispatch({
            type: FETCHING_ALL_USERS_SUCCESS,
            payload: res.data.results,
          });
        }
        if (page > 1) {
          dispatch({
            type: FETCHING_ALL_USERS_SUCCESS,
            payload: prevState.concat(res.data.results),
          });
        }
      })
      .catch((err) => {
        console.log(err?.response);
      });
  };

// fetch mutual connections
export const fetchMutualConnections = (userId) => (dispatch, getState) => {
  const key = getState().auth.key;

  // Get Token from state
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  // If token, add to headers config
  if (key) {
    config.headers["Authorization"] = `Token ${key}`;
  }

  axios
    .get(`${url}connections/mutual/${userId}/`, config)
    .then((res) => {
      dispatch({
        type: FETCHING_MUTUAL_CONNECTIONS_SUCCESS,
        payload: res.data.results,
      });
    })
    .catch((err) => {
      console.log(err?.response);
    });
};
