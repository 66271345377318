// import axios from 'axios';
// import { UPDATING_PHOTO_TO_SHARE, UPDATED_PHOTO_TO_SHARE } from './types';
import { ADD_VIDEO_STORAGE, ADD_VIDEO_STORAGE_FALSE } from "./types";

export const videoUploadedStorage = (bool) => {
  return (dispatch) => {
    dispatch({
      type: ADD_VIDEO_STORAGE,
      isvideoUploaded: bool,
    });
  };
};
export const videoUploadedStorageFalse = (bool) => {
  return (dispatch) => {
    dispatch({
      type: ADD_VIDEO_STORAGE_FALSE,
      isvideoUploaded: bool,
    });
  };
};