import React, { useState } from "react";
import { Link, withRouter } from "react-router-dom";
import hamburger from "../../assets/images/sidebar/hamburger.png";
import { SidebarData } from "./SidebarData";
import "./styles/Navbar.css";
import logo from "../../assets/images/sidebar/toplogo.png";
import { BiChevronLeft } from "react-icons/bi";
import onClickOutside from "react-onclickoutside";
import { useHistory } from "react-router-dom";
import {connect, useDispatch} from "react-redux";
import { logout } from "../wm_actions/auth";
import logoutImg from "../../assets/images/sidebar/logout.png";
import { SearchUsers } from "../wm_actions/chat";
import {LOGOUT} from "../wm_actions/types";

function Navbar(props) {
	const history = useHistory();
	const dispatch = useDispatch()

	const [sidebar, setSidebar] = useState(false);
	const showSidebar = () => setSidebar(!sidebar);
	Navbar.handleClickOutside = () => setSidebar(false);
	return (
		<>
			<div className="TopNav"></div>
			<div className={props?.chatNav ? "navbar chatNav " : "navbar"}>
				{props?.chatNav ? (
					<div className="innerChatNav">
						<svg
							width="19"
							height="13"
							viewBox="0 0 19 13"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
							onClick={() => showSidebar()}
						>
							<path
								d="M0.125 7.52539V5.47461H12.625V7.52539H0.125ZM0.125 0.25H18.875V2.34961H0.125V0.25ZM0.125 12.75V10.6504H6.375V12.75H0.125Z"
								fill="white"
							/>
						</svg>
						<div>
							<label className="chatSearch">
								<svg
									width="20"
									height="20"
									viewBox="0 0 20 20"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										fill-rule="evenodd"
										clip-rule="evenodd"
										d="M12.4709 11.8791H13.1292L17.2875 16.0457L16.0459 17.2874L11.8792 13.1291V12.4707L11.6542 12.2374C10.7042 13.0541 9.47086 13.5457 8.12919 13.5457C5.13752 13.5457 2.71252 11.1207 2.71252 8.12907C2.71252 5.1374 5.13752 2.7124 8.12919 2.7124C11.1209 2.7124 13.5459 5.1374 13.5459 8.12907C13.5459 9.47074 13.0542 10.7041 12.2375 11.6541L12.4709 11.8791ZM4.37919 8.12907C4.37919 10.2041 6.05419 11.8791 8.12919 11.8791C10.2042 11.8791 11.8792 10.2041 11.8792 8.12907C11.8792 6.05407 10.2042 4.37907 8.12919 4.37907C6.05419 4.37907 4.37919 6.05407 4.37919 8.12907Z"
										fill="white"
									/>
								</svg>
								<input
									onChange={(e) => props?.SearchUsers(e.target.value)}
									className="chatSearchField"
								/>
							</label>
						</div>

						<svg
							onClick={() => history.push("/newChat")}
							className="addPhotoIcon"
							width="20"
							height="20"
							viewBox="0 0 20 20"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M15.8203 9.17969V7.5H12.5V4.17969H10.8203V7.5H7.5V9.17969H10.8203V12.5H12.5V9.17969H15.8203ZM16.6797 1.67969C17.1224 1.67969 17.5 1.84896 17.8125 2.1875C18.151 2.5 18.3203 2.8776 18.3203 3.32031V13.3203C18.3203 13.763 18.151 14.1536 17.8125 14.4922C17.5 14.8307 17.1224 15 16.6797 15H6.67969C6.23698 15 5.84635 14.8307 5.50781 14.4922C5.16927 14.1536 5 13.763 5 13.3203V3.32031C5 2.8776 5.16927 2.5 5.50781 2.1875C5.84635 1.84896 6.23698 1.67969 6.67969 1.67969H16.6797ZM3.32031 5V16.6797H15V18.3203H3.32031C2.8776 18.3203 2.48698 18.1641 2.14844 17.8516C1.83594 17.513 1.67969 17.1224 1.67969 16.6797V5H3.32031Z"
								fill="white"
							/>
						</svg>
					</div>
				) : (
					<div className="menu-bars">
						<img alt="" src={hamburger} onClick={() => showSidebar()} />
						<img alt="" src={logo} className="toplogo" />
						<div></div>
					</div>
				)}
			</div>
			<nav className={sidebar ? "nav-menu active" : "nav-menu"}>
				<ul className="nav-menu-items">
					<li className="navbar-toggle">
						<Link className="side-logo" to="profile">
							<img alt="" src={logo} />
							{/* <BiChevronLeft className='chevronleft' onClick={showSidebar} /> */}
						</Link>
						{/* <img alt='' src={hamburger} onClick={showSidebar} /> */}
					</li>
					{SidebarData.map((item, index) => {
						return (
							<li key={index} className={item.cName}>
								<Link to={item.path}>
									{item.icon}
									<span id="navSpan">{item.title}</span>
								</Link>
							</li>
						);
					})}
					<li
						onClick={() => {
							dispatch(LOGOUT);
						}}
						className="nav-text logoutbtn"
					>
						<Link>
							<img alt="" src={logoutImg} />
							<span id="navSpan">Logout</span>
						</Link>
					</li>
				</ul>
			</nav>
		</>
	);
}
export function NavbarConnect(props) {
	const history = useHistory();

	const [sidebar, setSidebar] = useState(false);
	const showSidebar = () => setSidebar(!sidebar);
	return (
		<>
			<div className={`navbar ${sidebar ? 'bg-auto' : 'bg-yellow-500'}`}>
				<Link to="/Home" className="menu-bars">
					<img alt="" src={hamburger} onClick={() => showSidebar()} />
					<div></div>
				</Link>
			</div>
			<nav className={sidebar ? "nav-menu active" : "nav-menu"}>
				<ul className="nav-menu-items">
					<li className="navbar-toggle">
						<Link to="/Home" className="side-logo">
							<img alt="" src={logo} />
							<BiChevronLeft
								className="chevronleft"
								onClick={() => showSidebar()}
							/>
						</Link>
					</li>
					{SidebarData.map((item, index) => {
						return (
							<li key={index} className={item.cName}>
								<Link to={item.path}>
									{item.icon}
									<span>{item.title}</span>
								</Link>
							</li>
						);
					})}
					<li
						onClick={() => {
							localStorage.clear();
							window.location.href = "/";
						}}
						className="nav-text logoutbtn"
					>
						<Link>
							<img alt="" src={logoutImg} />
							<span id="navSpan">Logout</span>
						</Link>
					</li>
				</ul>
			</nav>
		</>
	);
}

const clickOutsideConfig = {
	handleClickOutside: () => Navbar.handleClickOutside,
};
const mapStateToProps = (state) => ({});
export default connect(mapStateToProps, { logout, SearchUsers })(
	withRouter(onClickOutside(Navbar, clickOutsideConfig), NavbarConnect),
);
