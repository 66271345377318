import React, { Component } from "react";
import completeProfile from "../../../assets/images/Register/completeProfile-top.png";
// import "../styles/professionUpdateDesktop.css";
import { genreConstants } from "../../wm_constants/index.js";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { Link, Redirect } from "react-router-dom";
import { genreUpdateCall, onBoardingCall, loadUser } from "../../wm_actions/auth";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { BrowserView } from "react-device-detect";
import logoDesktop from "../../../assets/Logo-2.png";
import TabsVertical from "../TabsVertical";
import CircleLoader from "../../../loader/CircleLoader";

export class genreUpdate extends Component {
	state = {
		genre: "",
		checked: false,
		show: false,
		is_onboarded: true,
		onBoarded: false,
		reDirect: false
	};
	static propTypes = {
		genreUpdate: PropTypes.bool
	};
	async componentDidMount() {
		console.log(this.props.genre);
		console.log(this.props.onBoarded);
		this.props.loadUser();

		let timer = setInterval(
			() =>
				this.setState({
					reDirect: this.props.onBoardRedirect,
					onBoarded: this.props.onBoarded
				}),
			1000
		);
		let time = setInterval(() => console.log(`onBoarded : ${this.props.onBoardRedirect}`), 500);
		// clearInterval(time)
		setTimeout(() => {
			clearInterval(time);
			clearInterval(timer);
		}, 50000);
	}
	disabledLimit(id) {
		return this.state.genre.length > 2 && this.state.genre.indexOf(id) === -1;
	}
	nextBtn() {
		if (this.state.genre.length === 2) {
			this.setState({ show: true });
		} else {
			return null;
		}
	}
	onSubmit = (e) => {
		e.preventDefault();
		this.props.genreUpdateCall(this.state.genre);
		this.props.onBoardingCall(this.state.is_onboarded);
		this.props.loadUser();
	};
	render() {
		if (this.state.reDirect && this.state.onBoarded) {
			return <Redirect to="/Home" />;
		} else {
			return (
				<BrowserView>
					<div className="ProfessionGenreUpdateDesktop">
						<div className="ProfessionHeader">
							<img alt="WHIP MUSIC AFRICA" src={logoDesktop} />
						</div>
						<div className="ProfessionProfessionRow">
							<div className="ProfessionSectionOneProfession">
								<h1 className="ProfessionHeading">Welcome to Whip Music Africa</h1>
								<TabsVertical
									Data={[
										{ id: 1, name: "Personal Information", isActive: true },
										{ id: 2, name: "Professional Information", isActive: true },
										{ id: 3, name: "Music Interest", isActive: true, NoLine: true }
									]}
								/>
							</div>
							<div className="ProfessionSectionTwoProfession">
								<h4 className="ProfessionHeadingTwoProfession">
									What genre of music do you have interest in?
								</h4>
								<p className="ProfessionLabelProfession">Pick at most 3 genres</p>

								<div className="ProfessionFormProffesion">
									<form onSubmit={this.onSubmit}>
										<ul className="Professioncontainer" id="GridDesktop">
											{genreConstants.map((genre) => (
												<li className="ProfessioncheckboxDesktop">
													<input
														type="checkbox"
														id={genre.id}
														value={genre.id}
														name={genre.id}
														onChange={(e) => {
															let checked = e.target.checked;
															genreConstants.map((prof) => {
																if (genre.id === prof.id) {
																	prof.checked = checked;
																	this.nextBtn();
																}
																return prof.id;
															});
															if (e.target.checked === true) {
																this.setState((prevState) => ({
																	genre: [...prevState.genre, e.target.value]
																}));
															} else if (e.target.checked === false) {
																this.setState({
																	genre: this.state.genre.filter((genre) => {
																		return genre !== e.target.value;
																	})
																});
																return null;
															}
														}}
														checked={genre.checked}
														disabled={this.disabledLimit(genre.id)}
													/>
													<div>
														<label for={genre.id}>
															<p>{genre.name}</p>
														</label>
													</div>
												</li>
											))}
										</ul>
										{this.state.show && (
											<div className="ProfessionButtonDivProfession">
												<input
													className="ProfessionButtonProfession"
													type="submit"
													value="Finish"
												/>
											</div>
										)}
									</form>
								</div>
							</div>
						</div>
					</div>
				</BrowserView>
			);
		}
	}
}
const mapStateToProps = (state) => ({
	genre: state.auth.genre,
	genreUpdate: state.auth.genreUpdate,
	onBoarded: state.auth.onBoarded,
	onBoardRedirect: state.auth.onBoardRedirect
});

export default connect(mapStateToProps, { genreUpdateCall, onBoardingCall, loadUser })(genreUpdate);
