import React, { useState, useEffect } from "react";
import Navbar from "../wm_navigation/Navbar";
import { loadUser } from "../wm_actions/auth.js";
import { connect } from "react-redux";
import LocationOn from "@material-ui/icons/LocationOnOutlined";
import "./successfulApplication.css";
import { Button } from "@material-ui/core";
import BottomNav from "../wm_navigation/BottomNav";
import { Link, Redirect } from "react-router-dom";
import highfive from "../../assets/images/gigs/high_five.png";

function SuccessfulApplication() {
 
    return (
        <div>
      <div>
        <Navbar />
      </div>
     <div>
         <img className="mx-auto my-20" src={highfive} alt="" />
     </div>
         <div>
             <h3 className="font-bold text-lg text-blue-500">Application sent successfully!</h3>
             <Link to='/Home'>
             <button className="my-10 bg-blue-700 text-white  py-2 px-4 rounded">Go Back Home</button>
             </Link>
         </div>
        <div className="mt-24">
          <BottomNav />
        </div>
      </div>
    
    )
    }

const mapStateToProps = (state) => ({
  me: state.auth.me,
  authKey: state.posts.key,
});

export default connect(mapStateToProps, { loadUser })(SuccessfulApplication);
