import React, { useEffect, useState, memo } from "react";
import Navbar from "../wm_navigation/Navbar";
import axios from "axios";
import {loadUser} from "../wm_actions/auth.js";
import {connect} from "react-redux";
import {Link, useHistory} from "react-router-dom";
import moment from "moment";
import "./listGigs.css";
import LocationOn from "@material-ui/icons/LocationOnOutlined";
import microphone from "../../assets/images/gigs/microphone 1.png";
import profile from "../../assets/images/gigs/profiles 1.png";
import BounceLoader from "react-spinners/BounceLoader";
import BottomNav from "../wm_navigation/BottomNav";
import {config} from "../Endpoints";
import { selectAllGigs, setGigs as setAllGigs } from "../wm_reducers/gigSlice";
import { useSelector, useDispatch } from 'react-redux'

const url = config.url.API_URL;

const ListGigs = () => {
	// const [gigs, setGigs] = useState([]);
	const gigs = useSelector(selectAllGigs);
	// const [loading, setLoading] = useState(true);
	const dispatch = useDispatch()

	const history = useHistory();



	useEffect(() => {
		let isSubscribed = true;
		const api = axios.create({
			baseURL: `${url}gigs/`,
		});
		if (isSubscribed) {
			api.get("/").then((res) => {
				// console.log(res.data);
				// setLoading(false);
				// setGigs(res.data.results);
				dispatch(setAllGigs(res.data.results));
				console.log(gigs);
			});

		}
		return () => {
			isSubscribed = false;
		};
	}, [gigs]);

	//function for navigating to gig
	const navigateToGig = (gigId) => {
		history.push(`viewGig/${gigId}`);
	};

	if (!gigs) {
		return (
			<div className="loader-wrapper items-center">
				<BounceLoader color={"#34529E"} size={80} />
			</div>
		);
	} else {
		return (
			<div>
				<Navbar />
				<div className="flex justify-between my-10 px-4">
					<div>
						<Link to="/gigList">
							<button className="text-gray-600 p-3 rounded-full font-bold text-xs   border-none  active:text-blue-900 hover:bg-white hover:text-blue-900">
								All Gigs
							</button>
						</Link>
					</div>
					<div>
						<Link to="/postedGigs">
							<button className=" p-3 rounded-full font-bold text-xs text-gray-600  border-none  active:text-blue-800 hover:bg-white hover:text-blue-800">
								<div className="flex justify-between">
									<img className="px-3 object-cover hidden md:inline" src={microphone} alt="" />
									Posted Gigs
								</div>
							</button>
						</Link>
					</div>
					<div>
						<Link to="/gigApplications">
							<button className="text-gray-600 p-3 rounded-full font-bold text-xs   border-none  active:text-blue-800 hover:bg-white hover:text-blue-800">
								<div className="flex justify-between">
									<img className="px-3 object-cover hidden md:inline" src={profile} alt="" /> Gig
									Applications
								</div>
							</button>
						</Link>
					</div>
				</div>
				{gigs?.map((gig) => (
					<div
						key={gig.id}
						className="border bg-white flex h-36 max-h-36 shadow-md mx-2 rounded-sm my-3"
						onClick={() => navigateToGig(gig.id)}
					>
						<div className="w-1/3">
							<img
								className="object-cover h-full w-full  rounded-sm "
								src={gig.gig_banner}
								alt=""
							/>
						</div>
						<div className="flex-1 text-left pl-5 mt-3">
							<p className="font-bold text-sm">{gig?.gig_name}</p>
							<p className="gig-date my-1 text-sm">
								{moment(gig.gig_date) > moment()
									? moment(gig.gig_date).format("Do MMMM YYYY")
									: "Deadline passed"}
							</p>
							<p className=" text-gray-700  text-sm">
								<LocationOn className="pr-1"></LocationOn>
								{gig.gig_location_city}, {gig.gig_location_country}
							</p>
							{/* <p
                                className="text-xs pt-3 font-semibold text-blue-500"
                                key={gig.id}
                            >
                                {gig.gig_artists.length}{" "}
                                {gig.gig_artists.length === 1 ? "applicant" : "applicants"}
                            </p> */}
						</div>
					</div>
				))}
				<div className="mt-24">
					<BottomNav />
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	me: state.auth.me,
	authKey: state.posts.key,
});

export default connect(mapStateToProps, { loadUser })(React.memo(ListGigs));
