import React, {Component, useState} from 'react'
// import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import './nps.css';
import StarRatings from 'react-star-ratings';
import {NpsUpdate} from '../wm_actions/profile';

function NPS() {
    const [score, setScore] = useState(0)

    const changeRating = (talentRating, name) => {
        setScore(talentRating)
        console.log(score)
    }
    const onNpsUpdate = e => {
        this.props.NpsUpdate(score)
    }


    return (
        <div>
            <div>
                <p>Based on your experience, on a scale of 0 - 10 , how likely are you to recommend Whip Music to
                    people?</p>
                <div>
                    <StarRatings
                        rating={score}
                        starRatedColor="#cca20f"
                        changeRating={changeRating}
                        numberOfStars={10}
                        name='score'
                        starDimension='25px'
                        starHoverColor='#cca20f'
                        starEmptyColor='#666666'
                    />
                    <button onClick={(e) => {onNpsUpdate(e)}} id='ratingSubmitButton'>Submit
                    </button>
                </div>
            </div>
        </div>
    )

}

// v1/nps/create/

const mapStateToProps = (state) => ({})

export default connect(mapStateToProps, {NpsUpdate})(NPS)
