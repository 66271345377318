import React from "react";
import { BrowserView } from "react-device-detect";
import "./styles/loginDesktop.css";
import logoDesktop from "../../assets/Logo-2.png";
import { Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { login } from "../wm_actions/auth";
import ImageOne from "../../assets/images/auth/image";

class Login extends React.Component {
	state = {
		email: "",
		password: "",
		userId: ""
	};
	static propTypes = {
		login: PropTypes.func.isRequired,
		isAuthenticated: PropTypes.bool,
		users: PropTypes.array,
		profession: PropTypes.array,
		genre: PropTypes.array
	};
	onSubmit = (e) => {
		e.preventDefault();
		this.props.login(this.state.email, this.state.password);
	};
	onChange = (e) =>
		this.setState({
			[e.target.name]: e.target.value
		});
	render() {
		if (this.props.isAuthenticated) {
			return <Redirect to="/Home" />;
		} else {
			const { email, password } = this.state;
			return (
				<>
					<BrowserView>
						<div className="LoginLoginDesktop">
							<div className="LoginHeader">
								<img
									alt="WHIP MUSIC AFRICA"
									src={logoDesktop}
									height="250"
									width="250"
								/>
							</div>
							<div className="LoginRow">
								<div className="LoginSectionOne">
									<h1 className="LoginHeading">Hi there,</h1>
									<h4 className="LoginHeadingTwo">Nice to see you again!</h4>
									<div className="LoginSectionOneImage">
										<ImageOne />
									</div>
								</div>
								<div className="LoginSectionTwo">
									<div className="LoginForm">
										<form onSubmit={this.onSubmit}>
											<p className="LoginLabel">Email address</p>
											<input
												value={email}
												name="email"
												onChange={this.onChange}
												placeholder="Input your email address"
												className="LoginInput"
											/>
											<p className="LoginLabel">Password</p>
											<input
												type="password"
												onChange={this.onChange}
												value={password}
												name="password"
												placeholder="Enter your password"
												className="LoginInput"
											/>
											<div className="LoginForgot">
												<a href="https://server.whipafrica.com/v1/auth/account/password/reset/">
													{" "}
													Forgot Password ?
												</a>
											</div>
											{/* <Link to="/resetPassword" className="LoginForgot">
												<p>Forgot Password?</p>
											</Link> */}
											<input
												type="submit"
												value="Login"
												className="LoginButton"
											/>
											<p className="LoginSignup">
												New to Whip?{" "}
												<Link to="/register" className="LoginNewAccount">
													{" "}
													Create an account
												</Link>
											</p>
										</form>
									</div>
									<p className="LoginSignup" style={{ marginTop: "47px" }}>
										Do you have a problem signing in?{" "}
										<span className="LoginNewAccount">
											Reach out to support
										</span>
									</p>
								</div>
							</div>
						</div>
					</BrowserView>
				</>
			);
		}
	}
}

const mapStateToProps = (state) => ({
	isAuthenticated: state.auth.isAuthenticated,
	users: state.auth.users,
	profession: state.auth.profession,
	genre: state.auth.genre
});

export default connect(mapStateToProps, { login })(Login);
