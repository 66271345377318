import React from "react";
import { withAlert } from "react-alert";
import { connect } from "react-redux";
import PropTypes from "prop-types";

export class Alerts extends React.Component {
	static propTypes = {
		error: PropTypes.object.isRequired,
		message: PropTypes.object.isRequired,
	};
	componentDidMount() {
		if (window.navigator.onLine) {
			// this.props.alert.show("IT Works");
			console.log("IT Works");
		} else {
			this.props.alert.show("Offline Mode Enabled");
		}
	}
	componentDidUpdate(prevProps) {
		const { error, alert, message } = this.props;
		if (error !== prevProps.error) {
			console.log(prevProps.error, "adsds");
			if (error?.msg?.name) alert.error(`Name: ${error.msg.name.join()}`);
			if (error?.msg?.password1) {
				error?.msg?.password1?.map((a, i) => {
					alert.error(`Password: ${a}`);
				});
			}
			if (error?.msg?.email) {
				console.log("adsds");
				alert.error(`Email: ${error?.msg?.email.join()}`);
			}
			if (error?.msg?.message)
				alert.error(`Message: ${error?.msg?.message.join()}`);
			if (error?.msg?.non_field_errors)
				alert.error(error?.msg?.non_field_errors.join());
		}
		if (message !== prevProps.message) {
			if (message?.passwordNotMatch) alert.error(message?.passwordNotMatch);
			if (message) alert.error(message);
		}
	}

	render() {
		return <React.Fragment />;
	}
}

const mapStateToProps = (state) => ({
	error: state.errors,
	message: state.messages,
});

export default connect(mapStateToProps)(withAlert()(Alerts));
// Testing

// import React from "react";
// import { withAlert } from "react-alert";
// import { connect } from "react-redux";
// import PropTypes from "prop-types";

// export class Alerts extends React.Component {
// 	static propTypes = {
// 		error: PropTypes.object.isRequired,
// 		message: PropTypes.object.isRequired
// 	};
// 	componentDidMount() {
// 		if (window.navigator.onLine) {
// 			this.props.alert.show("IT Works");
// 		} else {
// 			this.props.alert.show("Offline Mode Enabled");
// 		}
// 	}
// 	componentDidUpdate(prevProps) {
// 		const { error, alert, message } = this.props;
// 		if (error !== prevProps.error) {
// 			if (error?.msg) {
// 				alert.error(`Error With Status Code: ${error.status}`);
// 			}
// 			// if (error?.msg?.password1) {
// 			// 	error?.msg?.password1?.map((a, i) => {
// 			// 		alert.error(`Password: ${a}`);
// 			// 	});
// 			// }
// 			if (error?.msg?.email) {
// 				alert.error(`Email: ${error?.msg?.email.join()}`);
// 			}
// 			if (error?.msg?.message) alert.error(`Message: ${error?.msg?.message.join()}`);
// 			if (error?.msg?.non_field_errors) alert.error(error?.msg?.non_field_errors.join());
// 		}
// 		if (message) {
// 			if (message?.passwordNotMatch) alert.error(message?.passwordNotMatch);
// 			if (message) {
// 				alert.show(message);
// 			}
// 		}
// 	}

// 	render() {
// 		return <React.Fragment />;
// 	}
// }

// const mapStateToProps = (state) => ({
// 	error: state.errors,
// 	message: state.messages
// });

// export default connect(mapStateToProps)(withAlert()(Alerts));
