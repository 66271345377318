export const SEARCH_USERS_CHAT = "SEARCH_USERS_CHAT";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const AUTH_ERROR = "AUTH_ERROR";
export const GET_ERRORS = "GET_ERRORS";
export const USER_LOADING = "USER_LOADING";
export const USER_LOADED = "USER_LOADED";
export const CREATE_MESSAGES = "CREATE_MESSAGES";
export const GET_POSTS = "GET_POSTS";
export const GET_POST = "GET_POST";

export const CONNECTION_REQUESTS_RECIEVED = "CONNECTION_REQUESTS_RECIEVED";
export const NUMBER_OF_LIKES = "NUMBER_OF_LIKES";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const PROFESSION_UPDATED = "PROFESSION_UPDATED";
export const GENRE_UPDATED = "GENRE_UPDATED";
export const PROFESSION_UPDATE_FAIL = "PROFESSION_UPDATE-FAIL";
export const GENRE_UPDATE_FAIL = "GENRE_UPDATE-FAIL";
export const CONNECTION_REQUESTS_FETCHING = "CONNECTION_REQUESTS_FETCHING";
export const CONNECTION_REQUESTS_FAILED = "CONNECTION_REQUESTS_FAILED";
export const CONNECT_REQUEST_SENDING = "CONNECT_REQUEST_SENDING";
export const CONNECT_REQUEST_SENT = "CONNECT_REQUEST_SENT";
export const FETCHING_CONNECTION_USERS = "FETCHING_CONNECTION_USERS";
export const FETCHING_ALL_USERS = "FETCHING_ALL_USERS";
export const FETCHING_USERS_FAILED = "FETCHING_USERS_FAILED";
export const FETCHING_USERS_SUCCESS = "FETCHING_USERS_SUCCESS";
export const SENDING_CONNECT_REQUEST = "SENDING_CONNECT_REQUEST";
export const SENT_CONNECT_REQUEST = "SENT_CONNECT_REQUEST";
export const FAILED_CONNECT_REQUEST = "FAILED_CONNECT_REQUEST";
export const PROFILE_LOADED = "PROFILE_LOADED";
export const NUMBER_OF_CONNECTS = "NUMBER_OF_CONNECTS";
export const FETCHING_NUMBER_OF_CONNECTS = "FETCHING_NUMBER_OF_CONNECTS";
export const GET_PERSONAL_POSTS = "GET_PERSONAL_POSTS";
export const GET_PERSONAL_VIDEOS = "GET_PERSONAL_VIDEOS";
export const GETTING_PROFILE_INFO = "GETTING_PROFILE_INFO";
export const GET_PROFILE_INFO = "GET_PROFILE_INFO";
export const FETCHING_SENT_REQUEST = "FETCHING_SENT_REQUEST";
export const SENT_REQUESTS_RECEIVED = "SENT_REQUESTS_RECEIVED";
export const FETCHING_USER_RECOMMENDATIONS = "FETCHING_USER_RECOMMENDATIONS";
export const USER_RECOMMENDATIONS = "USER_RECOMMENDATIONS";
export const FETCHING_OTHERS_RECOMMENDATIONS =
  "FETCHING_OTHERS_RECOMMENDATIONS";
export const OTHERS_RECOMMENDATIONS = "OTHERS_RECOMMENDATIONS";
export const FETCHING_USER_POSTS = "FETCHING_USER_POSTS";
export const USER_POSTS = "USER_POSTS";
export const ADD_VIDEO_STORAGE = "ADD_VIDEO_STORAGE";
export const ADD_VIDEO_STORAGE_FALSE = "ADD_VIDEO_STORAGE_FALSE";
export const FETCHING_USER_VIDEOS = "FETCHING_USER_VIDEOS";
export const USER_VIDEOS = "USER_VIDEOS";
export const OTHERS_RATINGS = "OTHERS_RATINGS";
export const FETCHING_OTHERS_RATINGS = "FETCHING_OTHERS_RATINGS";
export const DELETING_CONNECT_REQUEST = "DELETING_CONNECT_REQUEST";
export const DELETED_CONNECT_REQUEST = "DELETED_CONNECT_REQUEST";
export const SENT_RATINGS = "SENT_RATINGS";
export const MERGED_CONNECT_USERS = "MERGED_CONNECT_USERS";
export const UNABLE_TO_ACCEPT_CONNECT_REQUEST =
  "UNABLE_TO_ACCEPT_CONNECT_REQUEST";
export const ACCEPTING_CONNECT_REQUEST = "ACCEPTING_CONNECT_REQUEST";
export const ACCEPTED_CONNECT_REQUEST = "ACCEPTED_CONNECT_REQUEST";
export const UPDATING_PHOTO_TO_SHARE = "UPDATING_PHOTO_TO_SHARE";
export const UPDATED_PHOTO_TO_SHARE = "UPDATED_PHOTO_TO_SHARE";
export const FETCHING_CHAT = "FETCHING_CHAT";
export const FETCHED_CHAT = "FETCHED_CHAT";
export const FETCH_CHAT_FAILED = "FETCH_CHAT_FAILED";
export const IMG_UPLOADED = "IMG_UPLOADED";
export const IMG_UPLOADING = "IMG_UPLOADING";
export const ONBOARDED = "ONBOARDED";
export const FETCHING_PROFILE_STATUS = "FETCHING_PROFILE_STATUS";
export const FETCHED_PROFILE_STATUS = "FETCHED_PROFILE_STATUS";
export const COLLABORATION_SUCCESS = "COLLABORATION_SUCCESS";
export const EVENT_BOOKING_SUCCESS = "EVENT_BOOKING_SUCCESS";
export const RECORD_LABEL_SUCCESS = "RECORD_LABEL_SUCCESS";
export const COLLABORATION_FAILED = "COLLABORATION_FAILED";
export const EVENT_BOOKING_FAILED = "EVENT_BOOKING_FAILED";
export const RECORD_LABEL_FAILED = "RECORD_LABEL_FAILED";
export const LOGOUT = "LOGOUT";
export const NPS_CREATE_SUCCESS = "NPS_CREATE_SUCCESS";
export const NPS_CREATE_FAILED = "NPS_CREATE_FAILED";
export const SEARCH_USER = "SEARCH_USER";
export const GET_VIDEO = "GET_VIDEO";
export const FETCHING_ALL_USERS_SUCCESS = "FETCHING_ALL_USERS_SUCCESS";
export const DISCONNECT_CONNECT_REQUEST = "DISCONNECT_CONNECT_REQUEST";
export const CREATE_GET_ROOM_CHAT = "CREATE_GET_ROOM_CHAT";
export const SEND_MESSAGE_CHAT = "SEND_MESSAGE_CHAT";
export const CHAT_HISTORY = "CHAT_HISTORY";
export const CURRENT_CHAT = "CURRENT_CHAT";
export const FETCH_CURRENT_CHAT = "FETCH_CURRENT_CHAT";
export const NOTIFICATION_ERROR = "NOTIFICATION_ERROR";
export const NOTIFICATION_LOADED = "NOTIFICATION_LOADED";
export const NOTIFICATION_LOADING = "NOTIFICATION_LOADING";
export const POST_LOADING = "POST_LOADING";
export const USER_CONNECT_LOADING = "USER_CONNECT_LOADING";

export const FETCHING_MUTUAL_CONNECTIONS_SUCCESS =
  "FETCHING_MUTUAL_CONNECTIONS_SUCCESS";
export const FETCHING_MUTUAL_CONNECTIONS = "FETCHING_MUTUAL_CONNECTIONS";
export const FETCHING_MUTUAL_CONNECTIONS_FAILED =
  "FETCHING_MUTUAL_CONNECTIONS_FAILED";
export const FETCH_SINGLE_GIG = "FETCH_SINGLE_GIG";
export const FETCH_SINGLE_GIG_CLEAR = "FETCH_SINGLE_GIG_CLEAR";
export const FACEBOOK = "FACEBOOK";
export const TWITTER = "TWITTER";
export const INSTAGRAM = "INSTAGRAM";
export const TIKTOK = "TIKTOK";
export const STREAMINGLINKS = "STREAMINGLINKS";

export const ARTIST_CAPTION = "ARTIST_CAPTION";
export const PHONE_NUMBER = "PHONE_NUMBER";
export const ARTIST_VIDEO = "ARTIST_VIDEO";

// export const CHAT_HISTORY = "CHAT_HISTORY";
export const LIKE_POST = "LIKE_POST";
export const UNLIKE_POST = "UNLIKE_POST";
export const SUBMIT_COMMENT = "SUBMIT_COMMENT";
