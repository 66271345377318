import React from "react";
import { getUserPosts } from "../../wm_actions/profile";
import { postTextLike, postImageLike, postVideoLike } from "../../wm_actions/posts";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import { Link } from "react-router-dom";
import { professionConstants } from "../../wm_constants/index";
import like from "../../../assets/images/Posts/like.png";
import comment from "../../../assets/images/Posts/comment.png";
import share from "../../../assets/images/Posts/share.png";
import CommentSystem from "../../wm_posts/component/comments";
import CircleLoader from "../../../loader/CircleLoader";

class MyPosts extends React.Component {
	static propTypes = {
		userPosts: PropTypes.array,

		person: PropTypes.array
	};

	onText = (e) => {
		this.setState({
			postId: e
		});
		this.t = setTimeout(() => this.props.postTextLike(this.state.postId), 200);
	};
	onVideo = (e) => {
		this.setState({
			postId: e
		});
		this.t = setTimeout(() => this.props.postVideoLike(this.state.postId), 200);
	};
	onImage = (e) => {
		this.setState({
			postId: e
		});
		this.t = setTimeout(() => this.props.postImageLike(this.state.postId), 200);
	};
	onSubmit = (e, pid) => {
		e.preventDefault();
	};
	commentSystemToggle = () => {
		this.setState({
			commentSystem: !this.state.commentSystem
		});
	};
	render() {
		if (this.props.userPosts == []) {
			return <CircleLoader />;
		} else {
			return (
				<div>
					{this.props.userPosts.results
						.sort((a, b) =>
							b.created_at < a.created_at
								? -1
								: b.created_at > a.created_at
								? 1
								: 0,
						)
						.map((post) => (
							<Card className="root" key={post.id}>
								<CardHeader
									avatar={
										<Avatar
											aria-label="recipe"
											className="avatar"
											src={this.props.person.profile.avatar}
										></Avatar>
									}
									title={this.props.person.name}
									subheader={professionConstants.map((proC) => (
										<div>
											<p>
												{proC.id === this.props.person.profile.profession[0]
													? proC.name
													: null}
											</p>
											<p>
												{proC.id === this.props.person.profile.profession[1]
													? proC.name
													: null}
											</p>
										</div>
									))}
								/>
								<CardContent id="cardContent">
									<Typography id="textPostContent">{post.caption}</Typography>
									{post.post_type === "image" && (
										<img id="ImagePostContent" src={post.url} alt="" />
									)}
									{post.post_type === "video" && (
										<video width="320" height="240" controls>
											<source src={post.url} type="video/mp4" />
										</video>
									)}
								</CardContent>
								{/* <div id="reaction-wrapper">
									<form id="reactionForm">
										<div id="reactions">
											<div id="likeWrap">
												<button type="submit" id="post-like-btn">
													<img src={like} />
												</button>
												<p>132 likes</p>
											</div>
										</div>
										<div id="reactions">
											<div id="commentWrap">
												<button type="submit" id="post-like-btn">
													<img alt="" src={comment} />
												</button>
												<p>1223 Comments</p>
											</div>
										</div>
										<div id="reactions">
											<div id="shareWrap">
												<button type="submit" id="post-like-btn">
													<img alt="" src={share} />
												</button>
												<p>2312 Shares</p>
											</div>
										</div>
									</form>
								</div> */}
								<div id="reaction-wrapper">
									<form onSubmit={this.onSubmit} id="reactionForm">
										<div id="reactions">
											{post?.hasOwnProperty("video_url") ? (
												<div id="likeWrap">
													<button
														name="postId"
														value={post?.id}
														onClick={() => this.onVideo(post?.id)}
														id="post-like-btn"
														type="submit"
													>
														<img alt="" className="LSCButtons" src={like} />
													</button>
													<p>{post?.likes} likes</p>
												</div>
											) : post?.hasOwnProperty("image_url") ? (
												<div id="likeWrap">
													<button
														name="postId"
														value={post?.id}
														onClick={() => this.onImage(post?.id)}
														type="submit"
														id="post-like-btn"
													>
														<img alt="" className="LSCButtons" src={like} />
													</button>
													<p>{post?.likes} likes</p>
												</div>
											) : post?.hasOwnProperty("text_body") ? (
												<div id="likeWrap">
													<button
														onClick={() => this.onText(post?.id)}
														type="submit"
														id="post-like-btn"
													>
														<img src={like} />
													</button>
													<p>{post?.likes} likes</p>
												</div>
											) : null}
										</div>
										<div id="reactions">
											{post?.hasOwnProperty("video_url") ? (
												<div id="commentWrap">
													<button
														name="postId"
														value={post?.id}
														onClick={() =>
															this.setState({ commentSystem: post?.id })
														}
														id="post-like-btn"
														type="submit"
													>
														<img alt="" className="LSCButtons" src={comment} />
													</button>
													<p>{post?.VideoComments.length} Comments</p>
												</div>
											) : post?.hasOwnProperty("image_url") ? (
												<div id="commentWrap">
													<button
														name="postId"
														value={post?.id}
														onClick={() =>
															this.setState({ commentSystem: post?.id })
														}
														type="submit"
														id="post-like-btn"
													>
														<img alt="" className="LSCButtons" src={comment} />
													</button>
													<p>{post?.ImageComments.length} Comments</p>
												</div>
											) : post?.hasOwnProperty("text_body") ? (
												<div id="commentWrap">
													<button
														onClick={() =>
															this.setState({ commentSystem: post?.id })
														}
														type="submit"
														id="post-like-btn"
													>
														<img alt="" className="LSCButtons" src={comment} />
													</button>
													<p>{post?.TextComments.length} Comments</p>
												</div>
											) : null}
										</div>
										<div id="reactions">
											{post?.hasOwnProperty("video_url") ? (
												<div id="shareWrap">
													<button
														name="postId"
														value={post?.id}
														onClick={() => this.onVideo(post?.id)}
														id="post-like-btn"
														type="submit"
													>
														<img alt="" className="LSCButtons" src={share} />
													</button>
													<p>{post?.VideoComments.length} Shares</p>
												</div>
											) : post?.hasOwnProperty("image_url") ? (
												<div id="shareWrap">
													<button
														name="postId"
														value={post?.id}
														onClick={() => this.onImage(post?.id)}
														type="submit"
														id="post-like-btn"
													>
														<img alt="" className="LSCButtons" src={share} />
													</button>
													<p>{post?.ImageComments.length} Shares</p>
												</div>
											) : post?.hasOwnProperty("text_body") ? (
												<div id="shareWrap">
													<button
														onClick={() => this.onText(post?.id)}
														type="submit"
														id="post-like-btn"
													>
														<img alt="" className="LSCButtons" src={share} />
													</button>
													<p>{post?.TextComments.length} Shares</p>
												</div>
											) : null}
										</div>
									</form>
								</div>
								{this.state?.commentSystem == post?.id ? (
									<CommentSystem />
								) : null}
							</Card>
						))}
				</div>
			);
		}
	}
}
const mapStateToProps = (state) => ({
	userPosts: state.profile.userPosts,
	person: state.profile.person
});
export default connect(mapStateToProps, {
	getUserPosts,
	postTextLike,
	postImageLike,
	postVideoLike
})(MyPosts);
