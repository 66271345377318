import axios from "axios";
import { returnErrors } from "./messages";
import {
  FETCHING_CHAT,
  FETCHED_CHAT,
  FETCH_CHAT_FAILED,
  CREATE_GET_ROOM_CHAT,
  CHAT_HISTORY,
  SEND_MESSAGE_CHAT,
  FETCH_CURRENT_CHAT,
  CURRENT_CHAT,
  SEARCH_USERS_CHAT,
} from "./types";

import { config } from "../Endpoints";

const url = config.url.API_URL;

// Fetching All
export const fetchChat = () => (dispatch, getState) => {
  dispatch({ type: FETCHING_CHAT });
  const key = getState().auth.key;
  const id = localStorage.getItem("userId");

  // Headers
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  // If token, add to headers config
  if (key) {
    config.headers["Authorization"] = `Token ${key}`;
  }
  const result = [];
  let grouping = (list) =>
    list.forEach(
      (function (hash) {
        return function (a) {
          if (!hash[a.receiver.id]) {
            hash[a.receiver.id] = {
              id: a.receiver.id,
              message: [],
              displayMessage: [],
              name: a.receiver.name,
              avatar: a.receiver.profile.url,
            };
            result.push(hash[a.receiver.id]);
            console.log(a.receiver.id);
          }
          hash[a.receiver.id].message.push({ message: a.message });
          hash[a.receiver.id].displayMessage.push(a.message);
          // hash[a.receiver.id].info.push({avatar: a.receiver.profile.url, name: a.receiver.name })
        };
      })(Object.create(null))
    );
  const sentChat = axios.get(`${url}chat/messages/`, config);
  const receivedChat = axios.get(`${url}chat/messages/received/`, config);
  axios
    .all([sentChat, receivedChat])
    .then(
      axios.spread((...res) => {
        const sentRes = res[0].data.results;
        const receivedRes = res[1].data.results;
        const mergedRes = sentRes.concat(receivedRes);
        grouping(mergedRes);
        dispatch({
          type: FETCHED_CHAT,
          payload: result,
        });
        console.log(result);
      })
    )
    .catch((err) => {
      dispatch(returnErrors(err?.response?.data, err?.response?.status));
      dispatch({
        type: FETCH_CHAT_FAILED,
      });
    });
};

// Create a chat room or get existing chat room
export const CreateRoomChat =
  (requestId, msg, clearState, isMsg) => (dispatch, getState) => {
    // Deleting Request State
    dispatch({ type: CREATE_GET_ROOM_CHAT });

    // Get Token from state
    const key = getState()?.auth?.key;

    var config = {
      method: "post",
      url: `${url}talk/threads/${requestId}`,
      headers: {
        Authorization: `token ${key}`,
        "Content-Type": "application/json",
      },
      // data: data
    };

    axios(config)
      .then((res) => {
        dispatch({
          type: CREATE_GET_ROOM_CHAT,
          payload: res.data,
        });
        // console.log(res.data);
        if (isMsg) {
          dispatch(
            SendMessageChat({
              message: msg,
              to: requestId,
            })
          );
        }
        clearState();
      })
      .catch((err) => {
        dispatch(returnErrors(err?.response?.data, err?.response?.status));
        dispatch({ type: FETCH_CHAT_FAILED });
        console.log(err?.response);
      });
  };

// **Send a Message endpoint**
export const SendMessageChat = (data, clearState) => (dispatch, getState) => {
  // Deleting Request State
  console.log(data);
  dispatch({ type: SEND_MESSAGE_CHAT });

  // Get Token from state
  const key = getState()?.auth?.key;

  var config = {
    method: "post",
    url: `${url}talk/messages`,
    headers: {
      Authorization: `token ${key}`,
      "Content-Type": "application/json",
    },
    data: data,
  };

  axios(config)
    .then((res) => {
      dispatch({
        type: SEND_MESSAGE_CHAT,
        payload: res,
      });
      clearState();
    })
    .catch((err) => {
      dispatch(returnErrors(err?.response?.data, err?.response?.status));
      dispatch({ type: FETCH_CHAT_FAILED });
      console.log(err?.response);
    });

  // // Headers
  // const config = {
  // 	headers: {
  // 		"Content-Type": "application/json"
  // 	},
  // 	body: userObj
  // };

  // // If token, add to headers config
  // if (key) {
  // 	config.headers["Authorization"] = `token ${key}`;
  // }

  // // const requestUrl = JSON.stringify(requestId)
  // console.log(userObj);
  // console.log(`${url}talk/messages`, config);

  // axios
  // 	.post(`${url}talk/messages`, config)
  // 	.then((res) => {
  // 		dispatch({
  // 			type: SEND_MESSAGE_CHAT,
  // 			payload: res
  // 		});
  // 		console.log(res.data);
  // 		dispatch(CurrentChats())
  // 	})
  // 	.catch(({response}) => {
  // 		dispatch(returnErrors(err?.response?.data, err?.response?.status));
  // 		dispatch({ type: FETCH_CHAT_FAILED });
  // 			console.log(err?.response)
  // 	});
};

// Create a chat room or get existing chat room
export const ChatHistory = (userId) => (dispatch, getState) => {
  // Deleting Request State
  dispatch({ type: CHAT_HISTORY });

  // Get Token from state
  const key = getState().auth.key;

  // Headers
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  // If token, add to headers config
  if (key) {
    config.headers["Authorization"] = `token ${key}`;
  }

  axios
    .get(
      `https://chat-micro-dot-whip-mvp.appspot.com/v1/talk/threads/${userId}`,
      config
    )
    .then((res) => {
      dispatch({
        type: CHAT_HISTORY,
        payload: res,
      });
      console.log(res.data);
    })
    .catch((err) => {
      dispatch(returnErrors(err?.response?.data, err?.response?.status));
      dispatch({ type: FETCH_CHAT_FAILED });
      console.log(err?.response);
    });
};

// Create a chat room or get existing chat room
export const CurrentChats = (userId) => (dispatch, getState) => {
  // Deleting Request State
  dispatch({ type: CURRENT_CHAT });

  // Get Token from state
  const key = getState()?.auth?.key;

  // Headers
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  // If token, add to headers config
  if (key) {
    config.headers["Authorization"] = `token ${key}`;
  }

  axios
    .get(`${url}talk/conversations`, config)
    .then((res) => {
      dispatch({
        type: CURRENT_CHAT,
        payload: res.data,
      });
      console.log(res.data);
    })
    .catch((err) => {
      dispatch(returnErrors(err?.response?.data, err?.response?.status));
      dispatch({ type: FETCH_CURRENT_CHAT });
      console.log(err?.response);
    });
};

export const SearchUsers = (value) => (dispatch, getState) => {
  dispatch({
    type: SEARCH_USERS_CHAT,
    payload: { value },
  });
};
