/* eslint-disable */
import { combineReducers } from "redux";
import errors from "./errors";
import auth from "./auth";
import messages from "./messages";
import posts from "./posts";
import connects from "./connects";
import profile from "./profile";
import chat from "./chat";
import share from "./share";
// import gigs from "./gigs";
import { artistApplicationReducer } from "./gig_application";
import gigReducer from "./gigSlice";
// import leads from '.'
export default combineReducers({
  errors,
  auth,
  messages,
  posts,
  connects,
  profile,
  chat,
  share,
  artistApplicationReducer,
  gigs: gigReducer,

});
