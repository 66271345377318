import React from "react";
import { FlutterWaveButton, closePaymentModal } from "flutterwave-react-v3";
import toplogo from "../../assets/images/sidebar/toplogo.png";
import "./premium.css";
import check from "../../assets/check.png";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { loadUser } from "../wm_actions/auth.js";
import axios from "axios";
import { config } from "../Endpoints";

const url = config.url.API_URL;

class Premium extends React.Component {
  static propTypes = {
    me: PropTypes.array,
  };
  async componentDidMount() {
    await this.props.loadUser();
  }

  config = {
    public_key: "FLWPUBK_TEST-2c273244657524fcc9c605984e7fc65d-X",
    tx_ref: Date.now(),
    amount: 71.88,
    currency: "USD",
    payment_options: "card,mobilemoney,ussd",
    payment_plan: 11795,
    customer: {
      email: this?.props?.me[0]?.email,
      name: this?.props?.me[0]?.name,
    },
    customizations: {
      title: "My store",
      description: "Payment for items in cart",
      logo: "https://st2.depositphotos.com/4403291/7418/v/450/depositphotos_74189661-stock-illustration-online-shop-log.jpg",
    },
  };
  config2 = {
    public_key: "FLWPUBK_TEST-2c273244657524fcc9c605984e7fc65d-X",
    tx_ref: Date.now(),
    amount: 5.99,
    currency: "USD",
    payment_options: "card,mobilemoney,ussd",
    payment_plan: 11794,
    customer: {
      email: this?.props?.me?.[0]?.email,
      name: this?.props?.me?.[0]?.name,
    },
    customizations: {
      title: "My store",
      description: "Payment for items in cart",
      logo: "https://st2.depositphotos.com/4403291/7418/v/450/depositphotos_74189661-stock-illustration-online-shop-log.jpg",
    },
  };
  monthlyPlan = {
    amount: 5.99,
    name: "Church collections plan",
    interval: "monthly",
    duration: 48,
  };

  fwConfig = {
    ...this.config,
    text: `$71.88 Pay Yearly `,
    className: "premiumFirstBtn",
    callback: (response) => {
      console.log(response);
      if (response.status === "successful") {
        this.props.history.push("/success");
        console.log("Successfully Login");
      }
      closePaymentModal(); // this will close the modal programmatically
    },
    onClose: () => {},
  };
  fwConfig2 = {
    ...this.config2,
    text: `$5.99 Pay Monthly `,
    className: "premiumSecondBtn",
    callback: (response) => {
      console.log(response);
      closePaymentModal(); // this will close the modal programmatically
    },
    onClose: () => {},
  };
  handleSub = (plan) => (event) => {
    event.preventDefault();
    const { authKey } = this.props;
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${authKey}`,
      },
    };
    axios
      .post(
        `${url}payments/process-payment-${plan}/`,
        {
          name: this?.props?.me?.[0]?.name,
          email: this?.props?.me?.[0]?.email,
          plan: plan,
          id: this?.props?.me?.[0]?.id,
        },
        config
      )
      .then((res) => {
        if (res.status === 200) {
          window.location.href = res.data.data.link;
          console.log(res.data.data.link);
        }
      });
  };
  render() {
    return (
      <div className="App">
        <div id="premiumWrapper">
          <img src={toplogo} />
          <div id="premiumPlans">
            <h1 id="premiumPlanHeading">Premium</h1>
            <h6 id="premiumHeadSubline">Advanced Features For Users</h6>
            <div id="premiumHeadPriceWrapper">
              <h1 id="premiumHeadPricing">
                5.99 USD <span>per month</span>
              </h1>
              <h1 id="premiumWhatYouGet">What you get</h1>
            </div>
            <ul id="premiumUl">
              <li>
                <img src={check} />
                Unlimited number of videos
              </li>
              <li>
                <img src={check} />
                Up to one minute video
              </li>
              <li>
                <img src={check} />
                Optimized user profile
              </li>
              <li>
                <img src={check} />
                Access to Whip charts
              </li>
              <li>
                <img src={check} />
                In Mail access
              </li>
              <li>
                <img src={check} />
                Integration of streaming and social media platforms
              </li>
            </ul>
            <div id="premiumPayBtnsWrapper">
              {/* <FlutterWaveButton {...this.fwConfig} />
              <FlutterWaveButton {...this.fwConfig2} /> */}
              <form onSubmit={this.handleSub("yearly")}>
                <button className="premiumFirstBtn" type="submit">
                  71.88usd Pay Yearly
                </button>
              </form>
              <form onSubmit={this.handleSub("monthly")}>
                <button className="premiumSecondBtn" type="submit">
                  5.99usd Pay Monthly
                </button>
              </form>
            </div>
          </div>
          <div id="premiumCustomPlan">
            <h1 id="premiumCustomHeading">Gold</h1>
            <p id="premiumCustomSub">
              Create a custom solution that fully meets your needs
            </p>
            <div id="premiumContactWrapper">
              <h1 id="premiumContactHeading">Custom</h1>
              <p id="premiumContactEmail">Email us at admin@africa.com</p>
            </div>
            <button id="premiumSendEmailBtn">Send Email To Admin</button>
          </div>
          <div>
            <h1 className="premFaq">Frequently Asked Questions</h1>
            <div>
              <Accordion className="premFaqAcc">
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>How will you bill me?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Suspendisse malesuada lacus ex, sit amet blandit leo
                    lobortis eget.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion className="premFaqAcc">
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>What is your refund policy?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Suspendisse malesuada lacus ex, sit amet blandit leo
                    lobortis eget.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion className="premFaqAcc">
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>Can I change or cancel my plan later?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Suspendisse malesuada lacus ex, sit amet blandit leo
                    lobortis eget.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion className="premFaqAcc">
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>What features can I use for free?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Suspendisse malesuada lacus ex, sit amet blandit leo
                    lobortis eget.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion className="premFaqAcc">
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>Do I get a discount if I pay yearly?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Suspendisse malesuada lacus ex, sit amet blandit leo
                    lobortis eget.
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </div>
          </div>
        </div>
        {/* <FlutterWaveButton {...fwConfig} /> */}
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  me: state.auth.me,
  authKey: state.posts.key,
});
export default connect(mapStateToProps, {
  loadUser,
})(Premium);
