import React, { useState } from "react";
import Navbar from "../wm_navigation/Navbar";
import { Button } from "@material-ui/core";
import "./gig.css";
import axios from "axios";
import { loadUser } from "../wm_actions/auth.js";
import { connect } from "react-redux";
import {
	genreConstants,
	gigCountryConstants,
	kenyaCitiesConstants,
	nigeriaCitiesConstants,
	southafricaCitiesConstants,
} from "../wm_constants";
import BottomNav from "../wm_navigation/BottomNav";
import { Redirect } from "react-router-dom";
import upload from "../../assets/images/gigs/upload.png";
import { config } from "../Endpoints";
import moment from "moment";

const url = config.url.API_URL;
function CreateGig(props) {
	const selectedCity = [
		kenyaCitiesConstants,
		nigeriaCitiesConstants,
		southafricaCitiesConstants,
	];

	const [banner, setBanner] = useState("");
	const [name, setName] = useState("");
	const [number_of_artists, setArtistsNumber] = useState("");
	const [date, setDate] = useState("");
	const [genre, setGenre] = useState("");
	const [city, setCity] = useState("");
	const [country, setCountry] = useState("");
	const [cities_in_country, setCitiesInCountry] = useState([]);
	const [additional_info, setAdditionalInfo] = useState("");
	const [selected_city_id, setNumber] = useState(0);
	const [file, setFile] = useState(null);
	const [redirect, setRedirect] = useState();
	const [display, setDisplay] = useState(null);

	const handleSubmit = (e) => {
		e.preventDefault();
		const { authKey } = props;
		console.log(authKey);
		console.log(props);

		const config = {
			headers: {
				Authorization: `Token ${authKey}`,
				"Content-Type": "application/json",
			},
		};
		console.log(
			banner,
			name,
			city,
			country,
			genre,
			additional_info,
			number_of_artists,
			date,
		);

		const data = new FormData();
		let gigDate = moment(date).format();

		// TODO extract to data formatter
		data.append("gig_banner", file);
		data.append("gig_name", name);
		data.append("gig_location_city", city);
		data.append("gig_location_country", country);
		data.append("gig_date", gigDate);
		data.append("gig_additional_info", additional_info);
		data.append("gig_genre", genre);
		data.append("number_of_artists", number_of_artists);
		console.log(data);
		try {
			axios.post(`${url}gigs/`, data, config).then(setRedirect("/gigList"));
		} catch (err) {
			console.log(err);
		}
	};

	let onUpload = (e) => {
		const newFile = e.target.files[0];
		setFile(newFile);
		setDisplay(URL.createObjectURL(e.target.files[0]));
	};

	let onCityChange = (e) => {
		console.log(e.target.value);
		setCity(e.target.value);
	};

	let onCountryChange = (e) => {
		const countryName = e.target.value;
		const cities = gigCountryConstants
			.filter((item) => item.name === countryName)
			.map((country) => {
				setNumber(country.i);

				return country.cities;
			});
		setCountry(countryName);
		console.log(selected_city_id);
		setCitiesInCountry(cities);
		console.log("After", cities_in_country);
	};
	if (redirect) {
		return <Redirect push to={redirect} />;
	}

	return (
		<div>
			<Navbar />
			<p className="pt-4 text-sm font-bold">Please enter details of the gig</p>
			<form onSubmit={handleSubmit} className="p-4">
				{display ? (
					<img
						className="gig-banner-preview shadow-xl rounded"
						src={display}
						alt=""
					/>
				) : (
					<div className="flex items-center justify-center w-full h-full">
						<label className="my-5 flex flex-col w-full border-4 border bg-gray-300">
							<div className="flex flex-col  justify-center pt-7 items-center h-1/4">
								<div className="my-6 items-center justify-center">
									<span>
										<img
											className="upload-icon w-12 mx-auto"
											src={upload}
											alt=""
										/>
									</span>
									<p className="pt-1 text-sm tracking-wider text-gray-500 group-hover:text-gray-600 font-bold">
										Upload Gig Banner
									</p>
								</div>
							</div>
							<input
								className="opacity-0 py-2"
								name="banner"
								type="file"
								id="banner"
								accept="image/*"
								onChange={onUpload}
							/>
						</label>
					</div>
				)}

				<div className="flex flex-row items-center justify-between w-full">
					<div className="flex flex-col items-start">
						<label className="text-xs" htmlFor="gig_name">
							Gig Name
						</label>
						<input
							className="py-2 text-sm pl-4"
							name="name"
							type="text"
							id="gig_name"
							placeholder=""
							value={name}
							onChange={(e) => setName(e.target.value)}
						/>
					</div>
					<div className="flex flex-col items-start">
						<label className="text-xs" htmlFor="number_of_artists">
							Number of artists
						</label>
						<input
							className="py-2  text-sm pl-4"
							name="number_of_artists"
							type="number"
							id="number_of_artists"
							placeholder=""
							value={number_of_artists}
							onChange={(e) => setArtistsNumber(e.target.value)}
						/>
					</div>
				</div>
				<div className="flex flex-row  items-center justify-between my-5">
					<div className="flex flex-col items-start">
						<label className="text-xs" htmlFor="date">
							Date
						</label>
						<input
							className="py-2 text-xs pl-5 uppercase"
							type="date"
							name="date"
							id="date"
							value={date}
							onChange={(e) => setDate(e.target.value)}
						/>
					</div>
					<div className="flex flex-col items-start">
						<label className="text-xs" htmlFor="genre">
							Genre
						</label>
						<select
							className="py-2 text-sm  pl-4"
							name="genre"
							id="genre"
							value={genre}
							onChange={(e) => setGenre(e.target.value)}
						>
							<option value=""></option>
							{genreConstants.map((k) => (
								<option value={k.id}>{k.name}</option>
							))}
						</select>
						<div />
					</div>
				</div>
				<div className="flex flex-row  items-center justify-between w-full">
					<div className="flex flex-col items-start">
						<p className="text-sm font-bold">Location</p>
						<label className="text-sm" htmlFor="country"></label>
						<select
							className="py-2 text-xs pl-5"
							name="country"
							id="country"
							value={country}
							onChange={onCountryChange}
						>
							<option className="text-xs pl-4" value="">
								Country
							</option>
							{gigCountryConstants.map((k) => (
								<option value={k.name} key={k.id}>
									{k.name}
								</option>
							))}
						</select>
					</div>
					<div className="flex flex-col items-start">
						<label className="text-sm" htmlFor="city"></label>
						<select
							className="py-2 text-xs pl-5 mt-8"
							name="city"
							id="city"
							value={city}
							onChange={onCityChange}
						>
							<option value="">City</option>
							{selectedCity[selected_city_id].map((item) => (
								<option value={item.id}>{item.name}</option>
							))}
						</select>
					</div>
				</div>

				<div className="flex flex-col pt-5">
					<label
						className="my-1 text-sm items-start mr-auto"
						htmlFor="additional_info"
					>
						Additional Information
					</label>
					<textarea
						className="py-2 text-sm pl-5"
						rows="5"
						placeholder="Type here"
						name="additional_info"
						id="additional_info"
						value={additional_info}
						onChange={(e) => setAdditionalInfo(e.target.value)}
					/>
				</div>
				<div className="postgig-btn-wrapper content-center mb-12">
					<Button
						className="gig-btn font-bold py-2 normal-case"
						type="submit"
						variant="contained"
						size="large"
						style={{ textTransform: "none" }}
					>
						Post Gig
					</Button>
				</div>
			</form>
			<div>
				<BottomNav />
			</div>
		</div>
	);
}

const mapStateToProps = (state) => ({
	me: state.auth.me,
	authKey: state.posts.key,
});

export default connect(mapStateToProps, { loadUser })(CreateGig);
