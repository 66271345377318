import React from 'react';
import arrowBack from '../../../assets/images/Profile/arrowBack.png';
import './styles/sharePost.css';
import Avatar from '@material-ui/core/Avatar';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { postVideo, startPost } from '../../wm_actions/posts';
import firebase, { storage } from '../../../components/Firebase/firebase';
import LinearProgress from '@material-ui/core/LinearProgress';

class ShareVideoCaption extends React.Component {
  static propTypes = {
    postVideo: PropTypes.func.isRequired,
    uploadedIMG: PropTypes.bool.isRequired,
  };
  state = {
    caption: '',
    video_url: '',
    videoName: '',
    percentUploaded: 0,
    isUploading: false,
    videoToGet: '',
  };
  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleDownloadUrl = () => {
    let spaceRef = this.state.videoName;
    console.log(spaceRef, 'spaceRef');
    let GetVideo = `https://firebasestorage.googleapis.com/v0/b/whip-mvp.appspot.com/o/post%2Fvideo%2F${this.state.videoToGet}?alt=media`;
    console.log(GetVideo);
    this.setState({
      video_url: GetVideo,
    });
  };

  //   onSubmit = (e) => {
  //     e.preventDefault();
  //     console.log(this.state.video_url, sessionStorage.getItem('video'), 'Post');
  //     this.props.postVideo(this.state.video_url, this.state.caption);
  //     this.props.history.push('/Home');
  //   };

  handlePost = async () => {
    const { caption, video_url } = this.state;
    const token = localStorage.getItem('key');
    await postVideo(video_url, caption, token).then((res) => {
      if (res.status === 201) {
        alert('Video Posted Successfully');
        this.props.history.push('/Home');
        window.location.reload();
      }
    });
  };

  async componentDidMount() {
    this.props.startPost();
    let blob = await fetch(sessionStorage.getItem('video')).then((r) =>
      r.blob()
    );
    console.log(blob);
    let bucketName = 'post';
    let file = blob;
    this.setState({
      ...this.state,
      videoToGet: new Date().getTime(),
    });
    let storageRef = firebase
      .storage()
      .ref(`${bucketName}/video/${this.state.videoToGet}`);
    let uploadTask = storageRef.put(file);
    console.log(uploadTask);
    uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, (snap) => {
      const percentUploaded = Math.round(
        (snap.bytesTransferred / snap.totalBytes) * 100
      );
      const isUploading =
        Math.round((snap.bytesTransferred / snap.totalBytes) * 100) === 100;
      this.setState({ percentUploaded, isUploading });
      this.setState({
        // videoName: firebase.storage().ref().child(`${storageRef.location.path_}`)
        videoName: firebase.storage().ref().child(`${storageRef}`),
      });
    });
  }
  render() {
    const video = sessionStorage.getItem('video');
    const { caption, video_url } = this.state;
    return (
      <div>
        <div id="editTopBar">
          <img alt="" src={arrowBack} onClick={this.props.history.goBack} />
          <button id="postShareBtn" onClick={this.handlePost}>
            Post
          </button>
        </div>
        <div id="sharePostInputSection">
          <Avatar />
          <textarea
            placeholder="What do you want to share?"
            id="postInputContentField"
            required
            value={caption}
            rows={10}
            onClick={this.handleDownloadUrl}
            onChange={this.onChange}
            name="caption"
          />
          <video
            src={video}
            type="video/mp4"
            width="120px"
            height="155px"
            autoPlay
          />
        </div>

        {!this.state.isUploading && (
          <div id="uploadingProgress">
            <div>
              <p>Upload In Progress...</p>
              <LinearProgress value={this.state.percentUploaded} />
            </div>
          </div>
        )}
        <video id="videoUploaded" />
        {/* <img id='imgUploaded'/> */}
        <div id="sharePhotoBottomLine" />
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  uploadedIMG: state.posts.uploadedIMG,
});
export default connect(mapStateToProps, { postVideo, startPost })(
  ShareVideoCaption
);
