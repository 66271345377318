import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { changePassword } from '../../../wm_actions/auth';
// import { connect } from 'react-redux';

function PatchPassword(props) {
    const [password1, setPassword1] = useState("")
    const [password2, setPassword2] = useState("")

    function changePass() {
        // Request Body 
        const body = new FormData;
        body.append("new_password1", password1)
        body.append("new_password2", password2)
        console.log(body)
        props.changePassword(body)
    }

    return (
        <div id='editProfileForm'>
            <p>New password</p>
            <input name='password1' type='password' placeholder='Enter new password' onChange={(ev) => setPassword1(ev.target.value)} />
            <p>Phone Number</p>
            <input name='password2' type='password' placeholder='Enter password again' onChange={(ev) => setPassword2(ev.target.value)} />
            <div id='edit-btn-wrapper' className="editProBtn">
                {/* <button >Update</button>  */}
                <button className="" onClick={() => changePass()}>update</button>
            </div>
        </div>
    )
}
const mapStateToProps = state => ({
    me: state.auth.me,
    numberOfConnects: state.connects.numberOfConnects,
    getPersonalPosts: state.posts.personalPosts,
    getPersonalVideos: state.posts.personalVideos,
    collaboration: state.auth.collaboration,
    event_booking: state.auth.event_booking,
    record_label: state.auth.record_label
})

export default connect(mapStateToProps, { changePassword })(withRouter(PatchPassword))
