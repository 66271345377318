import React from "react";
import "./allUsers.css";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import defaultAvatar from "../../assets/images/browse/default-avatar.png";
import backIcon from "../../assets/images/share/arrowBack.png";
import { professionConstants, countryConstants } from "../wm_constants/index";
import { CircularProgress } from "@material-ui/core";
import {
  sendRequest,
  updateSentRequest,
  connectionUsers,
  loadAllUsers,
} from "../wm_actions/connects";
import SwipeableViews from "react-swipeable-views";
import CircleLoader from "../../loader/CircleLoader";
class AllUsers extends React.Component {
  state = {
    loading: false,
    profession: "",
    allUsers: [],
    requestId: "",
    previousIndex: 0,
    page: 1,
    index: null,
  };
  static propTypes = {
    allUsers: PropTypes.array,
  };
  async componentDidMount() {
    await this.props.connectionUsers();
    await this.props.updateSentRequest();
    await this.props.loadAllUsers(this.state.page, this.state.profession);

    // this.setState({
    // 	allUsers:this.props.allUsers
    // })
  }
  onSubmit = (e) => {
    e.preventDefault();
    this.t = setTimeout(
      () => this.props.sendRequest(this.state.requestId),
      1000
    );
    // this.props.updateSentRequest();
    this.time = setInterval(() => this.props.connectionUsers(), 2000);
    this.endCall = setTimeout(() => clearInterval(this.time), 25100);
    console.log("Submitted");
  };
  onChange = (e) => {
    this.setState({ page: 1 });
    this.setState({
      [e.target.name]: e.target.value,
    });
    console.log("Clicked");
    this.t = setTimeout(
      () => this.props.sendRequest(this.state.requestId),
      1000
    );
    // this.props.updateSentRequest();
    this.time = setInterval(() => this.props.connectionUsers(), 2000);
    this.endCall = setTimeout(() => clearInterval(this.time), 25100);
    console.log("Submitted");
    this.loaded = setTimeout(() => this.setState({ loading: false }), 10500);
  };
  changeIndex = (i) => {
    let nextFetch = Math.round(this.props.allUsers.length / 2);
    console.log(nextFetch, i);
    // console.log(this.state.previousIndex);
    // console.log(i);
    if (nextFetch === i) {
      console.log("fetching next");
      this.setState({
        page: this.state.page + 1,
      });
      this.props.loadAllUsers(this.state.page + 1, this.state.profession);
    }
  };
  handleChange = (value) => {
    let val = JSON.parse(value.target.value);
    this.setState({
      page: 1,
      index: 0,
    });
    // console.log(val.name)
    this.props.loadAllUsers(1, val.value);
    this.setState({ profession: val.value });
    this.setState({
      page: 1,
      index: 0,
    });

    // if (val.target.value == []) {
    // 	this.setState({ allUsers: this.props.allUsers });
    // } else {

    // item.profile.profession.map((a, i) => {
    // 	if (a === val.id) {
    // 		if (this.state.allUsers.length) {

    // 			this.setState({
    // 				allUsers: [...this.state.allUsers, item]
    // 			});
    // 		} else {
    // 			this.setState({
    // 				allUsers: [item]
    // 			});
    // 		}
    // 		// arr.push(item)
    // 	}
    // })
    // item.profile.profession.filter((a, i) => a === val.name).length
    //  === val.target.value
    // )
    // console.log(this.state.allUsers);
    // }
  };
  render() {
    // if(this.props.allUsers){
    // 	this.setState({allUsers: this.props.allUsers})

    // }
    const styles = {
      root: {
        padding: "0 20px",
      },
      slideContainer: {
        padding: "0 5px",
      },
      slide: {
        padding: 15,
        minHeight: 100,
        color: "#fff",
      },
      slide1: {
        backgroundColor: "#FEA900",
      },
      slide2: {
        backgroundColor: "#B3DC4A",
      },
      slide3: {
        backgroundColor: "#6AC0FF",
      },
    };
    // console.log(this.state.allUsers);
    return (
      <div>
        <div id="browsebackNav">
          <img
            alt=""
            src={backIcon}
            className="browseBack"
            onClick={() => this.props.history.goBack()}
          />
          <p>Music professionals you may want to connect with</p>
        </div>
        <div>
          <p id="browseSearchHead">Browse through by:</p>
          <select id="profession" onChange={this.handleChange}>
            {professionConstants.map((prof) => (
              <option value={JSON.stringify(prof)}>{prof.name}</option>
            ))}
          </select>
        </div>
        <SwipeableViews
          enableMouseEvents
          style={styles.root}
          slideStyle={styles.slideContainer}
          onChangeIndex={this.changeIndex}
          index={this.state.index}
        >
          {this.props.allUsers ? (
            this.props.allUsers.map((user) => (
              <div key={user.id}>
                <div id="browseImgWrap">
                  {user.profile.avatar ? (
                    <img
                      alt=""
                      className="browse-img"
                      src={user.profile.avatar}
                    />
                  ) : (
                    <img className="browse-img" src={defaultAvatar} />
                  )}
                </div>
                <p className="browseName">{user.name}</p>
                {professionConstants.map((proC) => (
                  <div>
                    <p className="browseSubName">
                      {proC.id === user.profile.profession[0]
                        ? proC.name
                        : null}
                    </p>
                    <p className="browseSubName">
                      {proC.id === user.profile.profession[1]
                        ? proC.name
                        : null}
                    </p>
                  </div>
                ))}
                {countryConstants.map((country) => (
                  <div>
                    <p className="browseCountry">
                      {country.id === user.country ? country.name : null}
                    </p>
                  </div>
                ))}
                <div id="allUserBtn">
                  {this.props.sentConnectRequests?.filter(
                    (a, i) => a.receiver.id === user.id
                  ).length ? (
                    <button
                      onClick={() =>
                        console.log(
                          this.props.sentConnectRequests?.filter(
                            (a, i) => a.receiver.id === user.id
                          )
                        )
                      }
                      id="music-connect-pendingBtn"
                    >
                      Pending
                    </button>
                  ) : (
                    <button
                      value={user.id}
                      name="requestId"
                      id="music-connect-button"
                      type="submit"
                      onClick={() =>
                        console.log(this.props.sentConnectRequests)
                      }
                    >
                      {this.state.loading[user.id] && (
                        <CircularProgress size={14} />
                      )}
                      {!this.state.loading[user.id] && "Connect"}
                    </button>
                  )}
                </div>
              </div>
            ))
          ) : (
            <CircleLoader />
          )}
        </SwipeableViews>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  allUsers: state.connects.allUsers,
  browseAllUser: state.connects.browseAllUser,
  sentConnectRequests: state.connects.sentConnectRequests,
});
export default connect(mapStateToProps, {
  connectionUsers,
  sendRequest,
  updateSentRequest,
  loadAllUsers,
})(AllUsers);
