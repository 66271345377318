import mixpanel from "mixpanel-browser";

// if environment is development disable mixpanel

if (process.env.NODE_ENV === "development") {
  mixpanel.init("9df272a95993965168ca8c8fad3c98eb");
  console.log("Mixpanel----> Tracking disabled");
} else {
  mixpanel.init("9df272a95993965168ca8c8fad3c98eb", {
    debug: true,
    //ignore do not track
    ignore_dnt: true,
  });
  console.log("Mixpanel----> Tracking enabled");
}

export default mixpanel;
