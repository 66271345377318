/* eslint-disable import/no-anonymous-default-export */
import {
  CONNECTION_REQUESTS_RECIEVED,
  CONNECTION_REQUESTS_FETCHING,
  CONNECTION_REQUESTS_FAILED,
  CONNECT_REQUEST_SENDING,
  CONNECT_REQUEST_SENT,
  FETCHING_CONNECTION_USERS,
  FETCHING_ALL_USERS,
  FETCHING_USERS_FAILED,
  FETCHING_USERS_SUCCESS,
  SENDING_CONNECT_REQUEST,
  SENT_CONNECT_REQUEST,
  FAILED_CONNECT_REQUEST,
  NUMBER_OF_CONNECTS,
  FETCHING_NUMBER_OF_CONNECTS,
  SENT_REQUESTS_RECEIVED,
  FETCHING_SENT_REQUEST,
  DELETING_CONNECT_REQUEST,
  DELETED_CONNECT_REQUEST,
  MERGED_CONNECT_USERS,
  ACCEPTING_CONNECT_REQUEST,
  ACCEPTED_CONNECT_REQUEST,
  UNABLE_TO_ACCEPT_CONNECT_REQUEST,
  SEARCH_USER,
  FETCHING_ALL_USERS_SUCCESS,
  DISCONNECT_CONNECT_REQUEST,
  FETCHING_MUTUAL_CONNECTIONS_SUCCESS,
  USER_CONNECT_LOADING,
} from "../wm_actions/types";

const initialState = {
  key: localStorage.getItem("key"),
  fetchingRequests: true,
  areRequests: null,
  connectRequests: [],
  allUsers: [],
  sentRequest: null,
  numberOfConnects: null,
  connectionsList: [],
  sentConnectRequests: null,
  fetchingSentRequest: false,
  connectedUsers: [],
  userConnectLoading: false,
  sending: false,
  searchUser: false,
  browseAllUser: false,
  mutualConnectionsCount: null,
  mutualConnections: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case CONNECTION_REQUESTS_FETCHING:
    case FETCHING_ALL_USERS:
      console.log(action, "FETCHING_ALL_USERS");
      return {
        ...state,
        fetchingRequests: true,
        sentRequest: false,
      };
    case SENDING_CONNECT_REQUEST:
      return {
        ...state,
        // fetchingRequests: true,
        sentRequest: false,
        sending: true,
      };
    case FETCHING_NUMBER_OF_CONNECTS:
      return {
        ...state,
        numberOfConnects: null,
      };
    case MERGED_CONNECT_USERS:
      return {
        ...state,
        fetchingRequests: false,
        areRequests: true,
        // connectRequests: action.payload,
        allUsers: action.payload,
      };
    case FETCHING_SENT_REQUEST:
      return {
        ...state,
        sentConnectRequests: null,
        fetchingSentRequest: true,
      };
    case CONNECTION_REQUESTS_RECIEVED:
      return {
        ...state,
        connectRequests: action.payload,
        fetchingRequests: false,
      };
    case NUMBER_OF_CONNECTS:
      return {
        ...state,
        numberOfConnects: action.payload.count,
        connectionsList: action.payload.results,
      };
    case SENT_REQUESTS_RECEIVED:
      return {
        ...state,
        sentConnectRequests: action.payload,
        fetchingSentRequest: false,
      };
    case SENT_CONNECT_REQUEST:
      return {
        ...state,
        sentRequest: true,
        sending: action.payload,
      };
    case SEARCH_USER:
      // console.log(action.payload)
      return {
        ...state,
        fetchingRequests: false,
        searchUser: action.payload,
        sentRequest: false,
      };
    case FETCHING_ALL_USERS_SUCCESS:
      console.log(action.payload, "FETCHING_ALL_USERS_SUCCESS");
      return {
        ...state,
        fetchingRequests: false,
        browseAllUser: action.payload,
        allUsers: action.payload,
        sentRequest: false,
      };
    case FETCHING_CONNECTION_USERS:
      console.log(action.payload, "FETCHING_CONNECTED_USERS_SUCCESS");
      return {
        ...state,
        fetchingRequests: false,
        connectedUsers: action.payload,
        sentRequest: false,
      };
    case USER_CONNECT_LOADING:
      console.log(action.payload);
      return {
        ...state,
        userConnectLoading: action.payload,
      };
    case FETCHING_MUTUAL_CONNECTIONS_SUCCESS:
      console.log(action.payload, "FETCHING_MUTUAL_CONNECTIONS_SUCCESS");
      return {
        ...state,
        fetchingRequests: false,
        mutualConnections: action.payload.count,
        mutualConnectionsCount: action.payload,
      };
    case CONNECTION_REQUESTS_FAILED:
    case FETCHING_USERS_FAILED:
    case FAILED_CONNECT_REQUEST:
      return {
        ...state,
        fetchingRequests: false,
        areRequests: null,
        sentRequest: false,
      };
    default:
      return state;
  }
}
