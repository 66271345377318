import React from 'react';
import './connect.css'
import { CircularProgress, Button } from '@material-ui/core';


class ConnectButton extends React.Component {
    state ={
        loading: false
    }
    onChag = e =>{
        this.setState({
            loading: true
        })
        this.t = setTimeout(() => this.setState({
            loading: false
        }))
    }
    render() {
        return (
            <div>
                <button onClick={this.onChag}>{this.state.loading && <CircularProgress size={14} />}{!this.state.loading && "Connect"}</button>
            </div>
        )
    }
}

export default ConnectButton