import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  allGigs: [],
}

export const gigSlice = createSlice({
  name: 'gigs',
  initialState,
  reducers: {
    setGigs: (state, action) => {
      // fetch gigs from API and set state accordingly

      state.allGigs = [...action.payload]
    },

  }
})

// Action creators are generated for each case reducer function
export const { setGigs } = gigSlice.actions

export const selectAllGigs = state => state.gigs.allGigs

export default gigSlice.reducer