import React, { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import terms from './terms.md'
import remarkGfm from 'remark-gfm'
import "./terms.css";


export default function Tnc() {
  const [markdown, setMarkdown] = useState('');

  useEffect(() => {
	fetch(terms)
	  .then(res => res.text())
	  .then(text => setMarkdown(text));
  }, []);


	return (
    <div>
      <div className="terms-section">
        <ReactMarkdown
		className="p-16 leading-6 text-sm text-justify font-semibold"
          children={markdown}
          remarkPlugins={[remarkGfm]}
        />
      </div>
    </div>
  );
}
