import React from 'react';
import _ from "lodash";
import { AllProfessions, countryConstants, professionConstants } from "../../wm_constants";
import CommentSystem from "../../wm_posts/component/comments";

const professionLookUp = (profession) => AllProfessions[profession] || '';

const CommentsCard = ({ comments, postId, authKey, handleFetchPosts }) => {
    return (
        <div className={"pb-3"}>
            <CommentSystem commentId={postId} authKey={authKey} func={handleFetchPosts} className={"mb-3"} />

            {comments.map((comment) => (
                <div
                    className=" px-4 flex justify-start gap-1 items-start"
                    key={comment.id}
                >
                    <div className="w-12 h-10 rounded-full">
                        <img
                            src={comment?.comment_by.profile?.avatar}
                            className="rounded-full w-full h-full object-fit object-cover"
                            alt="avatar"
                        />
                    </div>
                    <div
                        style={{ backgroundColor: "#FFFCF6" }}
                        className="text-left w-full p-3"
                    >
                        <p

                            className="text-xs text-[#9C7B39]-700 capitalize"
                        >
                            {comment?.comment_by?.name}
                        </p>
                        <div className="flex items-center">
                            {professionConstants.map((proC) => (
                                <p
                                    key={proC.id}
                                    style={{
                                        color: "##666666",
                                        fontFamily: "Poppins",
                                        fontWeight: 400,
                                        fontSize: "8px",
                                    }}
                                    className="mr-0.5"
                                >
                                    {proC.id ===
                                        comment?.comment_by?.profile.profession[0]
                                        ? proC.name
                                        : null}
                                </p>
                            ))}
                            <p
                                style={{
                                    color: "##666666",
                                    fontFamily: "Poppins",
                                    fontWeight: 400,
                                    fontSize: "8px",
                                }}
                            >
                                ,{"  "}
                            </p>
                            {countryConstants?.map((country) => (
                                <p
                                    key={country.id}
                                    style={{
                                        color: "##666666",
                                        fontFamily: "Poppins",
                                        fontWeight: 400,
                                        fontSize: "8px",
                                    }}
                                >
                                    {country.id === comment?.comment_by?.country
                                        ? country.name
                                        : null}
                                </p>
                            ))}
                        </div>
                        <p
                            style={{
                                fontFamily: "Poppins",
                                fontWeight: 400,
                            }}
                            className="text-sm mt-2"
                        >
                            {comment?.comment}
                        </p>
                    </div>
                </div>
                // <p>{comment.comment}</p>
            ))}
        </div>
    );
};

export default CommentsCard;