import React from "react";
import { userRecommendations } from "../../wm_actions/profile";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import CircleLoader from "../../../loader/CircleLoader";

class UserRecommendations extends React.Component {
	static propTypes = {
		recommendations: PropTypes.array,
		isFetchingRecommendations: PropTypes.bool
	};
	componentWillMount() {
		this.props.userRecommendations();
	}
	render() {
		if (this.props.isFetchingRecommendations) {
			return <CircleLoader />;
		}
		return (
			<div>
				{this?.props?.recommendations ? (
					this?.props?.recommendations.length ? (
						this?.props?.recommendations?.map((rec) => (
							<div className="recContainer">
								<h1 className="rec-text">{rec?.recommendation}</h1>
								<div>
									<p className="rec-by">{rec?.recommendation_by?.name}</p>
								</div>
							</div>
						))
					) : (
						<div className="ProfileLoaderWrapper">
							<h1>Nothing To Show</h1>
						</div>
					)
				) : (
					<div className="ProfileLoaderWrapper">
						<div className="loader"></div>
					</div>
				)}
			</div>
		);
	}
}
const mapStateToProps = (state) => ({
	recommendations: state.profile.recommendations,
	isFetchingRecommendations: state.profile.isFetchingRecommendations
});

export default connect(mapStateToProps, { userRecommendations })(UserRecommendations);
