import React from 'react';

class ShareGig extends React.Component {
    render() {
        return (
            <div>
                <h1>This is ShareGig Page</h1>
            </div>
        )
    }
}
export default ShareGig