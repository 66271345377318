import VideoRecorder from "react-video-recorder";
import "./styles/VideoRecorder.scss";
import {useHistory, withRouter, useParams} from "react-router";
import {useCallback, useEffect, useRef, useState} from "react";
import {connect, useDispatch} from "react-redux";
import {loadUser} from "../../wm_actions/auth";
import accept from "../../../assets/images/share/accept.png";
import cancel from "../../../assets/images/share/cancel.png";
import {Link} from "react-router-dom";
import {videoUploadedStorage} from "../../wm_actions/share";
import Webcam from "react-webcam";
import videoStart from "../../../assets/images/share/videoStart.png";
import stopVideo from "../../../assets/images/share/stopVideo.png";

const RecordView = (props) => {
    const webcamRef = useRef(null);
    const mediaRecorderRef = useRef(null);
    const [recordedChunks, setRecordedChunks] = useState([]);
    const [capturing, setCapturing] = useState(false);
    const [dataurl, setDataurl] = useState("");
    const [counter, setCounter] = useState(60);
    const [recording, setRecording] = useState(false);
    const [sixty, setSixty] = useState("");
    const [ninetySec, setNinetySec] = useState(60000);
    const [ComRec, setComRec] = useState(false);
    const [slugone, setSlugone] = useState("");
    const dispatch = useDispatch();
    let {slug} = useParams();

    const [myUser, setMyUser] = useState(false);
    const {loadUser, me} = props;

    // useEffect for checking and setting current users
    useEffect(() => {
        let isFetchingCurrentUser = true;
        if (isFetchingCurrentUser) {
            props.loadUser();
            if (me) {
                setMyUser(me[0]);
                console.log(myUser);
            }
        }
        return () => {
            isFetchingCurrentUser = false;
        };
    }, []);

    // const stopRecording = useCallback(() => {
    // 	mediaRecorderRef.current.stop();
    // 	setRecording(false);
    // 	// handleDownload();
    // 	setComRec(true);
    // }, [mediaRecorderRef, webcamRef, setRecording]);

    // const startRecording = useCallback(() => {
    // 	setRecording(true);
    // 	mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream, {
    // 		mimeType: "video/webm",
    // 	});
    // 	mediaRecorderRef.current.addEventListener(
    // 		"dataavailable",
    // 		handleDataAvailable,
    // 	);
    // 	mediaRecorderRef.current.start();
    // }, [webcamRef, setRecording, mediaRecorderRef]);

    const videoConstraints = {
        width: 1920,
        height: 1040,
        // facingMode: this.state.cameraMode,
    };
    const handleDataAvailable = useCallback(
        ({data}) => {
            if (data.size > 0) {
                setRecordedChunks((prev) => prev.concat(data));
            }
        },
        [setRecordedChunks],
    );
    const handleStartCaptureClick = useCallback(() => {
        setCapturing(true);
        mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream, {
            mimeType: "video/webm",
        });
        mediaRecorderRef.current.addEventListener(
            "dataavailable",
            handleDataAvailable,
        );
        mediaRecorderRef.current.start();
    }, [handleDataAvailable]);
    // const handleDownload = () => {
    // 	console.log("working");

    // 	if (recordedChunks.length) {
    // 		const blob = new Blob(recordedChunks, {
    // 			type: "video/webm",
    // 		});
    // 		console.log(blob);
    // 		const url = URL.createObjectURL(blob);
    // 		setDataurl(url);
    // 		console.log(url);
    // 		sessionStorage.setItem("video", url);
    // 		dispatch(videoUploadedStorage(true));

    // 		setComRec(true);
    // 		// const a = document.createElement("a");
    // 		// document.body.appendChild(a);
    // 		// a.style = "display: none";
    // 		// a.href = url;
    // 		// a.download = "react-webcam-stream-capture.webm";
    // 		// a.click();
    // 		// window.URL.revokeObjectURL(url);
    // 		setRecordedChunks([]);
    // 	}
    // };
    const handleStopCaptureClick = () => {
        mediaRecorderRef.current.stop();
        setCapturing(false);
        setComRec(true);
        console.log("stopping the recording");
    };

    useEffect(() => {
        console.log(recording);
        if (capturing) {
            const timer =
                counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
            return () => clearInterval(timer);
        }
        // if (ComRec) {
        // 	handleDownload();
        // }
    }, [ComRec, capturing, counter, recording]);

    return (
        <div className="ShareVideo">
            {!ComRec ? (
                <div className="video-stream">
                    {/* <button onClick={handleDownload}>Download</button> */}

                    <div className="countdown">
                        <p>{counter}s</p>
                    </div>
                    <Webcam
                        audio={true}
                        muted={true}
                        height={1080}
                        ref={webcamRef}
                        screenshotFormat="image/jpeg"
                        width={1080}
                        videoConstraints={videoConstraints}
                        mirrored={true}
                        imageSmoothing
                        // mirrored
                        style={{
                            height: "100vh",
                            width: "100%",
                            objectFit: "cover",
                        }}
                    />
                </div>
            ) : (
                <video
                    style={{
                        height: "100vh",
                        width: "100%",
                        objectFit: "cover",
                        zIndex: -1,
                    }}
                    autoPlay
                    controls
                    src={dataurl}
                />
            )}
            {!ComRec && (
                <div className="recordingIcon z-40">
                    <button onClick={handleStopCaptureClick}>Stop</button>
                    {capturing ? (

                        <button onClick={handleStopCaptureClick}>
                            <img alt="" src={stopVideo}/>
                        </button>
                        ) : (
                        <button onClick={handleStartCaptureClick}>
                        <img alt="" src={videoStart} />
                        </button>
                        )}
                </div>
            )}

            {/* <VideoRecorder
        chunkSize={250}
        constraints={{
          audio: true,
          video: true,
        }}
        countdownTime={3000}
        dataAvailableTimeout={500}
        isFlipped
        isOnInitially
        showReplayControls
        replayVideoAutoplayAndLoopOff
        timeLimit={ninetySec}
        onRecordingComplete={(videoBlob) => {
          const url = URL.createObjectURL(videoBlob);
          sessionStorage.setItem("video", url);
          setComRec(true);
          dispatch(videoUploadedStorage(true));
        }}
      /> */}
            {ComRec && (
                <div>
                    <div id="sharePhotoUploadingBtns">
                        <button onClick={() => window.location.reload()}>
                            <img id="sharePhotoCancel" src={cancel} alt=""/>
                        </button>
                        <Link to="/artistApplication">
                            <img id="sharePhotoAccept" src={accept} alt=""/>
                        </Link>
                    </div>
                </div>
            )}
        </div>
    );
};
;
;

const mapStateToProps = (state) => ({
    isPremium: state.auth.isPremium,
    me: state.auth.me,
    videoStorage: true,
    video: true,
});
export default connect(mapStateToProps, {loadUser, videoUploadedStorage})(
    withRouter(RecordView)
);
