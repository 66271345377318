import { useHistory } from "react-router";
import {
  AUTH_ERROR,
  ONBOARDED,
  LOGOUT,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  USER_LOADING,
  USER_LOADED,
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  PROFESSION_UPDATED,
  GENRE_UPDATED,
  PROFESSION_UPDATE_FAIL,
  GENRE_UPDATE_FAIL,
} from "../wm_actions/types";

const initialState = {
  key: localStorage.getItem("key"),
  isAuthenticated: null,
  registerSuccessful: false,
  isLoading: false,
  users: [],
  profession: [],
  genre: [],
  professionUpdate: false,
  genreUpdate: false,
  me: [],
  profile: [],
  onBoarded: false,
  onBoardRedirect: false,
  collaboration: null,
  record_label: null,
  event_booking: null,
  avatar: null,
};

let myWorker = new Worker("service-worker.js");

export default function loadUser(state = initialState, action) {
  // const history = useHistory();
  switch (action.type) {
    case USER_LOADING:
      return {
        ...state,
        // isLoading: true,
      };
    case USER_LOADED:
      // console.log(action.payload[0].email);
      // localStorage.setItem("userId", action.payload[0].id);
      // localStorage.setItem(
      //   "Profession",
      //   JSON.stringify(action.payload[0]?.profile.profession)
      // );
      return {
        ...state,
        isAuthenticated: true,
        // isLoading: false,
        me: action.payload,
        profile: action.payload[0].profile,
        users: action.payload[0].profile.url,
        profession: action.payload[0].profile.profession,
        genre: action.payload[0].profile.genre,
        onBoarded: action.payload[0].profile.is_onboarded,
        record_label: action.payload[0].profile.record_label_interest,
        event_booking: action.payload[0].profile.event_interest,
        collaboration: action.payload[0].profile.collaboration_interest,
        isPremium: action.payload[0].is_premium,
        Name: action.payload[0].name,
        avatar: action.payload[0].avatar,
      };
    case PROFESSION_UPDATED:
      return {
        ...state,
        profession: action.payload.profession,
        professionUpdate: true,
      };
    case GENRE_UPDATED:
      return {
        ...state,
        genre: action.payload.genre,
        genreUpdate: true,
      };
    case LOGIN_SUCCESS:
      console.log(action.payload.key, action.payload);
      localStorage.setItem("key", action.payload.key);
      return {
        ...state,
        ...action.payload,
        isAuthenticated: true,
        isLoading: false,
      };
    case REGISTER_SUCCESS:
      localStorage.setItem("key", action.payload.key);

      return {
        ...state,
        ...action.payload,
        registerSuccessful: true,
        isLoading: false,
        // isAuthenticated: true,
      };
    case PROFESSION_UPDATE_FAIL:
    case GENRE_UPDATE_FAIL:
      return {
        ...state,
        professionUpdate: false,
        genreUpdate: false,
      };
    case ONBOARDED:
      return {
        ...state,
        onBoardRedirect: true,
      };
    case AUTH_ERROR:
    case LOGIN_FAIL:
    case REGISTER_FAIL:
    case LOGOUT:
      console.log("Logout Fired");
      localStorage.removeItem("key");
      localStorage.removeItem("userId");
      localStorage.removeItem("pusherTransportTLS");
      // history.push("/");
      return {
        ...state,
        key: null,
        user: null,
        isAuthenticated: false,
        isLoading: false,
        // registerSuccessful: false,
      };
    default:
      return state;
  }
}
