export const artistHandle = (TYPE, value) => async (dispatch, getState) => {
  try {
    dispatch({
      type: TYPE,
      payload: value,
    });
  } catch (error) {
    console.log(error);
  }
};
