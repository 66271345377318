import React, { useState, useEffect } from "react";
import Navbar from "../wm_navigation/Navbar";
import { loadUser } from "../wm_actions/auth.js";
import { connect } from "react-redux";
import axios from "axios";
import moment from "moment";
import LocationOn from "@material-ui/icons/LocationOnOutlined";
import "./singleGig.css";
import singer from "../../assets/images/gigs/singer 1.png";
import { Button } from "@material-ui/core";
import BounceLoader from "react-spinners/BounceLoader";
import BottomNav from "../wm_navigation/BottomNav";
import { Redirect } from "react-router-dom";

function ExpandedGig(props) {
  const [gig, setGig] = useState({});
  const [loading, setLoading] = useState(true);
  const [request, setRequest] = useState("");
  const [redirect, setRedirect] = useState();
  const [organizer, setOrganizer] = useState("");
  const pathname = window.location.pathname;
  const gig_id = pathname.split("/")[2];
  const user_id = localStorage.getItem("userId");
  //  console.log(user_id, 'user id');



  useEffect(() => {
    axios.get(`http://server.whipafrica.com/v1/${gig_id}/`).then((res) => {
      console.log(res.data);
      setLoading(false);
      setGig(res.data);
      setOrganizer(res.data.gig_organizer.id);
      console.log(res.data.gig_organizer.id);
    });
  }, [gig_id]);

  if (redirect) {
    return <Redirect push to={redirect} />;
  }
  if (loading) {
    return (
      <div className="loader-wrapper items-center">
        <BounceLoader color={"#34529E"} size={80} />
      </div>
    );
  } else {
    return (
      <div>
        <Navbar />

        <div>
          <img className="my-5" src={gig.gig_banner} alt="" />
          <div className="flex justify-between">
            <p key={gig.id} className="font-bold px-2">
              {gig.gig_name}
            </p>
            <p className="gig-date px-3 font-bold text-sm">
              {moment(gig.gig_date).format("Do MMMM YYYY")}
            </p>
          </div>
          <div className="text-left p-3">
            <p className="py-2 text-gray-600">
              <LocationOn className="mr-1"></LocationOn>
              {gig.gig_location_city}, {gig.gig_location_country}
            </p>
            <div className="flex">
              <img className="ml-1" src={singer} alt="" />
              <p>{gig.number_of_artists}</p>
            </div>
          </div>
          <div className="text-left mb-8 p-3">
            <p className="font-bold text-gray-500">GENRES</p>
            <ol>
              <li>{gig.gig_genre}</li>
            </ol>
          </div>
          <div className="text-left p-3">
            <p className="font-bold text-gray-500">ADDITIONAL INFORMATION</p>
            <p className="py-3 leading-7">{gig.gig_additional_info}</p>
          </div>
          {organizer !== localStorage.getItem("userId") ? (
            <div className="py-5">
              
            </div>
          ) : (
            <div></div>
          )}
        </div>
        <div className="mt-24">
          <BottomNav />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  me: state.auth.me,
  authKey: state.posts.key,
});

export default connect(mapStateToProps, { loadUser })(ExpandedGig);
