import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import "react-awesome-slider/dist/styles.css";
import "../styles/browse.css";
import Navbar from "../../wm_navigation/Navbar";
import SwipeableViews from "react-swipeable-views";
import BottomNav from "../../wm_navigation/BottomNav";
import { Link } from "react-router-dom";
import image from "./../../../assets/videoImage.png";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import VideoPlayer from "react-simple-video-player";
import { getVideos, postVideoLike } from "../../wm_actions/posts";
import like from "../../../assets/images/Posts/like.png";
import comment from "../../../assets/images/Posts/comment.png";
import share from "../../../assets/images/Posts/share.png";
import Loader from "../../../loader/CircleLoader";
import { Carousel } from "react-responsive-carousel";

function VidBrowse(props) {
	const [videos, setVideos] = useState([]);
	const [category, setCategory] = useState([]);
	const [isSetting, setIsSetting] = useState([]);
	// if (this.state.isSetting) {
	//     return <p>Loading</p>
	// }

	useEffect(() => {
		props.getVideos();
	}, []);
	useEffect(() => {
		setVideos(props.vid);
		console.log(videos);
	}, [props]);

	return (
		<div id="browseVidWrapper">
			<Navbar />
			{/* <div className="MainBrowseVideo"> */}
			<Carousel
				axis="vertical"
				showArrows={false}
				showStatus={false}
				showIndicators={false}
			>
				{videos.length > 0 ? (
					videos.map((video, i) => (
						<div
							//  className="BrowseVideoCard"
							key={video?.id}
						>
							<CardContent id="VideoBrowse">
								<div id="personInfo">
									<Link to={`/profile/${video.created_by.id}`}>
										<img alt="" src={video?.created_by?.profile?.avatar} />
										<div>
											<p id="VideoBrowseName">{video.created_by.name}</p>
											<p id="personProf">
												{video.created_by.profile.profession.join(", ")}
											</p>
										</div>
									</Link>
								</div>
								{video.url ? (
									<>
										<VideoPlayer url={video.url} autosize aspectRatio="4:3" />
										<div className="videoBrowsReact" id="reaction-wrapper">
											<form id="reactionForm">
												<div id="reactions">
													<div id="likeWrap">
														<button
															type="submit"
															id="post-like-btn"
															onClick={(e) => {
																e.preventDefault();
																props.postVideoLike(video.id);
															}}
														>
															<img className="LSCButtons" src={like} />
														</button>
														<p>{video?.likes?.length} likes</p>
													</div>
												</div>
												<div id="reactions">
													<div id="commentWrap">
														<button type="submit" id="post-like-btn">
															<img
																className="LSCButtons"
																alt=""
																src={comment}
															/>
														</button>
														<p>{video?.Comments?.length} Comments</p>
													</div>
												</div>
												<div id="reactions">
													<div id="shareWrap">
														<button type="submit" id="post-like-btn">
															<img className="LSCButtons" alt="" src={share} />
														</button>
													</div>
												</div>
											</form>
										</div>
									</>
								) : (
									<div
										style={{
											height: "100vh",
											display: "flex",
											alignItems: "center",
											justifyContent: "center",
											background: "#7A7C7D",
											color: "#FFF",
											fontSize: "30px",
										}}
									>
										<h1>No Video To Preview</h1>
									</div>
								)}
							</CardContent>
						</div>
					))
				) : (
					// <Loader />
					<div>
						<p>No Videos to show</p>
					</div>
				)}
			</Carousel>
			{/* </div> */}

			<BottomNav />
		</div>
	);
}
const mapStateToProps = (state) => ({
	vid: state.posts.videoPost,
	me: state.auth.me,
});
export default connect(mapStateToProps, { getVideos, postVideoLike })(
	VidBrowse,
);
