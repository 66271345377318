import React from 'react';
import {logout} from '../wm_actions/auth';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';

class Logout extends React.Component {
    componentDidMount() {
        this.props.logout()
        setTimeout(()=> <Redirect to='/login'/>, 100)
    }
    render(){
        return <div>
            <p>Logging Out</p>
        </div>
    }
}
const mapStateToProps = state => ({
})

export default connect(mapStateToProps,{logout}) (Logout)